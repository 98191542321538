import React, { useContext, useState, useEffect, useRef } from 'react';
import { FormikForm, Section } from 'components';
import { useFormikContext } from 'formik';
import { transformSnakeKeysToCamel } from '../../utils';
import * as styles from './styles.module.scss';
import {
  TextInput,
  CheckBoxSingleInput,
  SelectInput,
  CheckBoxInput,
  CheckBoxRangeInput,
} from '../../components/FormikInputs';
import { GeoContext, TrackEventContext, UtmContext } from '../../context';
import { trackAmplitudeEvent } from '../../utils/analytics';
import Logo from '../../images/logos/equitise-icon-tertiary.svg';
import Check from '../../images/icons/equitise-check.svg';

export const MainForm = props => {
  const { data } = transformSnakeKeysToCamel(props);
  const { primary, items } = data;
  const {
    theme,
    formStyle,
    description,
    messageField,
    mainForm: formTitle,
    mainFormTag,
    newsletterCheckbox,
    submissionParagraph,
    submissionTitle,
    formPrefix,
  } = primary;

  const [emailFromUrl, setEmailFromUrl] = useState('');
  useEffect(() => {
    const emailParam = window.location.search.split('=')[1];
    setEmailFromUrl(emailParam);
  }, []);

  const { trackIntercomEvent } = useContext(TrackEventContext) || {};
  const { countryName: countryOfResidence } = useContext(GeoContext) || {};
  const { utmParams } = useContext(UtmContext) || {};
  const { utmSource, utmMedium, utmCampaign } = utmParams || {};
  const [showForm, setShowForm] = useState(true);

  const validationFunction = values => {
    const { firstName, lastName, email: emailToValidate } = values;
  };

  const submitMessageRef = useRef(null);
  const formPrefixValue = formPrefix.text || 'form';

  const onSubmit = values => {
    const newValuesObject = {};
    Object.keys(values).forEach(value => {
      newValuesObject[`${formPrefixValue}-${value.toLowerCase()}`] = values[value];
    });

    // intercom does not accept array as value amplitude does not give us the info we want with arrays, convert multiple choice array into a string
    const multipleChoicesFieldNamesObject = {};
    multipleChoiceFieldNames.forEach(fieldName => {
      multipleChoicesFieldNamesObject[`${formPrefixValue}-${fieldName.toLowerCase()}`] =
        values[fieldName].join(',') || '';
    });

    try {
      trackIntercomEvent({
        email: emailFromUrl,
        eventName: `form submission - ${mainFormTag?.text}`,
        metadata: {
          source: utmSource,
          medium: utmMedium,
          campaign: utmCampaign,
          ...newValuesObject,
          ...multipleChoicesFieldNamesObject,
        },
      });
    } catch (formError) {
      console.error('form submission track intercom error', {
        error: formError,
      });
    }

    try {
      trackAmplitudeEvent({
        email: emailFromUrl,
        eventName: `form submission - ${mainFormTag?.text}`,
        userData: {
          email: emailFromUrl,
          ...newValuesObject,
          ...multipleChoicesFieldNamesObject,
        },
        eventData: {
          ...newValuesObject,
          ...multipleChoicesFieldNamesObject,
        },
      });
    } catch (formError) {
      console.error('form submission track amplitude error', {
        error: formError,
      });
    }

    setShowForm(false);
    submitMessageRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  const isMultipleChoice = item => item.input_type === 'multiple choice';
  const isDropdown = item => item.input_type === 'dropdown';
  const isText = item => item.input_type === 'text';

  const multipleChoiceQuestions = items.filter(question => question.input_type === 'multiple choice');
  const multipleChoiceFieldNames = multipleChoiceQuestions.map(question => question.field_name.text);
  const multipleChoiceFieldNamesObject = {};
  multipleChoiceFieldNames.forEach(fieldName => {
    multipleChoiceFieldNamesObject[fieldName] = [];
  });

  // const AddInput = () => {};

  const FormItems = () => {
    const AdditionalInputs = displayAdditionalInput => {
      const { values, setValues } = useFormikContext();
      const { item } = displayAdditionalInput;
      const [showAdditionalInput, setShowAdditionalInput] = useState(false);
      const {
        field_name: fieldName,
        additional_input_question: additionalInputQuestion,
        answer_that_requires_additional_input: answerThatRequiresAdditionalInput,
      } = item;
      const fieldText = fieldName?.text;
      useEffect(() => {
        if (values[fieldText]?.includes(answerThatRequiresAdditionalInput?.text) && !showAdditionalInput) {
          setShowAdditionalInput(true);
        }

        if (!values[fieldText]?.includes(answerThatRequiresAdditionalInput?.text) && showAdditionalInput) {
          setShowAdditionalInput(false);
        }
      }, [values[fieldText], values]);

      return (
        <>
          {showAdditionalInput && (
            <div style={{ width: '100%' }}>
              <TextInput label={additionalInputQuestion?.text} name={`${item.field_name.text} additional input`} />
            </div>
          )}
        </>
      );
    };

    return (
      <>
        {items.map(item => {
          const {
            additional_input_text: additionalInputText,
            additional_input_condition: additionalInputCondition,
            display_additional_input: displayAdditionalInput,
            field_name: fieldName,
            input_type: inputType,
            multiple_choice: multipleChoice,
            question,
            additional_input_question: additionalInputQuestion,
            answer_that_requires_additional_input: answerThatRequiresAdditionalInput,
          } = item;
          return (
            <>
              {isDropdown(item) && (
                <>
                  <SelectInput
                    className="multipleChoiceValue"
                    name={fieldName.text}
                    label={question.text}
                    labelclassname="mainformLabels"
                  >
                    <option value="" hidden aria-label="Blank first option to force user input" />
                    {multipleChoice.richText.map(multipleChoiceItem => (
                      <option key={multipleChoiceItem.text} value={multipleChoiceItem.text}>
                        {multipleChoiceItem.text}
                      </option>
                    ))}
                  </SelectInput>
                </>
              )}
              {isText(item) && (
                <TextInput labelclassname="mainformLabels" label={question.text} name={fieldName.text} />
              )}
              {isMultipleChoice(item) && (
                <>
                  <p className={styles.multipleChoiceQuestion}>{question.text}</p>
                  <div className={styles.categoryFields}>
                    {multipleChoice.richText?.map(category => (
                      <CheckBoxRangeInput
                        labelclassname="mainformLabels"
                        name={fieldName.text}
                        key={category.text}
                        value={category.text}
                      >
                        <div className={styles.multipleChoice}>{category.text}</div>
                      </CheckBoxRangeInput>
                    ))}
                  </div>
                </>
              )}
              {displayAdditionalInput && (
                <>
                  <AdditionalInputs displayAdditionalInput={displayAdditionalInput} item={item} />
                </>
              )}
            </>
          );
        })}
      </>
    );
  };

  // prevent form from submitting if the user presses enter
  const handleKeyDown = event => {
    if ((event.charCode || event.keyCode) === 13) {
      event.preventDefault();
    }
  };

  const RenderForm = () => (
    <div className={styles.formMain}>
      <FormikForm
        initialValues={{
          ...multipleChoiceFieldNamesObject,
        }}
        onKeyDown={handleKeyDown}
        onSubmit={onSubmit}
        validationFunction={validationFunction}
        submitButtonText="Submit"
        submitButtonTheme="highlight"
      >
        <FormItems />
      </FormikForm>
    </div>
  );

  const submitStyle = `formsubmit${theme}`;
  const submitDefaultTitle = <h2>Thanks for your interest in the 2022 CSF Trends Report</h2>;
  const submitDefaultPara = <p>We're delivering the link directly to your inbox. Enjoy!</p>;

  const SubmitMessage = () => (
    <div className={styles[submitStyle]}>
      <div className={styles.checkCircle}>
        <img src={Check} alt="Check icon" />
      </div>
      {<h2>{submissionTitle?.text}</h2> || submitDefaultTitle}
      {<p>{submissionParagraph?.text}</p> || submitDefaultPara}
    </div>
  );

  const LightLogo = () => (
    <div className={styles.logoLight}>
      <img src={Logo} alt="Equitise logo" className={styles.logo1} />
    </div>
  );

  const DarkLogo = () => (
    <div className={styles.logoDark}>
      <img src={Logo} alt="Equitise logo" className={styles.logo} />
    </div>
  );

  const TwoColumn = () => (
    <Section className={styles[theme]} noContainer as="div">
      <div className={styles[formStyle]}>
        <div className={styles.container}>
          <div className={styles.textBox1}>
            {showForm && (
              <>
                <h1>{formTitle.text}</h1>
                <p>{description.text}</p>
              </>
            )}
          </div>
          <div className={styles.formMargin}>{showForm ? <RenderForm /> : <SubmitMessage />}</div>
        </div>
      </div>
    </Section>
  );

  const Centred = () => (
    <div ref={submitMessageRef}>
      <Section className={styles[theme]} noContainer as="div" spacing="n">
        <div className={styles[formStyle]}>
          <div className={styles.textBox}>
            {showForm && (
              <>
                <h1 className={styles.formTitle}>{formTitle.text}</h1>
                <p>{description.text}</p>
              </>
            )}
          </div>
          <div>{showForm ? <RenderForm /> : <SubmitMessage />}</div>
          <div className={`${styles.logoDiv} ${showForm && styles.hideOnMobile}`}>
            {theme === 'Dark' ? <DarkLogo /> : <LightLogo />}
          </div>
        </div>
      </Section>
    </div>
  );

  const defaultStyle = 'TwoColumns';

  return <>{formStyle === defaultStyle ? <TwoColumn /> : <Centred />}</>;
};
