// extracted by mini-css-extract-plugin
export var g = "SocialLink-module--g--ylrkJ";
export var googlePlus = "SocialLink-module--google-plus--qiEuL";
export var medium = "SocialLink-module--medium--f14E0";
export var primary = "SocialLink-module--primary--tXAE9";
export var secondary = "SocialLink-module--secondary--fM3vr";
export var small = "SocialLink-module--small--ph9nb";
export var socialLink = "SocialLink-module--social-link---9CEe";
export var svg = "SocialLink-module--svg--oRicn";
export var tertiary = "SocialLink-module--tertiary--aD3T1";
export var white = "SocialLink-module--white--kMeCO";