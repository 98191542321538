import React, { useState } from 'react';

import ReactPlayer from 'react-player';

import * as styles from './Video.module.scss';

export const Video = ({ src }) => {
  const [ready, setReady] = useState(false);

  const videoReady = () => {
    setReady(true);
  };

  return (
    <div className={`${styles.video} ${ready ? 'ready' : 'loading-video'}`}>
      <ReactPlayer url={src} onReady={videoReady} playing controls muted loop volume={1} width="100%" height="100%" />
    </div>
  );
};
