import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { LocalAPI } from '../../apis/local';
import { destructureNetworkError } from '../../utils';

export const useFetchResource = ({
  isExternal,
  endPoint,
  fallback = null,
  listeners = [],
  condition = true,
  params = [],
  key = 'data',
  onCompleted,
  onFail,
}) => {
  const { CancelToken } = axios;
  const source = CancelToken.source();

  const [resource, setResource] = useState(fallback);

  const [loading, setLoading] = useState(condition);

  const [errorMessage, setErrorMessage] = useState('');

  const [numRefreshes, setNumRefreshes] = useState(0);

  const refreshRequest = () => setNumRefreshes(numRefreshes + 1);

  const clearResource = () => setResource(fallback);

  useEffect(() => {
    const makeRequest = async () => {
      try {
        setLoading(true);

        const axiosParams = { url: endPoint, params, cancelToken: source.token };
        // Refactor to use base url and end point - more extendable for different base urls e.g. lambda / API etc.
        let response;
        if (isExternal) {
          response = await axios(axiosParams);
        } else {
          response = await LocalAPI(axiosParams);
        }

        const { data: responseData } = response;
        const destructuredData = responseData[key] || responseData;

        setResource(destructuredData);

        if (onCompleted) onCompleted(destructuredData);
      } catch (error) {
        if (axios.isCancel(error)) {
          console.log('Request cancelled');
        } else {
          if (onFail) onFail(error);

          const { unhandledErrMsg, message } = destructureNetworkError(error);
          setErrorMessage(unhandledErrMsg || message);
          console.error(error);
        }
      } finally {
        setLoading(false);
      }
    };

    if (condition) makeRequest();

    return () => {
      source.cancel('Fetch resource cancelled because component unmounted');
    };
  }, [numRefreshes, ...listeners]);

  return { loading, error: errorMessage, resource, refreshRequest, clearResource };
};
