import React from 'react';
import { Spinner } from 'components';
import * as styles from './Loading.module.scss';

// JL: Not sure why size is being passed into outer div, not being used in stylesheet

export const Loading = ({ text, fullScreen, size, light }) => (
  <div className={`${styles.loading} ${fullScreen ? styles.fullScreen : styles.inline} ${size}`}>
    <div className={styles.loadingContainer}>
      <Spinner size={size} light={light} />
      {text && <span className={styles.loadingText}>{text}</span>}
    </div>
  </div>
);
