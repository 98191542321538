import { transformCamelKeysToSnake } from '../index';
import { requestSubscribeEoi, LambdaClient } from '../../apis';
import { OPT_OUT_NEWSLETTER } from '../constants/autopilotCustomFields';

// Ensure Window object is fully loaded before making call
export const checkWindowObjectCallabe = ({ windowObj, name, objective, ...otherDebugginInfo }) => {
  const IS_BROWSER = typeof window !== 'undefined';
  if (IS_BROWSER && !!windowObj) {
    return true;
  }
  console.error(`${name} unavailable to call`, {
    isBrowser: IS_BROWSER,
    windowObj,
    objective,
    name,
    ...otherDebugginInfo,
  });
  return false;
};

export const appendSuffixToEvent = ({ eventName, eventNameSuffix }) => {
  const name = eventNameSuffix ? `${eventName} ${eventNameSuffix}` : eventName;
  return name;
};

export const pushDataLayerEvent = ({ eventName, eventData }) => {
  if (
    checkWindowObjectCallabe({
      windowObj: window?.dataLayer,
      name: 'GA Data Layer',
      objective: 'Track Data Layer event',
      eventName,
      eventData,
    })
  ) {
    window.dataLayer.push({ event: eventName, ...eventData });
  }
};

export const createOrUpdateAutopilotUser = ({ email, country, firstName, lastName, optOutNewsletter, listName }) => {
  const customFields = [{ name: OPT_OUT_NEWSLETTER, value: optOutNewsletter }];
  if (!process.env.IS_STAGING) {
    LambdaClient.post('/track-ap', { email, country, firstName, lastName, customFields, listName });
  }
};
export const createorUpdateIntercomUser = async ({ contactId, email, name, customAttributes }) => {
  // if no email creates a contact in Intercom, otherwise updates the existing contact
  const payload = { contactId, email, name, customAttributes };
  try {
    const updateContactResponse = await LambdaClient.post('update-contact', payload);
    // updates Intercom context provider with intercom id
    return updateContactResponse;
  } catch (error) {
    console.error(error);
  }
};

// use lambda api to push event and user information to amplitude.  Amplitude requires a userId which we pass through as an email address if there is one in context, or '00000' if not to ensure the event still fires`.
export const trackAmplitudeEvent = ({ email, eventName, eventData, userData }) => {
  const events = {
    userId: email?.toLowerCase() || '00000',
    eventType: eventName,
    eventProperties: eventData,
    userProperties: userData,
  };
  try {
    return LambdaClient.post('track-amp', { events });
  } catch (trackAmplitudeEventError) {
    console.error('trackAmplitudeEventError', trackAmplitudeEventError);
    console.error('trackAmplitudeEventError name', trackAmplitudeEventError?.name);
    console.error('trackAmplitudeEventError message', trackAmplitudeEventError?.message);
  }
};

export const trackEoiSubscribed = async values => {
  const {
    firstName,
    lastName,
    email,
    offerName,
    uid,
    prismicId,
    subscribeToComms,
    countryOfResidence,
    hasTargetMax,
    amount,
    phone,
    isEoi: isPreLaunch,
  } = values;

  const basePayload = {
    firstName,
    lastName,
    email,
    country: countryOfResidence,
    type: hasTargetMax ? 'invest' : 'eoi',
    amount,
    phone,
    isPreLaunch,
    optOutNewsletter: !subscribeToComms,
  };

  const apiPayload = transformCamelKeysToSnake({ ...basePayload, eoiName: offerName, prismicUid: uid, prismicId });

  // If in production env (no staging setup for Autopilot) create / update contact
  if (!process.env.IS_STAGING) {
    try {
      const apPayload = { email, country: countryOfResidence, firstName, lastName };
      if (subscribeToComms) apPayload.customFields = [{ name: OPT_OUT_NEWSLETTER, value: false }];
      createOrUpdateAutopilotUser(apPayload);
    } catch (upApErr) {
      console.error('Update contact failed', { error: upApErr, email });
    }
  }

  try {
    return requestSubscribeEoi(apiPayload);
  } catch (eqSubscribeErr) {
    console.error(eqSubscribeErr);
    return { success: false };
  }
};

// use lambda api update Ortto contact with a tag containing the offer name. This is used to create an audience in ortto to send an sms
export const trackOrtto = payload => {
  // if (!process.env.IS_STAGING) {
  try {
    return LambdaClient.post('track-ort', { payload });
  } catch (trackOrttoError) {
    console.error('Update or create Ortto contact failed', {
      error: trackOrttoError,
    });
  }
  // }
};
