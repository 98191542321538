// extracted by mini-css-extract-plugin
export var Centred = "styles-module--Centred--moz-I";
export var Dark = "styles-module--Dark--Mfp6b";
export var Light = "styles-module--Light--wBUaT";
export var TwoColumns = "styles-module--TwoColumns--xshD4";
export var active = "styles-module--active--eUxMt";
export var categoryFields = "styles-module--categoryFields--+TzFP";
export var checkCircle = "styles-module--checkCircle--8fKlz";
export var checkboxMessage = "styles-module--checkboxMessage--O9DTv";
export var container = "styles-module--container--MxujG";
export var field = "styles-module--field--mzN2-";
export var formMain = "styles-module--formMain--YX+jQ";
export var formMargin = "styles-module--formMargin--35Pxz";
export var formTitle = "styles-module--formTitle--yL1RL";
export var formsubmitDark = "styles-module--formsubmitDark--6o4P0";
export var formsubmitLight = "styles-module--formsubmitLight--HW49J";
export var hideOnMobile = "styles-module--hideOnMobile--ein1f";
export var line = "styles-module--line--5zu-u";
export var logo = "styles-module--logo--+awc6";
export var logo1 = "styles-module--logo1--8hWwf";
export var logoDark = "styles-module--logoDark--tZ46n";
export var logoDiv = "styles-module--logoDiv--GliS9";
export var logoLight = "styles-module--logoLight--kKbmU";
export var multipleChoice = "styles-module--multipleChoice--I6snK";
export var multipleChoiceQuestion = "styles-module--multipleChoiceQuestion--nTOVX";
export var nameContainer = "styles-module--nameContainer--q3Yk4";
export var nameContainerChild = "styles-module--nameContainerChild--83Acq";
export var primary = "#06004d";
export var privacy = "styles-module--privacy--FASwA";
export var secondary = "#4dafd7";
export var textBox = "styles-module--textBox--MsKps";
export var textBox1 = "styles-module--textBox1--GsApR";
export var validationError = "styles-module--validation-error--rPEZi";