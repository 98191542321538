import React, { useState } from 'react';
import OnVisible from 'react-on-visible';
import { Tagline, Section, Icon } from 'components';
import { SectionHeading } from 'components/DealRoom';
import * as styles from './styles.module.scss';

export const Accordion = ({
  data: {
    primary: {
      heading,
      content: { html: description },
      tagline,
    },
    items,
  },
  template,
}) => {
  const [openIndexes, setOpenIndexes] = useState([]);
  const templateClass = styles[template] || '';

  const onClick = (event, index) => {
    event.preventDefault();
    const clonedIndexes = [...openIndexes];
    const openIndex = clonedIndexes.indexOf(index);
    const isActive = openIndex !== -1;
    if (isActive) {
      clonedIndexes.splice(openIndex, 1);
      setOpenIndexes(clonedIndexes);
    } else {
      clonedIndexes.push(index);
      const filteredIndex = clonedIndexes.filter(num => num === index);
      setOpenIndexes(filteredIndex);
    }
  };

  const isOpen = index => (openIndexes.indexOf(index) !== -1 ? 'open' : 'closed');

  return (
    <div className={templateClass || ''}>
      <OnVisible className={`${styles.accordionSection} ${styles[templateClass] || ''} accordion-section`} percent={20}>
        <Section className={styles.wrapper} as="div" noContainer sliceName="Accordion">
          <section className={`${styles.accordionBlock} accordion-block`}>
            {tagline && tagline.text && <Tagline inheritedClassname={styles.tagline} tagline={tagline} />}
            {heading && <SectionHeading heading={heading} />}
            <div dangerouslySetInnerHTML={{ __html: description }} />
            <section className={styles.accordion}>
              {items.map((item, index) => {
                const {
                  heading: { text: rowTitle },
                  content: { html: rowContent },
                } = item;
                return (
                  <div className={`${styles.accordionItem} ${styles[isOpen(index)]}`} key={rowTitle}>
                    <a href="#toggle" onClick={event => onClick(event, index)} className={styles.accordionItemTitle}>
                      {rowTitle} <Icon type="chevron_down" />
                    </a>
                    <div className={styles.accordionItemBody} dangerouslySetInnerHTML={{ __html: rowContent }} />
                  </div>
                );
              })}
            </section>
          </section>
        </Section>
      </OnVisible>
    </div>
  );
};
