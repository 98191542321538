import React, { createContext, useState, useContext } from 'react';
import { useMutation } from '@apollo/client';
import { LambdaClient } from '../apis';
import { createorUpdateIntercomUser, getCompleteUserDataIntercom } from '../utils/analytics';
import { AuthContext } from './AuthContext';
import { UPDATE_INTERCOM_ID } from './TrackEventContextMutations';
import apolloClient from '../gatsby-theme-apollo/client';

export const TrackEventContext = createContext(null);

// Provides the intercom user id globally
export const TrackEventProvider = ({ children }) => {
  const [intercomId, setIntercomId] = useState('');
  const [intercomAttributes, setIntercomAttributes] = useState('');
  const [intercomEmail, setIntercomEmail] = useState('');
  const { resource: user, refreshRequest: refetchAuthContextUserData } = useContext(AuthContext) || {};
  // const { intercomContactId: userIntercomContactId, id: userId } = user;
  const userIntercomContactId = user?.intercomContactId;
  const userId = user?.id;
  const [updateUserWithContactId] = useMutation(UPDATE_INTERCOM_ID, {
    client: apolloClient,
  });

  // checks context to see if intercomId exists in state, if it doesn't, createOrUpdateIntercomUser will update the contact and get the intercomContactId in response. Use intercomId found in context if state already exists, or the intercomContactId if it didn't, to post the event to the user
  const trackIntercomEvent = async ({
    email,
    name,
    customAttributes,
    eventName,
    metadata,
    questionAnswered,
    questionAsked,
  }) => {
    // intercomContactId will be the response returned from createOrUpdateIntercomUser.
    let intercomContactId = '';
    if (intercomId || userIntercomContactId) {
      intercomContactId = intercomId || userIntercomContactId;
    }

    // if no intercomId exists in state, and no email provided, return nothing because we cant track an event or create/update contact with neither
    const identifier = questionAnswered || questionAsked ? email : intercomContactId || email;
    if (!identifier) {
      return;
    }

    const storedContactId = questionAnswered || questionAsked ? '' : Boolean(intercomContactId);

    const noStoredContactIdWithEmail = !storedContactId && email;

    if (noStoredContactIdWithEmail) {
      try {
        const contactIdResponse = await createorUpdateIntercomUser({
          email,
          name,
          customAttributes,
        });
        intercomContactId = contactIdResponse?.data?.contactId;
        setIntercomAttributes(contactIdResponse?.data?.intercomAttributes);
        setIntercomEmail(contactIdResponse?.data?.email);

        if (!questionAnswered) {
          setIntercomId(intercomContactId);
          if (user && !userIntercomContactId) {
            await updateUserWithContactId({
              variables: {
                userId,
                userInput: {
                  intercomContactId,
                },
              },
            });
            await refetchAuthContextUserData();
          }
        }
      } catch (createIntercomUserError) {
        console.error('Attempt to create intercom user failed: ', createIntercomUserError);
      }
    }

    // post the event to the user using the intercomId in state or intercomContactId response from createorUpdateIntercomUser
    if (intercomContactId) {
      try {
        return LambdaClient.post('/track-event', {
          contactId: intercomContactId,
          eventName,
          metadata,
        });
      } catch (error) {
        console.error(error);
      }
    }
  };

  return (
    <TrackEventContext.Provider value={{ trackIntercomEvent, intercomId, intercomAttributes, intercomEmail }}>
      {children}
    </TrackEventContext.Provider>
  );
};

TrackEventContext.displayName = 'TrackEventContext';
