// extracted by mini-css-extract-plugin
export var capabilities = "styles-module--capabilities--QRRz9";
export var capabilitiesBody = "styles-module--capabilities-body--xBUsj";
export var capability = "styles-module--capability--cSCOW";
export var capabilityIcon = "styles-module--capability-icon--MMgXX";
export var gatsbyImage = "styles-module--gatsby-image--hkjyM";
export var pageSliceTitle = "styles-module--page-slice-title--V-KtS";
export var primary = "#06004d";
export var secondary = "#4dafd7";
export var visible = "styles-module--visible--Z5OLf";
export var wrapper = "styles-module--wrapper--tvIN9";