import React from 'react';
import shortid from 'shortid';
import { PostCard } from './PostCard';
import * as styles from './LatestPostsSection.module.scss';
import Section from '../Section';

const postCardClassnames = {
  postCard: styles.postCard,
  type: styles.latest,
  postCardImage: styles.postCardImage,
  postCardTitle: styles.postCardTitle,
  postCardContent: styles.postCardContent,
  postCardInfo: styles.postCardInfo,
  postCardImageWrapper: styles.postCardImageWrapper,
  date: styles.date,
};

export const LatestPostsSection = ({ posts, pathname, inheritedClassnames }) => {
  const validClassnames = inheritedClassnames || {};

  return (
    <Section
      className={styles.latestSection}
      containerClassName={`${validClassnames.blog || ''} ${validClassnames.wrapper || ''} blog wrapper`}
    >
      <h3 className={`section-title ${validClassnames.sectionTitle || ''}`}>The Latest</h3>
      <div className={styles.latestPosts}>
        {posts.slice(0, 6).map(post => (
          <PostCard
            inheritedClassnames={postCardClassnames}
            post={post}
            pathname={pathname}
            type="latest"
            displayDate
            key={shortid.generate()}
            contentCharLimit={50}
            headingCharLimit={40}
            tileLocation="LatestPostsSection"
          />
        ))}
      </div>
    </Section>
  );
};
