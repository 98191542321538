import React from 'react';
import { Button, Card, Tagline, Section } from 'components';
import * as styles from './styles.module.scss';

export const ImageAndText = ({ data }) => {
  const { primary } = data;
  const {
    tagline,
    title,
    content,
    image,
    image_position: imagePosition,
    cta_text: ctaText,
    cta_link: ctaLink,
  } = primary;

  const hasTitle = title?.text;
  const hasContent = content?.html;
  const hasCtaText = ctaText?.text;
  const hasCtaLink = ctaLink?.url;
  const imagePositionLowerCase = imagePosition.toLowerCase();
  const cardClassnames = {
    card: styles.card,
    imageTextCard: styles.iimageTextCard,
    cardImageWrapper: styles.cardImageWrapper,
    gatsbyImage: styles.gatsbyImage,
    cardBody: styles.cardBody,
  };

  return (
    <Section
      className={`${styles.imageText} ${(imagePosition && styles[imagePositionLowerCase]) || ''}`}
      containerClassName={`${styles.wrapper}`}
      sliceName="ImageAndText"
    >
      <Card imageProps={{ image }} className={styles.imageTextCard} inheritedClassnames={cardClassnames}>
        <Tagline tagline={tagline} inheritedClassname={styles.tagline} />
        {hasTitle && <h3 className={styles.imageTextTitle}>{hasTitle}</h3>}
        {hasContent && <div dangerouslySetInnerHTML={{ __html: hasContent }} className={styles.imageTextDescription} />}
        {hasCtaText && (
          <Button to={hasCtaLink} inheritedClassname={styles.button}>
            {hasCtaText}
          </Button>
        )}
      </Card>
    </Section>
  );
};
