import React, { useEffect, useState } from 'react';
import { Image, Icon } from 'components';
import ReactDOM from 'react-dom';
import * as styles from './Modal.module.scss';

const setElement = () => {
  if (process.env.IS_STORYBOOK === 'YES') {
    return 'root';
  }
  return '___gatsby';
};

const appRoot = typeof window !== 'undefined' ? document.getElementById(setElement()) : null;

export const Modal = props => {
  const {
    active,
    closeModal,
    children,
    className,
    warnBeforeClose,
    title,
    logo,
    icon,
    modalClassnames: inheritedClassnames,
    ipoModal = false,
  } = props;

  const [mounted, setMounted] = useState(false);
  const validClassnames = inheritedClassnames || {};
  // console.log('inheritedClassnames', inheritedClassnames);
  // console.log('validClassnames', validClassnames);
  const onUnload = event => {
    // eslint-disable-next-line
    if (warnBeforeClose && event) event.returnValue = 'Are you sure?';
  };
  const handleEscKey = event => {
    if (event.keyCode === 27 && closeModal) {
      return closeModal(event);
    }
  };
  const handleModalBackgroundClick = event => {
    if (!event || !event.target || !closeModal) return null;
    const { className: clickedClassName } = event.target;
    if (clickedClassName && typeof clickedClassName === 'string' && clickedClassName.indexOf('modal ') !== -1) {
      return closeModal(event);
    }
    return null;
  };
  useEffect(() => {
    if (active) {
      document.addEventListener('keydown', handleEscKey, false);
      window.addEventListener('beforeunload', onUnload);
    }

    setMounted(true);
    return () => {
      document.removeEventListener('keydown', handleEscKey, false);
      window.removeEventListener('beforeunload', onUnload);
    };
  }, [active]);
  if (!active || !mounted) return null;

  return ReactDOM.createPortal(
    <div
      className={`${styles.modal} ${validClassnames.modal || ''} modal ${className || ''}`}
      onClick={event => handleModalBackgroundClick(event)}
      role="dialog"
    >
      <div
        className={`${validClassnames.modalContent || ''} ${
          ipoModal ? styles.ipoModalContent : styles.modalContent
        } modal-content ${title || logo ? styles.customTitle : null}`}
      >
        <div className={`${styles.modalTitleContainer} ${logo ? styles.eoi : styles.noEoi}`}>
          {logo ? (
            <span className={styles.modalLogo}>
              <Image inheritedClassname={validClassnames.gatsbyImage} image={logo} alt={logo?.alt} />
            </span>
          ) : null}
          {icon ? (
            <span className={styles.modalIcon}>
              <Icon type={icon} alt={icon?.alt} />
            </span>
          ) : null}
          {title ? <h3 className={styles.modalTitle}>{title}</h3> : null}
        </div>
        <div className={`${styles.modalContentBody} ${validClassnames.modalContentBody || ''}`}>{children}</div>
      </div>
    </div>,
    appRoot
  );
};

export default Modal;
