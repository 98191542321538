import React from 'react';
import { PostCard } from './PostCard';
import * as styles from './FeaturedPosts.module.scss';
import Section from '../Section';

const inheritedClassnamesMain = {
  postCard: styles.postCard,
  type: styles.featuredPostsMain,
  postCardImageWrapper: styles.postCardImageWrapper,
  postCardImage: styles.postCardImage,
  postCardTitle: styles.postCardTitle,
  categories: styles.categories,
  postCardInfo: styles.postCardInfo,
  postCardContent: styles.postCardContent,
};

const inheritedClassnamesSubOne = {
  postCard: styles.postCard,
  type: `${styles.featuredPostsSub} ${styles.featuredPostsSubOne}`,
  postCardImageWrapper: styles.postCardImageWrapper,
  postCardImage: styles.postCardImage,
  postCardTitle: styles.postCardTitle,
  categories: styles.categories,
  postCardInfo: styles.postCardInfo,
  postCardContent: styles.postCardContent,
};

const inheritedClassnamesSubTwo = {
  postCard: styles.postCard,
  type: `${styles.featuredPostsSub} ${styles.featuredPostsSubTwo}`,
  postCardImageWrapper: styles.postCardImageWrapper,
  postCardImage: styles.postCardImage,
  postCardTitle: styles.postCardTitle,
  categories: styles.categories,
  postCardInfo: styles.postCardInfo,
  postCardContent: styles.postCardContent,
};

export const FeaturedPosts = ({ main, subPostOne, subPostTwo, pathname, inheritedClassnames }) => {
  const validClassnames = inheritedClassnames || {};
  return (
    <Section
      className={`blog ${validClassnames.blog || ''} ${styles.featuredPosts}`}
      containerClassName={`${styles.wrapper} ${validClassnames.wrapper || ''} wrapper`}
    >
      {main && (
        <PostCard
          post={main}
          pathname={pathname}
          inheritedClassnames={inheritedClassnamesMain}
          type="featured-posts-main"
          contentCharLimit={150}
          featured
          tileLocation="FeaturedPosts"
        />
      )}
      {subPostOne && (
        <PostCard
          post={subPostOne}
          pathname={pathname}
          inheritedClassnames={inheritedClassnamesSubOne}
          type="featured-posts-sub featured-posts-sub-one"
          hideContent
          featured
          tileLocation="FeaturedPosts"
        />
      )}
      {subPostTwo && (
        <PostCard
          post={subPostTwo}
          pathname={pathname}
          inheritedClassnames={inheritedClassnamesSubTwo}
          type="featured-posts-sub featured-posts-sub-two"
          hideContent
          featured
          tileLocation="FeaturedPosts"
        />
      )}
    </Section>
  );
};
