import React from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import * as styles from './Image.module.scss';

export const Image = props => {
  const { image, alt, className = '', inheritedClassname, objectFit, ...otherImgProps } = props;
  if (!image) return <div className={`${styles.gatsbyImage} ${inheritedClassname || ''} placeholder ${className}`} />;
  const { localFile, type } = image;
  if (type && type === 'private') {
    return (
      <div className={`${styles.gatsbyImageUrl} ${inheritedClassname || ''} gatsby-image ${className}`}>
        <img
          className={`${styles.imgPrivateOffer} img`}
          src={image.url}
          alt={image.alt || alt || 'Crowdfunding Australia'}
        />
      </div>
    );
  }

  // Gifs do not work with Gatsby image. If concerned the Prismic user may add a gif to a slice / field query for the extension
  if (localFile && localFile.extension && (localFile.extension === 'gif' || localFile.extension === 'svg')) {
    const src = localFile.url || image.url;
    return (
      <div className={`${styles.gatsbyImage} ${inheritedClassname || ''} ${className}`}>
        <img src={src} alt={image.alt || alt || 'Crowdfunding Australia'} />
      </div>
    );
  }
  if (localFile?.childImageSharp?.gatsbyImageData) {
    return (
      <GatsbyImage
        className={`${styles.gatsbyImage} ${inheritedClassname || ''} gatsby-image ${className}`}
        image={getImage(localFile)}
        alt={image.alt || alt || 'Crowdfunding Australia'}
        objectFit={objectFit || 'cover'}
        loading="eager"
        {...otherImgProps}
      />
    );
  }
  if (image.url) {
    return (
      <div className={`${styles.gatsbyImageUrl} ${inheritedClassname || ''} gatsby-image ${className}`}>
        <img className={`${styles.img} img`} src={image.url} alt={image.alt || alt || 'Crowdfunding Australia'} />
      </div>
    );
  }

  if (image) {
    return (
      <div className={`${styles.gatsbyImage} ${inheritedClassname || ''} gatsby-image ${className}`}>
        <img className={`${styles.img} img`} src={image} alt={image.alt || alt || 'Crowdfunding Australia'} />
      </div>
    );
  }
  return <div className={`${styles.gatsbyImage} ${inheritedClassname || ''} gatsby-image placeholder ${className}`} />;
};
