import React from 'react';
import { Link } from 'components';
import { capitaliseWord } from 'utils';
import { PostCard } from 'components/BlogLandingPage';
import shortid from 'shortid';
import * as styles from './CategoryCard.module.scss';

const inheritedClassnames = {
  postCard: styles.postCard,
  type: styles.categorySpecific,
  postCardImageWrapper: styles.postCardImageWrapper,
  postCardImage: styles.postCardImage,
  postCardContent: styles.postCardContent,
  postCardTitle: styles.postCardTitle,
};

export const CategoryCard = ({ name, posts, pathname }) => (
  <div className={styles.categoryCard}>
    <h4 className={styles.categoryCardName}>{capitaliseWord(name).replace(/-/g, ' ')}</h4>
    <div className={styles.categoryCardPosts}>
      {posts.map(post => (
        <PostCard
          post={post}
          pathname={pathname}
          inheritedClassnames={inheritedClassnames}
          type="category-specific"
          key={shortid.generate()}
          contentCharLimit={80}
          headingCharLimit={40}
          hidePostInfo
          tileLocation="CategoryCard"
        />
      ))}
    </div>
    <Link to={`/${pathname}/${name}`} className={styles.categoryCardViewMoreLink}>
      <span>View more about {name.replace(/-/g, ' ')}</span>
    </Link>
  </div>
);
