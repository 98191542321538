import React, { useContext } from 'react';
import OnVisible from 'react-on-visible';
import { Section } from 'components';
import * as styles from './styles.module.scss';

export const CtaDisclaimer = ({
  data: {
    primary: { header, disclaimer_text: disclaimerText },
  },
}) => {
  const disclaimerDefault =
    '<p>This information is provided by Equitise (CAR) Pty Ltd (Equitise (CAR)), Equitise (CAR) are a corporate authorised representative (CAR No 001260971) of Red Leaf Securities Pty Ltd (ACN ACN 606 000 800, AFSL 510097) (Red Leaf).</p><p>This information has been prepared by Equitise (CAR) and it is for general information purposes only and is not intended to promote or recommend any particular product or services offered by Equitise. This information does not take into account the objectives, financial situation or needs of any investor. Before making an investment decision, investors should read the relevant offer document and (if appropriate) seek professional advice to determine whether the investment is suitable for them. This information is intended only for wholesale clients within the meaning of s761G and s761GA of the Corporations Act 2001 (Cth)</p>';

  const displayDisclaimerText = disclaimerText.text ? disclaimerText.html : disclaimerDefault;

  return (
    <OnVisible percent={20} visibleClassName={styles.visible}>
      <Section noContainer sliceName="Team" className={styles.landingCtaDisclaimerWrapper}>
        <div className={styles.contentContainer}>
          <div className={styles.disclaimerHeader}>{header.text || 'Disclaimer'}</div>
          <div className={styles.disclaimerText} dangerouslySetInnerHTML={{ __html: displayDisclaimerText }}></div>
        </div>
      </Section>
    </OnVisible>
  );
};
