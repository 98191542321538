import React from 'react';
import * as styles from './Tagline.module.scss';

export const Tagline = ({ tagline, icon, HtmlElement = 'h5', inheritedClassname }) => {
  if (!tagline || !tagline.text) return null;

  return (
    <HtmlElement className={`${styles.tagline} ${inheritedClassname || ''}`}>
      {icon && <span className={`icon icon-${icon}`} />}
      {tagline.text}
    </HtmlElement>
  );
};
