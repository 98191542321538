// Truncate text takes a string and truncates it by a specified number of characters
export const truncateText = (text, charLimit = 30, symbol = '...') => {
  // Don't truncate the word if specified character limit is more than the number of characters in the text
  if (charLimit >= text.length) return text;
  // Split the text into words
  const words = text.split(' ');
  // Keep a running total of the number of characters in each word
  let charCount = 0;
  // Filter out words where the character limit has been exceeded
  return `${
    words
      .filter(word => {
        charCount += word.length;
        return charCount < charLimit;
      })
      .join(' ')
      // Remove the last character if it's not an alphanumeric character
      .replace(/[^a-z\d]*$/gi, '')
    // Let the user know if the word has been truncated by adding the specified symbol
  }${symbol}`;
};
