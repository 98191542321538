import React, { useState, useContext } from 'react';
import shortid from 'shortid';
import { Icon } from './Icon';
import { trackAmplitudeEvent } from '../utils';
import { AuthContext, TrackEventContext } from '../context';
import { userData } from '../utils/userDataToAmplitude';
import * as styles from './Accordion.module.scss';

export const Accordion = ({ items, className, isInvestorRewardsSlice, rangeText, rangeValues, accordionEventType }) => {
  const [openIndexes, setOpenIndexes] = useState([]);
  const { resource: user } = useContext(AuthContext) || {};
  const { intercomAttributes, intercomEmail } = useContext(TrackEventContext) || {};

  const onClick = (event, index, item) => {
    event.preventDefault();
    const clonedIndexes = [...openIndexes];
    const openIndex = clonedIndexes.indexOf(index);
    const isActive = openIndex !== -1;
    if (isActive) {
      clonedIndexes.splice(openIndex, 1);
      setOpenIndexes(clonedIndexes);
    } else {
      clonedIndexes.push(index);
      const filteredIndex = clonedIndexes.filter(num => num === index);
      setOpenIndexes(filteredIndex);
    }

    try {
      trackAmplitudeEvent({
        email: user?.email || intercomEmail,
        eventName: `Clicked Accordian`,
        eventData: {
          accordionType: accordionEventType,
          itemTitle: item.title,
        },
        userData: user?.email ? userData(user) : intercomAttributes || {},
      });
    } catch (error) {
      console.error('Amplitude event clicked accordion type failed');
    }
  };

  const isOpen = index => (openIndexes.indexOf(index) !== -1 ? 'open' : 'closed');

  return (
    <section className={`${className || ''} ${styles.accordion}`}>
      {items?.map((item, index) => (
        <div className={`${styles.accordionItem}  ${styles[isOpen(index)]}`} key={shortid.generate()}>
          <a
            href="#toggle"
            onClick={event => onClick(event, index, item)}
            className={`${styles.accordionItemTitle} ${isInvestorRewardsSlice && styles.rewardTitle}`}
          >
            {item.title} <Icon type="chevron_down" />
          </a>

          {/* display this section under the title if the accordion is a reward */}
          {isInvestorRewardsSlice && (
            <p onClick={event => onClick(event, index, item)} className={styles.rewardThreshold}>
              {rangeText(items, item, item.rangeDescription)}
              <span className={styles.thresholdAmount}>{rangeValues(items, item, index)}</span>
            </p>
          )}

          <div className={styles.hiddenBody}>
            <div
              className={`${styles.accordionItemBody} ${isInvestorRewardsSlice && styles.rewardItemBody}`}
              dangerouslySetInnerHTML={{ __html: item.body }}
            />
          </div>
        </div>
      ))}
    </section>
  );
};
