import React from 'react';
import * as styles from './Section.module.scss';

const isDev = process.env.NODE_ENV === 'development';

const Section = props => {
  const { as, className, containerClassName, children, sliceName, noContainer, spacing, style } = props;

  const HtmlElement = as || 'section';

  const spacingClassNames = {
    s: styles.smallSpacing,
    l: styles.largeSpacing,
    n: styles.noSpacing,
  };

  return (
    <HtmlElement
      className={`${styles.section} ${spacingClassNames[spacing] || ''} ${className || ''}`}
      data-slice={isDev ? sliceName : null}
      style={style}
    >
      {noContainer ? children : <div className={`${styles.container} ${containerClassName || ''}`}>{children}</div>}
    </HtmlElement>
  );
};

export default Section;
