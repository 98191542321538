import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import * as styles from './SearchOverlay.module.scss';

const setElement = () => {
  if (process.env.IS_STORYBOOK === 'YES') {
    return 'root';
  }
  return '___gatsby';
};

const appRoot = typeof window !== 'undefined' ? document.getElementById(setElement()) : null;

// This is a copy of the Modal component, it is configured to only contain functionality suitable for the Search component
export const SearchOverlay = props => {
  const { active, closeModal, children, className } = props;
  const [mounted, setMounted] = useState(false);

  const handleEscKey = event => {
    if (event.keyCode === 27 && closeModal) {
      return closeModal(event);
    }
  };

  const handleModalBackgroundClick = event => {
    if (!event || !event.target || !closeModal) return null;
    const { className: clickedClassName } = event.target;
    if (
      clickedClassName &&
      typeof clickedClassName === 'string' &&
      clickedClassName.indexOf(`${styles.searchOverlay} `) !== -1
    ) {
      return closeModal(event);
    }
    return null;
  };

  useEffect(() => {
    if (active) {
      document.addEventListener('keydown', handleEscKey, false);
    }

    setMounted(true);
    return () => {
      document.removeEventListener('keydown', handleEscKey, false);
    };
  }, [active]);

  if (!active || !mounted) return null;

  return ReactDOM.createPortal(
    <div
      className={`${styles.searchOverlay} ${className || ''}`}
      onClick={event => handleModalBackgroundClick(event)}
      role="dialog"
    >
      <div className={styles.searchOverlayContent}>
        <div className={styles.searchOverlayContentBody}>{children}</div>
      </div>
    </div>,
    appRoot
  );
};

export default SearchOverlay;
