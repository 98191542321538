import React, { useState, useContext } from 'react';
import { pushDataLayerEvent, trackAmplitudeEvent, trackOrtto } from '../utils/analytics';
import { TrackEventContext, UtmContext, AuthContext } from '../context';
import { OPT_OUT_NEWSLETTER } from '../utils/constants/autopilotCustomFields';
import * as styles from './NewsletterSignup.module.scss';

export const NewsletterSignup = ({
  hideSubText,
  countryOfResidence,
  inheritedClassname,
  setSubscribed,
  subscribed,
  tilesDisplayed,
  isLandingPage,
}) => {
  const [value, setValue] = useState('');

  const [error, setError] = useState('');

  const [hasSubmission, setHasSubmission] = useState(false);

  const { resource: user } = useContext(AuthContext) || {};
  const { trackIntercomEvent } = useContext(TrackEventContext) || {};

  const { utmParams } = useContext(UtmContext) || {};
  const { utmSource, utmMedium, utmCampaign } = utmParams || {};

  const onChange = event => setValue(event.target.value);

  const onNewsletterSignup = event => {
    event.preventDefault();

    if (value) {
      try {
        trackOrtto({
          email: value,
          subscribeToEmails: true,
          tags: [`NEWSLETTER SUBSCRIBED`],
        });
      } catch (orttoSubscribeError) {
        console.error('Ortto newsletter subscribe failed', orttoSubscribeError);
      }

      try {
        trackIntercomEvent({
          email: value,
          customAttributes: {
            optOutNewsletter: false,
            country: countryOfResidence,
          },
          eventName: 'Newsletter Subscribed',
          metadata: {
            source: utmSource,
            medium: utmMedium,
            campaign: utmCampaign,
            ctaSource: isLandingPage ? 'Landing Page' : 'slice',
          },
        });
      } catch (newsletterSubscribedIntercomErr) {
        console.error('Newsletter Subscription Intercom Error', {
          error: newsletterSubscribedIntercomErr,
        });
      }

      try {
        trackAmplitudeEvent({
          email: value,
          eventName: 'Newsletter Subscribed',
          eventData: {
            ctaSource: isLandingPage ? 'Landing Page' : 'slice',
          },
          userData: {
            email: value,
            country: countryOfResidence,
            source: utmSource,
            medium: utmMedium,
            campaign: utmCampaign,
            state: user?.address?.state,
            subscribeToEmails: user?.subscribeToComms,
            firstName: user?.firstName,
            lastName: user?.lastName,
            sophisticatedStatus: user?.profile?.sophisticatedStatus?.name,
            telephone: user?.profile?.telephone,
            identityVerifiedAt: user?.identityVerifiedAt,
          },
        });
      } catch (trackDLAmpErr) {
        console.error('Amplitude event Newsletter Subscribed failed', {
          error: trackDLAmpErr,
        });
      }

      try {
        pushDataLayerEvent({ eventName: 'Newsletter Subscribed' });
      } catch (pushDataLayerNewsletterSubscribedFailed) {
        console.error('Data layer event Register failed', {
          error: pushDataLayerNewsletterSubscribedFailed,
        });
      }
    }

    setHasSubmission(true);
    setSubscribed(true);
  };

  return (
    <div className={`${styles.newsletterSignup} ${value ? 'active' : ''}`}>
      {!hasSubmission ? (
        <form>
          <div className={styles.newsletterSignupInputs}>
            <input type="email" placeholder="Enter your email address" onChange={onChange} value={value} required />
            {/* {error ? <div className="validation-error active">{error}</div> : <div className="validation-error" />} */}
            <button className="icon icon-plane-dark" type="submit" onClick={onNewsletterSignup}>
              Submit
            </button>
          </div>
        </form>
      ) : (
        <div
          className={`${styles.newsletterSignupConfirmation} ${tilesDisplayed &&
            subscribed &&
            styles.landingPageSubscribed}`}
        >
          <p>🎉 You have subscribed</p>
        </div>
      )}
      {!hideSubText && (
        <span className={`${styles.newsletterSignupSubtext} ${inheritedClassname || ''} newsletter-signup-subtext`}>
          {!hasSubmission
            ? 'Join our newsletter to stay up to date on offers and news'
            : "You'll be the first to know about our new offers. We'll also keep you updated on our current offers so keep your eyes peeled"}
        </span>
      )}
    </div>
  );
};
