import React, { useState, useContext } from 'react';
import OnVisible from 'react-on-visible';
import { BrandShape, Link, NewsletterSignup, Tagline, Section } from 'components';
import * as styles from './styles.module.scss';
import { TileGrid } from '../../components/TileGrid';
import { LandingPageContext } from '../../context';

export const Cta = props => {
  const { settings } = useContext(LandingPageContext) || {};

  const [subscribed, setSubscribed] = useState(false);

  const { data, countryOfResidence, inheritedClassname, template } = props;

  const { primary, items } = data;

  const { tagline, title, content, is_landing_page: isLandingPage } = primary;

  const tilesDisplayed = items?.length >= 1;

  const isAllBlogPosts = items?.filter(element => element.tile.type === 'deal_room').length <= 0;

  const hideHeaderTaglineOnSubmit =
    (tilesDisplayed && isLandingPage && subscribed && styles.landingPagetitletagline) || '';

  const ctaProps = {
    primary,
    countryOfResidence,
    items,
    setSubscribed,
    subscribed,
    tilesDisplayed,
    isLandingPage,
  };

  return (
    <OnVisible
      className={`${styles.ctaSection} ${inheritedClassname || ''} ${isLandingPage ? `${styles.isLandingPage}` : ''} ${
        tilesDisplayed && subscribed ? `${styles.subscribed}` : ''
      }`}
      visibleClassName={styles.visible}
    >
      <Section as="div" className={styles.wrapper} spacing="n" noContainer sliceName="Cta">
        <div className={`${styles.ctaSectionHeader} ${hideHeaderTaglineOnSubmit}`}>
          <Tagline tagline={tagline} inheritedClassname={styles.tagline} />
          <span className={styles.ctaSectionHeaderTitle}>{title.text}</span>
        </div>
        {content && content.html && (
          <div className={styles.ctaSectionContent} dangerouslySetInnerHTML={{ __html: content.html }} />
        )}
        <CtaAction {...ctaProps} />
      </Section>
      {isLandingPage && tilesDisplayed && subscribed && (
        <TileGrid
          title="Deals"
          tiles={items.slice(0, 3)}
          warningStatements={settings.data.warning_statements}
          isLandingPage={isLandingPage}
          isAllBlogPosts={isAllBlogPosts}
        />
      )}
      <BrandShape inheritedClassname={`${styles.brandShape} ${isLandingPage ? `${styles.isLandingPage}` : ''}`} />
    </OnVisible>
  );
};

const CtaAction = props => {
  const {
    call_to_action: type,
    button,
    button_link: buttonLink,
    secondary_button: secondaryButton,
    secondary_button_link: secondaryButtonLink,
    countryOfResidence,
    setSubscribed,
    subscribed,
    tilesDisplayed,
    isLandingPage,
  } = props;

  if (type === 'Buttons' && button && button.text) {
    return (
      <div className={styles.ctaSectionActions}>
        {secondaryButton && secondaryButton.text && (
          <Link to={secondaryButtonLink.url} className={`${styles.button} button white outline`}>
            {secondaryButton.text}
          </Link>
        )}
        <Link to={buttonLink.url} className={`${styles.button} button secondary`}>
          {button.text}
        </Link>
      </div>
    );
  }
  return (
    <div className={styles.ctaSectionNewsletter}>
      <NewsletterSignup
        countryOfResidence={countryOfResidence}
        setSubscribed={setSubscribed}
        subscribed={subscribed}
        tilesDisplayed={tilesDisplayed}
        isLandingPage={isLandingPage}
      />
    </div>
  );
};
