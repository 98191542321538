import React, { useEffect, useState, useContext } from 'react';
import { AUSTRALIA } from 'utils';
import { GeoContext } from '../context';
import { Link, Logo } from '.';
import * as styles from './InvestDropdown.module.scss';

export const InvestDropdown = ({ hide, retailLink }) => {
  const { countryName: countryOfResidence } = useContext(GeoContext) || {};

  const [showDropdown, setShowDropdown] = useState(false);

  useEffect(() => {
    document.addEventListener('click', handleClick, false);
    return () => {
      document.removeEventListener('click', handleClick, false);
    };
  }, [showDropdown]);

  if (hide) return null;

  // Close dropdown on outside click
  const handleClick = event => {
    if (!event || !event.target) return null;
    const { className } = event.target;
    if (showDropdown && className && typeof className === 'string' && className.indexOf('invest-dropdown') === -1) {
      return setShowDropdown(false);
    }
    return null;
  };

  const toggleDropDown = event => {
    event.preventDefault();
    setShowDropdown(!showDropdown);
  };
  return (
    <div className={`${styles.investDropdown} invest-dropdown ${showDropdown ? 'active' : 'inactive'}`}>
      <a href="#toggle" onClick={toggleDropDown} className={styles.investDropdownToggle}>
        I want to invest in
        <span className={`${styles.icon} icon icon-chevron-down-secondary`} />
      </a>
      <div className={styles.investDropdownOptions}>
        <Link className={styles.investDropdownOptionsItem} to="/wholesale">
          <Logo useSpan inheritedClassname={styles.logo} type="iconPrimary" />
          Wholesale Offers
        </Link>
        <Link className={styles.investDropdownOptionsItem} to="/ipo">
          <Logo useSpan inheritedClassname={styles.logo} type="iconPrimary" />
          IPO Offers
        </Link>
        <Link className={styles.investDropdownOptionsItem} to={retailLink?.to}>
          <Logo useSpan inheritedClassname={styles.logo} type="iconPrimary" />
          {retailLink?.text}
        </Link>
        <Link className={styles.investDropdownFooter} to="https://equitiseau.typeform.com/to/oSLLs1" target="_self">
          <span className={`${styles.icon} icon icon-question-dark`} />
          Not sure? Take our quiz
        </Link>
      </div>
    </div>
  );
};
