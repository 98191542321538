import { isObject } from 'utils';

// Regex to convert value from camel case to snake case
export const convertCameltoSnakeCase = value => value.replace(/([A-Z])/g, '_$1').toLowerCase();

export const convertSnaketoCamelCase = value =>
  value &&
  typeof value === 'string' &&
  value.replace(/([-_][a-z])/g, group =>
    group
      .toUpperCase()
      .replace('-', '')
      .replace('_', '')
  );

// convert any string to camel case - solution found here: https://stackoverflow.com/questions/2970525/converting-any-string-into-camel-case
// TODO clean up regex below
export const convertToCamelCase = str =>
  str
    ?.replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, (match, index) => {
      if (+match === 0) return ''; // or if (/\s+/.test(match)) for white spaces
      return index === 0 ? match.toLowerCase() : match.toUpperCase();
    })
    .replace(/-/g, '');

// function to change the key casing of keys in an object
// takes a function to change the key and returns a new object with the changed keys
// leaves the original object unchanged
export const transformKeyCasing = (transformationFunction, object) => {
  const newObject = {};
  // debugger;
  const orginialKeys = Object.keys(object);
  for (let i = 0; i < orginialKeys.length; i += 1) {
    const currentKey = orginialKeys[i];
    let val = object[currentKey];
    if (isObject(val)) val = transformKeyCasing(transformationFunction, val);
    newObject[transformationFunction(currentKey)] = val;
  }
  // debugger;
  return newObject;
};

// function to specifically transform object keys from camel to snake casing
export const transformCamelKeysToSnake = object => transformKeyCasing(convertCameltoSnakeCase, object);

export const transformSnakeKeysToCamel = object => transformKeyCasing(convertSnaketoCamelCase, object);

export const capitaliseString = string => string.charAt(0).toUpperCase() + string.slice(1);
