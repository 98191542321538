// extracted by mini-css-extract-plugin
export var featuredPosts = "FeaturedPosts-module--featured-posts--kMjBr";
export var featuredPostsMain = "FeaturedPosts-module--featured-posts-main--nedXv";
export var featuredPostsSub = "FeaturedPosts-module--featured-posts-sub--BA4dE";
export var featuredPostsSubOne = "FeaturedPosts-module--featured-posts-sub-one--yfbxs";
export var featuredPostsSubTwo = "FeaturedPosts-module--featured-posts-sub-two--Vrpce";
export var postCard = "FeaturedPosts-module--post-card--HMD91";
export var postCardContent = "FeaturedPosts-module--post-card-content--Cr1mX";
export var postCardImage = "FeaturedPosts-module--post-card-image--nlhL2";
export var postCardImageWrapper = "FeaturedPosts-module--post-card-image-wrapper--73Mqc";
export var postCardInfo = "FeaturedPosts-module--post-card-info--RzN2H";
export var postCardTitle = "FeaturedPosts-module--post-card-title--MJ05w";
export var primary = "#06004d";
export var secondary = "#4dafd7";
export var wrapper = "FeaturedPosts-module--wrapper--GzwTX";