import React, { useContext } from 'react';
import OnVisible from 'react-on-visible';
import { Image, SocialLink, Section, Video, TeamMembers, RegisterInterestForm } from 'components';
import { SectionHeading } from 'components/DealRoom';
import * as styles from './styles.module.scss';
import { LandingPageContext } from '../../context';

export const CtaImageVideo = ({
  // data,
  data: {
    primary: {
      featured_image: featuredImage,
      featured_video: featuredVideo,
      logo,
      tagline,
      headline,
      form_logo: formLogo,
      include_headline: includeHeadline,
      include_logo: includeLogo,
      link_to_offer: linkToOffer,
      container_image: containerImage,
    },
    items: teamMembers,
  },
}) => {
  const {
    prismicId,
    uid,
    companyName,
    primaryColour,
    secondaryColour,
    backgroundColour,
    textColour,
    isEoi,
    isSyndicateDeal,
    isWholesaleDeal,
  } = useContext(LandingPageContext) || {};

  const customFormHeaderColor = {
    backgroundColor: `${primaryColour}`,
  };

  const sectionStyles = {
    backgroundColor: `${backgroundColour}`,
  };

  const textStyles = {
    color: `${textColour}`,
  };

  const customColors = {
    primary: `${primaryColour}`,
    secondary: `${secondaryColour}`,
  };

  const showFeaturedImage = featuredImage?.localFile || featuredImage?.url;

  const showFeaturedVideo = featuredVideo?.url;

  const showLogo = logo?.localFile || logo?.url;

  return (
    <OnVisible percent={20} visibleClassName={styles.visible}>
      <Section noContainer sliceName="Team" className={styles.landingCtaImageVideoWrapper} style={sectionStyles}>
        <div className={styles.landingCtaImageVideoContainer}>
          <div className={styles.landingCtaImageVideoLogoContainer}>
            {showLogo && <Image image={logo} className={styles.landingCtaImageVideoLogo} />}
            <div className={styles.landingCtaImageVideo}>
              {(showFeaturedImage || showFeaturedVideo) &&
                (showFeaturedVideo ? (
                  <Video src={featuredVideo.url} />
                ) : (
                  <Image image={featuredImage} alt={featuredImage?.alt} />
                ))}
            </div>
          </div>
          <div className={styles.landingCtaImageVideoTextTeamContainer}>
            {headline && headline.html && (
              <div className={styles.landingCtaImageVideoHeadline}>
                <h1 style={textStyles}>{headline.text}</h1>
              </div>
            )}

            <div className={styles.landingCtaFormFormLinkContainer}>
              <RegisterInterestForm
                companyName={companyName?.text}
                logo={formLogo || logo}
                prismicId={prismicId}
                uid={uid}
                style={customFormHeaderColor}
                linkToOffer={linkToOffer}
                isEoi={isEoi}
                customStyle={customColors}
                isSyndicateDeal={isSyndicateDeal}
                isWholesaleDeal={isWholesaleDeal}
              />
            </div>

            {tagline && tagline.html && (
              <div className={styles.landingCtaImageVideoDescription}>
                <p style={textStyles}>{tagline.text}</p>
              </div>
            )}
            {teamMembers.length > 0 && <TeamMembers teamMembers={teamMembers} />}
          </div>
        </div>
      </Section>
    </OnVisible>
  );
};
