import React from 'react';
import { Formik, Form, useFormikContext } from 'formik';
import { Loading } from 'components';

export const FormikForm = ({
  children,
  validationFunction,
  initialValues,
  onSubmit,
  onKeyDown,
  submitButtonText = 'Submit',
  submitButtonTheme = 'primary',
  hideSubmitButton,
  onChange,
  handleBlur,
  validateOnChange,
  className,
  enableReinitialize,
  formikClassnames,
  customStyle,
}) => (
  <Formik
    initialValues={initialValues}
    validate={validationFunction}
    onSubmit={onSubmit}
    onChange={onChange}
    handleBlur={handleBlur}
    validateOnChange={validateOnChange}
    enableReinitialize={enableReinitialize}
  >
    <Form className={`form ${className || ''} ${formikClassnames?.form}`} onKeyDown={onKeyDown}>
      {children}
      {!hideSubmitButton && (
        <SubmitButton
          submitButtonTheme={submitButtonTheme}
          submitButtonText={submitButtonText}
          inheritedClassname={formikClassnames?.button}
          customStyle={customStyle}
        />
      )}
    </Form>
  </Formik>
);

const SubmitButton = ({ submitButtonTheme, submitButtonText, inheritedClassname, customStyle }) => {
  // TODO don't need this just use withloader prop
  const { isSubmitting } = useFormikContext();
  const customButtonColor = {
    backgroundColor: customStyle?.primary,
    borderColor: customStyle?.primary,
  };
  return (
    <button
      type="submit"
      className={`button ${inheritedClassname || ''} ${submitButtonTheme || ''} submit-button`}
      disabled={isSubmitting}
      style={customButtonColor}
    >
      {isSubmitting ? <Loading light size="small" /> : submitButtonText}
    </button>
  );
};
