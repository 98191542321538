import React, { useState, useEffect, useContext } from 'react';
import { Modal, FormikForm } from 'components';
import { navigate } from 'gatsby';
import { CheckBoxSingleInput, SelectInput, TempPhoneInput, TextInput } from './FormikInputs';
import * as styles from './IpoNoticeModal.module.scss';
import { DealContext } from '../context/DealContext';

export const IpoNoticeModal = ({ modalOpen, setModalOpen, setRedirect, investUrl }) => {
  const { prismicDealData } = useContext(DealContext);
  // const [modalOpen, setModalOpen] = useState(false);

  // useEffect(() => {
  // if (prismicDealData.prismicId === 'Zw7uIhEAACEAanME') {
  //   setModalOpen(true);
  // }
  // }, []);

  const validationFunction = values => {
    const { agreeWithTerms } = values;
    if (!agreeWithTerms) return { email: 'Please agree with the terms' };
  };

  return (
    <Modal active={modalOpen} closeModal={modalOpen} className={styles.ipoModal} ipoModal>
      <div className={styles.modalContainer}>
        <div className={styles.modalText}>
          <p>You have read the Target Market Determination and agree that you are not an investor who:</p>
          <ul>
            <li>does not have a high risk appetite or require a short-term investment;</li>
            <li>
              seeks capital preservation or are reliant on the distributions from the investment (noting that investors
              may not be paid dividends and may not be able to recover their principal if the Company is unable to meet
              its liabilities or generate profits);
            </li>
            <li>requires income on the invested amount;</li>
            <li>does not have the capacity to lose some or all of the investment;</li>
            <li>
              commits a large allocation of their total investable assets to Candy Club (i.e., greater than 10% of the
              investor’s total investable assets excluding the investor’s principal residence); or
            </li>
            <li>uses debt finance to subscribe for the Product.</li>
          </ul>

          {/* Add the link here */}
          <p>
            <a
              href="https://v3-data-equitise-public.s3.ap-southeast-2.amazonaws.com/Scalare+TMD+vF+20240923.pdf"
              target="_blank"
              rel="noopener noreferrer"
            >
              Click on this link to view the documents
            </a>
          </p>
        </div>

        <FormikForm
          initialValues={{
            agreeWithTerms: false,
          }}
          validationFunction={validationFunction}
          onSubmit={() => navigate(investUrl)}
          submitButtonTheme="highlight"
          submitButtonText="accept"
        >
          <CheckBoxSingleInput name="agreeWithTerms">
            I agree to check the checkbox and agree with the terms
          </CheckBoxSingleInput>
        </FormikForm>
      </div>
    </Modal>
  );
};
