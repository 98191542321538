import React, { useState, useEffect, useCallback } from 'react';
import { useEmblaCarousel } from 'embla-carousel/react';
import { SliceHeader, Tabs } from 'components/Page';
import { Button } from 'components';
import { DotButton, PrevButton, NextButton } from './EmblaCarouselButtons';
import { useRecursiveTimeout } from './useRecursiveTimeout';
import * as styles from './embla.module.scss';

const AUTOPLAY_INTERVAL = 4000;

export const EmblaCarousel = ({
  slides,
  className,
  inheritedClassname,
  buttonWrapperClassname,
  queryData,
  hasTabs,
  isHoveredOverQuote,
}) => {
  const options = {
    startIndex: 0,
    containScroll: 'trimSnaps',
    skipSnaps: true,
    loop: true,
    slidesToScroll: 1,
    align: 'start',
  };
  const [viewportRef, embla, emblaRef] = useEmblaCarousel(options);
  const [prevBtnEnabled, setPrevBtnEnabled] = useState(false);
  const [nextBtnEnabled, setNextBtnEnabled] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [scrollSnaps, setScrollSnaps] = useState([]);

  // Enables autoplay on carousel
  useEffect(() => {
    play();
  }, [play]);

  const autoplay = useCallback(() => {
    if (!embla) return;
    if (embla.canScrollNext()) {
      embla.scrollNext();
    } else {
      embla.scrollTo(0);
    }
  }, [embla]);

  const { play, stop } = useRecursiveTimeout(autoplay, AUTOPLAY_INTERVAL);

  // stops scrolling if mouse is hovered over quote text on testimonial card
  useEffect(() => {
    if (isHoveredOverQuote) {
      stop();
    }
  }, [isHoveredOverQuote]);

  const scrollPrev = useCallback(() => {
    if (!embla) return;
    embla.scrollPrev();
    stop();
  }, [embla, stop]);
  const scrollNext = useCallback(() => {
    if (!embla) return;
    embla.scrollNext();
    stop();
  }, [embla, stop]);
  const scrollTo = useCallback(index => embla.scrollTo(index), [embla, stop]);

  // Enable arrows and highlighted dots
  const onSelect = useCallback(() => {
    if (!embla) return;
    setSelectedIndex(embla.selectedScrollSnap());
    setPrevBtnEnabled(embla.canScrollPrev());
    setNextBtnEnabled(embla.canScrollNext());
  }, [embla, setSelectedIndex]);

  // Stop carousel when user interacts with it
  useEffect(() => {
    if (!embla) return;
    onSelect();
    setScrollSnaps(embla.scrollSnapList());
    embla.on('select', onSelect);
    embla.on('pointerDown', stop);
  }, [embla, setScrollSnaps, onSelect]);

  useEffect(() => {
    if (embla && embla.slideNodes().length !== slides.length) {
      embla.reInit(options);
      setScrollSnaps(embla.scrollSnapList());
    }
  }, [embla, slides]);

  const isOfferRoom = className === 'offer-room-slides';
  const isOfferRoomClassname = className === 'offer-room-slides' ? styles.offerRoom : '';

  // removes arrows and buttons from carousels when there is no ability to scroll
  const showButtons = scrollSnaps?.length > 1;

  const oneSlideInView = embla?.slidesInView(1).length < 2;

  const buttonClassnames = {
    embla__button: styles.embla__button,
    embla__dot: styles.embla__dot,
    isSelected: styles.isSelected,
    embla__buttonPrev: styles.embla__buttonPrev,
    embla__buttonNext: styles.embla__buttonNext,
  };

  return (
    <>
      <div className={`${inheritedClassname || ''} ${isOfferRoomClassname} ${styles.embla} embla`} ref={emblaRef}>
        {!isOfferRoom && (
          <div
            className={
              hasTabs ? `${buttonWrapperClassname || ''} buttons-wrapper-${className}` : styles.buttonsWrapperNoTabs
            }
          >
            {showButtons && (
              <>
                <PrevButton onClick={scrollPrev} inheritedClassnames={buttonClassnames} enabled={prevBtnEnabled} />
                <NextButton onClick={scrollNext} inheritedClassnames={buttonClassnames} enabled={nextBtnEnabled} />
              </>
            )}
          </div>
        )}
        <div className={styles.embla__viewport} ref={viewportRef}>
          <div className={styles.embla__container}>
            {slides.map(slide => (
              <div className={styles.embla__slide} key={slide.key}>
                {slide}
              </div>
            ))}
          </div>
        </div>
        {showButtons && (
          <div className={styles.emblaNavigation}>
            {(isOfferRoom || oneSlideInView) && (
              <PrevButton inheritedClassnames={buttonClassnames} onClick={scrollPrev} enabled={prevBtnEnabled} />
            )}
            <div className={styles.embla__dots}>
              {scrollSnaps?.map((_, index) => (
                <DotButton
                  inheritedClassnames={buttonClassnames}
                  key={_}
                  selected={index === selectedIndex}
                  onClick={() => {
                    scrollTo(index);
                    stop();
                  }}
                />
              ))}
            </div>
            {(isOfferRoom || oneSlideInView) && (
              <NextButton inheritedClassnames={buttonClassnames} onClick={scrollNext} enabled={nextBtnEnabled} />
            )}
          </div>
        )}
      </div>
    </>
  );
};
