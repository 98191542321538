import React from 'react';

const CHEVRON_DOWN = 'chevron_down';
const CHEVRON_UP = 'chevron_up';
const CHEVRON_RIGHT = 'chevron_right';
const CHEVRON_LEFT = 'chevron_left';
const ILLUSTRATION = 'illustration';

export const Icon = ({ type }) => {
  switch (type) {
    case CHEVRON_DOWN:
      return (
        <svg viewBox="0 0 48 28" className="icon-svg" fill="#484e59">
          <path
            d="M41.5251263,26.4748737 C42.8919613,27.8417088 45.1080387,27.8417088 46.4748737,26.4748737 C47.8417088,25.1080387 47.8417088,22.8919613 46.4748737,21.5251263 L26.4748737,1.5251263 C25.1080387,0.1582912 22.8919613,0.1582912 21.5251263,1.5251263 L1.5251263,21.5251263 C0.1582912,22.8919613 0.1582912,25.1080387 1.5251263,26.4748737 C2.8919613,27.8417088 5.1080387,27.8417088 6.4748737,26.4748737 L24.0095722,8.9401752 L41.5251263,26.4748737 Z"
            id="Path"
            transform="translate(24.000000, 14.000000) rotate(180.000000) translate(-24.000000, -14.000000) "
          />
        </svg>
      );
    case CHEVRON_UP:
      return (
        <svg viewBox="0 0 48 28" className="icon-svg" fill="#484e59">
          <path
            d="M41.5251263,26.4748737 C42.8919613,27.8417088 45.1080387,27.8417088 46.4748737,26.4748737 C47.8417088,25.1080387 47.8417088,22.8919613 46.4748737,21.5251263 L26.4748737,1.5251263 C25.1080387,0.1582912 22.8919613,0.1582912 21.5251263,1.5251263 L1.5251263,21.5251263 C0.1582912,22.8919613 0.1582912,25.1080387 1.5251263,26.4748737 C2.8919613,27.8417088 5.1080387,27.8417088 6.4748737,26.4748737 L24.0095722,8.9401752 L41.5251263,26.4748737 Z"
            id="Path"
            transform="translate(24.000000, 14.000000) scale(1, -1) rotate(180.000000) translate(-24.000000, -14.000000)"
          />
        </svg>
      );
    case CHEVRON_RIGHT:
      return (
        <svg viewBox="0 0 48 28" className="icon-svg" fill="#484e59">
          <path
            d="M31.5251263,36.4748737 C32.8919613,37.8417088 35.1080387,37.8417088 36.4748737,36.4748737 C37.8417088,35.1080387 37.8417088,32.8919613 36.4748737,31.5251263 L16.4748737,11.5251263 C15.1080387,10.1582912 12.8919613,10.1582912 11.5251263,11.5251263 L-8.4748737,31.5251263 C-9.8417088,32.8919613 -9.8417088,35.1080387 -8.4748737,36.4748737 C-7.1080387,37.8417088 -4.8919613,37.8417088 -3.5251263,36.4748737 L14.0095722,18.9401752 L31.5251263,36.4748737 Z"
            id="Path"
            transform="translate(14.000000, 24.000000) scale(1, -1) rotate(90.000000) translate(-14.000000, -24.000000)"
          />
        </svg>
      );
    case CHEVRON_LEFT:
      return (
        <svg viewBox="0 0 48 28" className="icon-svg" fill="#484e59">
          <path
            d="M31.5251263,36.4748737 C32.8919613,37.8417088 35.1080387,37.8417088 36.4748737,36.4748737 C37.8417088,35.1080387 37.8417088,32.8919613 36.4748737,31.5251263 L16.4748737,11.5251263 C15.1080387,10.1582912 12.8919613,10.1582912 11.5251263,11.5251263 L-8.4748737,31.5251263 C-9.8417088,32.8919613 -9.8417088,35.1080387 -8.4748737,36.4748737 C-7.1080387,37.8417088 -4.8919613,37.8417088 -3.5251263,36.4748737 L14.0095722,18.9401752 L31.5251263,36.4748737 Z"
            id="Path"
            transform="translate(14.000000, 24.000000) rotate(270.000000) translate(-14.000000, -24.000000)"
          />
        </svg>
      );
    case ILLUSTRATION:
      return (
        <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect width="48" height="48" rx="24" fill="#FFFDCA" />
          <path
            d="M33.707 17.793L27.207 11.293C27.019 11.105 26.765 11 26.5 11H16C14.895 11 14 11.895 14 13V35C14 36.105 14.895 37 16 37H32C33.105 37 34 36.105 34 35V18.5C34 18.235 33.895 17.981 33.707 17.793ZM27 30H19C18.448 30 18 29.552 18 29C18 28.448 18.448 28 19 28H27C27.552 28 28 28.448 28 29C28 29.552 27.552 30 27 30ZM29 26H19C18.448 26 18 25.552 18 25C18 24.448 18.448 24 19 24H29C29.552 24 30 24.448 30 25C30 25.552 29.552 26 29 26ZM27 19C26.448 19 26 18.552 26 18V12.904L32.096 19H27Z"
            fill="#D8D35D"
          />
        </svg>
      );
    default:
      return null;
  }
};
