import React, { useContext } from 'react';
import shortid from 'shortid';
import { TrackEventContext, DealContext } from '../../context';

import { SocialLink } from '../../components/SocialLink';
import * as styles from './styles.module.scss';

export const FollowSocials = props => {
  const { prismicDealData } = useContext(DealContext) || {};
  const { companyName } = prismicDealData || {};
  const { intercomId } = useContext(TrackEventContext) || {};
  const {
    data: { items },
    storyCompanyName,
  } = props;
  const pageArea = 'Follow Slice';

  return (
    <div className={styles.followSocialsWrapper}>
      <div>
        <h3>Follow {storyCompanyName || companyName?.text}</h3>
      </div>
      <div className={styles.shareLinks}>
        {items &&
          items.map(link => {
            const {
              link: { url },
              platform: type,
            } = link;
            return (
              <SocialLink
                url={url}
                type={type}
                key={shortid.generate()}
                userId={intercomId}
                event={companyName?.text}
                area={pageArea}
              />
            );
          })}
      </div>
    </div>
  );
};
