// extracted by mini-css-extract-plugin
export var brandShape = "styles-module--brand-shape--PzonX";
export var button = "styles-module--button--nGPE7";
export var ctaSection = "styles-module--cta-section--yjKLA";
export var ctaSectionActions = "styles-module--cta-section-actions--rBv21";
export var ctaSectionContent = "styles-module--cta-section-content--UVoTk";
export var ctaSectionHeader = "styles-module--cta-section-header--WXjmN";
export var ctaSectionHeaderTitle = "styles-module--cta-section-header-title--HocBH";
export var ctaSectionNewsletter = "styles-module--cta-section-newsletter--Dc+C9";
export var gatsbyImage = "styles-module--gatsby-image--22r6d";
export var isLandingPage = "styles-module--isLandingPage--WyQti";
export var landingPagetitletagline = "styles-module--landingPagetitletagline--9NoR7";
export var primary = "#06004d";
export var secondary = "#4dafd7";
export var subscribed = "styles-module--subscribed--gNbZh";
export var tagline = "styles-module--tagline--Ux9ls";
export var visible = "styles-module--visible--OTVxO";
export var wrapper = "styles-module--wrapper--XS79z";