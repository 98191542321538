import React from 'react';
import { useQuery } from '@apollo/client';
import dayjs from 'dayjs';
import { SliceHeader } from './Page/SliceHeader';
import { Tile } from './Tile';
import Section from './Section';
import Button from './Button';
import * as styles from './TileGrid.module.scss';
import { GET_ALL_DEALS } from './TileGridQueries';
import { getCookie } from '../apis';

export const TileGrid = props => {
  const {
    title,
    tagline,
    cta,
    tiles,
    warningStatements,
    ctaText,
    backLink,
    setView,
    inheritedClassnames,
    isLandingPage,
    isAllBlogPosts,
  } = props;

  // Get all cookies
  const allCookies = getCookie();

  // Retrieve cookie values from visited deal room
  const visitedOfferCookieValues = Object.keys(allCookies || {})
    .filter(key => key.includes('eq_deal_offer'))
    .map(key => allCookies[key]);

  // If cookie values retrieved, parse JSON value to grab visited deal tile object
  // Then check if tile for deal exists
  // If tile exists, remove and replace with visited deal tile object
  // else if no duplicate tile found, push visited deal tile to front of tiles arrray
  if (visitedOfferCookieValues && tagline?.text === 'CURRENT OFFERS') {
    visitedOfferCookieValues?.forEach(visitedOffer => {
      const parsedCookie = JSON.parse(visitedOffer);
      const duplicateTiles = tiles.filter(
        tile =>
          tile?.tile?.document?.data?.company_name?.text === parsedCookie?.tile?.document?.data?.company_name?.text
      );
      if (duplicateTiles?.length > 0) {
        duplicateTiles.forEach(tile => {
          const indexToRemove = tiles.indexOf(tile);
          if (indexToRemove !== -1) {
            tiles.splice(indexToRemove, 1);
          }
        });
        tiles.unshift(parsedCookie);
      } else {
        tiles.unshift(parsedCookie);
      }
    });
  }

  const hasTiles = tiles?.length > 0;

  const validClassnames = inheritedClassnames || {};

  return (
    <Section
      className={`${styles.dealGridContainer} ${validClassnames.dealGridContainer}`}
      noContainer
      sliceName="Tiles"
    >
      {hasTiles && (
        <>
          <SliceHeader title={title} tagline={tagline} titleName={validClassnames.pageSliceTitle} />
          {cta?.url && <Button to={cta?.url}>{ctaText?.text}</Button>}
          {backLink && (
            <p className={styles.backToResultsButton} onClick={() => setView('all')}>
              Back to search results
            </p>
          )}
          <div className={`${styles.dealGrid} ${validClassnames.dealGrid}`}>
            {tiles?.map(tile => (
              <Tile
                {...tile}
                isLandingPage={isLandingPage}
                isAllBlogPosts={isAllBlogPosts}
                warningStatements={warningStatements}
                dealTileLocation="Grid"
                key={Math.random()}
              />
            ))}
          </div>
        </>
      )}
    </Section>
  );
};
