import React, { useEffect, useState } from 'react';
import { Hamburger } from 'components';
import { SearchComponent } from './SearchComponent/SearchComponent';
import * as styles from './HamburgerNav.module.scss';

export const HamburgerNav = ({ className, children, isSyndicateDeal }) => {
  const [showNav, setShowNav] = useState(false);
  const [scrollTop, setScrollTop] = useState(0);
  const [bodyLocked, setBodyLock] = useState(false);

  const toggleNav = event => {
    event.preventDefault();
    setShowNav(!showNav);
  };

  const handleEscKey = event => {
    if (event.keyCode === 27) {
      document.body.classList.remove(styles.hamburgerNavOpen);
      setShowNav(false);
    }
  };

  const lockBody = () => {
    const scrollPosition = window.pageYOffset || document.documentElement.scrollTop;
    setScrollTop(scrollPosition);
    document.body.style.top = `-${scrollPosition}px`;
    document.body.classList.add(styles.hamburgerNavOpen);
    if (scrollPosition > 0) document.body.classList.add(styles.scrolledBeforeLock);
    setBodyLock(true);
  };

  const unlockBody = () => {
    document.body.classList.remove(styles.hamburgerNavOpen);
    document.body.classList.remove(styles.scrolledBeforeLock);
    document.body.style.top = '0px';
    window.scrollTo(0, scrollTop);
    setScrollTop(0);
    setBodyLock(false);
  };

  useEffect(() => {
    document.addEventListener('keydown', handleEscKey, false);
    return () => {
      document.removeEventListener('keydown', handleEscKey, false);
      unlockBody();
    };
  }, []);

  useEffect(() => {
    if (showNav && !bodyLocked) lockBody();
    if (!showNav && bodyLocked) unlockBody();
    return () => {
      unlockBody();
    };
  }, [showNav]);

  return (
    <div className={`${styles.hamburgerNav} ${className || ''}`}>
      {children}
      <div className={`${styles.hamburgerIcons} ${isSyndicateDeal && styles.syndicateHamburgerIcons}`}>
        <SearchComponent
          isOnNavbar
          isSyndicateDeal={isSyndicateDeal}
          className={`${styles.mobileSearchBar} mobile-search-bar`}
        />
        <span className={`${isSyndicateDeal ? styles.syndicateHamburgerBg : styles.hamburgerBg}`} />
        <Hamburger
          inheritedClassname={`${styles.hamburger} hamburger`}
          active={showNav}
          onClick={toggleNav}
          theme="white"
        />
      </div>
    </div>
  );
};
