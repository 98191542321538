import React from 'react';
import shortid from 'shortid';
import { useCsvToJsonData } from 'components/CustomHooks';
import * as styles from './UserTable.module.scss';

export const UserTable = ({ url: tableUrl, footnotes, content }) => {
  // If having a CORS error, check local.js auth headers, bearer token being added
  const tableData = useCsvToJsonData(tableUrl);

  const mapRow = (row, header) => {
    // rowLabel is only used to inform user how to enter information into the csv template
    const [rowLabel, ...vals] = row;

    // remove all the user specified formatting prefixing each cell
    const cleanPrefix = val => val.replace(/h:|nbb:|2r:|~/, '');

    const mapTh = hdr => <th key={hdr}>{cleanPrefix(hdr)}</th>;

    const mapTd = val =>
      // check if Prismic users wants the cell to be merged with the cell to the left ('~' prefix)
      !(val === '~') && (
        <td
          key={`${val}${shortid.generate()}`}
          // check if the Prismic user wants to merge the cell with cell below ('2r' prefix)
          rowSpan={/2r:/.test(val) ? 2 : 1}
          // check if the Prismic user wants to remove the bottom border from the cell ('nbb' prefix)
          className={/nbb:/.test(val) ? 'no-bottom-border' : ''}
        >
          {cleanPrefix(val)}
        </td>
      );

    return (
      vals[0] && (
        // check if the Prismic user wants to highlight the row ('h:' prefix)
        <tr key={rowLabel} className={/h:/.test(vals[0]) ? styles.highlight : undefined}>
          {header ? vals.map(mapTh) : vals.map(mapTd)}
        </tr>
      )
    );
  };

  // eslint-disable-next-line no-unused-vars
  const [columnLabels, headers, ...rows] = tableData || [];
  if (!headers) return null;

  // Check the number of non empty headers to remove empty values at the end of each row
  const rowLength = headers.filter(Boolean).length;
  return (
    <div className={styles.offerRoomUserTable}>
      <table className={footnotes.html ? styles.hasFootnotes : ''}>
        <tbody>
          {mapRow(headers.slice(0, rowLength), true)}
          {rows.map(row => mapRow(row.slice(0, rowLength)))}
        </tbody>
      </table>
      {footnotes && <div dangerouslySetInnerHTML={{ __html: footnotes.html }} className={styles.footnotes} />}
      {content && <div dangerouslySetInnerHTML={{ __html: content }} />}
    </div>
  );
};
