// extracted by mini-css-extract-plugin
export var dark = "styles-module--dark--fmca6";
export var leftContainer = "styles-module--leftContainer--2U31V";
export var light = "styles-module--light--6d-9k";
export var logo = "styles-module--logo--TU1hS";
export var logosContainer = "styles-module--logosContainer--0XpSl";
export var metricCard = "styles-module--metricCard--kMKjX";
export var metricLogo = "styles-module--metricLogo--sI04v";
export var metricOnlyCards = "styles-module--metricOnlyCards--NJr7Z";
export var metricOnlyCardsFourGrid = "styles-module--metricOnlyCardsFourGrid--JOGuu";
export var metricsAndLogosContainer = "styles-module--metricsAndLogosContainer--gdisn";
export var metricsContainer = "styles-module--metricsContainer--ZXAcA";
export var metricsOnlyContainer = "styles-module--metricsOnlyContainer--rVk4U";
export var metricsOnlySection = "styles-module--metricsOnlySection--GvPX3";
export var metricsTagLine = "styles-module--metricsTagLine--90SW6";
export var metricsTitle = "styles-module--metricsTitle--2w3eY";
export var metricsWrapper = "styles-module--metricsWrapper--203E-";
export var primary = "#06004d";
export var rightContainer = "styles-module--rightContainer--S1cpT";
export var secondary = "#4dafd7";
export var titleContainer = "styles-module--titleContainer--vIgG4";