import { gql } from '@apollo/client';

export const GET_OWN_USER = gql`
  query ownUser {
    ownUser {
      id
      email
      firstName
      lastName
      identityVerifiedAt
      address {
        id
        state
        country {
          name
        }
      }
      profile {
        userId
        sophisticatedStatus {
          id
          name
        }
        telephone
      }
      roles {
        id
      }
      intercomContactId
    }
  }
`;
