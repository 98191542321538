import React from 'react';
import { Image, Section } from 'components';
import shortid from 'shortid';
import * as styles from './styles.module.scss';

export const ImageField = props => {
  const { data } = props;
  const { primary } = data;
  const { image } = primary;

  const imageProps = {
    image,
    alt: image?.alt,
    key: shortid.generate(),
  };

  return (
    <Section noContainer sliceName="ImageField" spacing="s">
      <div className={styles.imageSlice} key={shortid.generate()}>
        <Image {...imageProps} />
      </div>
    </Section>
  );
};
