import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import { EmblaCarousel, Button, Section } from 'components';
import { SliceHeader } from 'components/Page';
import { PostCard } from 'components/BlogLandingPage';
import * as styles from './styles.module.scss';

const blogFeedQuery = graphql`
  query {
    ...BlogPostsFragment
    ...BlogPathFragment
  }
`;

const inheritedClassnames = {
  postCard: styles.postCard,
  postCardImageWrapper: styles.postCardImageWrapper,
  postCardInfo: styles.postCardInfo,
  postCardTitle: styles.postCardTitle,
  postCardContent: styles.postCardContent,
};

export const BlogFeed = ({
  data: {
    primary: { tagline, title },
  },
}) => (
  <StaticQuery
    query={blogFeedQuery}
    render={queryData => {
      const { posts: { edges: posts } = {}, blogPathName: { uid: blogPathName } = {} } = queryData;

      const slides = posts.map(edge => (
        <PostCard
          inheritedClassnames={inheritedClassnames}
          post={edge}
          pathname={blogPathName}
          contentCharLimit={80}
          key={edge.node.uid}
          tileLocation="BlogFeed Slice"
        />
      ));

      return (
        <Section noContainer className={styles.blogfeed} sliceName="BlogFeed">
          <div className={styles.blogCarouselHeader}>
            <SliceHeader inheritedClassname={styles.pageSliceHeader} title={title} tagline={tagline} />
          </div>
          <EmblaCarousel className="blog-feed-carousel" slides={slides} />
          <div className={styles.blogCta}>
            <Button theme="primary" to={`/${blogPathName}`}>
              View All
            </Button>
          </div>
        </Section>
      );
    }}
  />
);
