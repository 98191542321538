import React, { useContext } from 'react';
import OnVisible from 'react-on-visible';
import { Image, SocialLink, Section, RegisterInterestForm, TeamMembers, Link, Video } from 'components';
import * as styles from './styles.module.scss';
import { LandingPageContext } from '../../context';
import { trackAmplitudeEvent } from '../../utils';

export const CtaVideoAndRegisterInterestForm = ({
  data: {
    primary: { featured_video: featuredVideo, logo, form_logo: formLogo, link_to_offer: linkToOffer },
  },
  template,
}) => {
  const { prismicId, uid, companyName, primaryColour, secondaryColour, isEoi } = useContext(LandingPageContext) || {};
  const showLogo = logo?.localFile || logo?.url;
  const showFeaturedVideo = featuredVideo?.url;
  const customBackgroundColor = {
    backgroundColor: `${secondaryColour}`,
  };
  const customFormHeaderColor = {
    backgroundColor: `${primaryColour}`,
  };

  return (
    <OnVisible percent={20} visibleClassName={styles.visible}>
      <Section className={styles.landingCtaVideoFormWrapper} noContainer sliceName="Team" style={customBackgroundColor}>
        <div className={styles.landingCtaVideoFormContainer}>
          <div className={styles.landingCtaVideoFormBox}></div>
          <div className={styles.landingCtaVideoFormLogoContainer}>
            {showLogo && <Image image={logo} className={styles.landingCtaVideoFormLogo} objectFit="contain" />}
            {showFeaturedVideo && <Video src={featuredVideo.url} />}
          </div>
          <div className={styles.landingCtaVideoFormFormContainer}>
            <RegisterInterestForm
              companyName={companyName?.text}
              logo={formLogo || logo}
              prismicId={prismicId}
              uid={uid}
              style={customFormHeaderColor}
              linkToOffer={linkToOffer}
              isEoi={isEoi}
            />
          </div>
        </div>
      </Section>
    </OnVisible>
  );
};
