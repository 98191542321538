import React from 'react';
import OnVisible from 'react-on-visible';
import { Image, SocialLink, Section } from 'components';
import { SectionHeading } from 'components/DealRoom';
import * as styles from './styles.module.scss';

export const Team = ({
  data: {
    primary: {
      heading,
      content: { html: teamOverview },
    },
    items: teamMembers,
  },
  template,
}) => (
  <OnVisible
    className={`offer-room-content-block ${styles[template] || ''} ${styles.teamBlock}`}
    id="team"
    percent={20}
    visibleClassName={styles.visible}
  >
    <Section noContainer spacing='n' sliceName="Team">
      {heading && <SectionHeading heading={heading} />}
      <div dangerouslySetInnerHTML={{ __html: teamOverview }} />
      <div className={styles.offerRoomTeamMembers}>
        {teamMembers.map(teamMember => {
          const { avatar, founder: featured, linkedin_url: linkedInUrl, position, name, bio } = teamMember;
          return (
            <div
              className={`${styles.offerRoomTeamMember} ${featured === 'yes' ? 'founder' : 'basic'}`}
              key={name && name.text}
            >
              <div className={styles.offerRoomTeamMemberContent}>
                <div className={styles.offerRoomAvatar}>
                  <Image image={avatar} alt={avatar.alt || 'Team Member'} />
                </div>
                <div className={styles.offerRoomTeamMemberDetails}>
                  <div className={styles.offerRoomTeamMemberName}>
                    <h4>{name && name.text}</h4>
                    {linkedInUrl?.url?.length > 0 && (
                      <SocialLink type="LinkedIn" theme="primary" url={linkedInUrl.url} />
                    )}
                  </div>
                  <span>{position && position.text}</span>
                </div>
              </div>
              {featured === 'yes' && bio && (
                <div className={styles.offerRoomTeamMemberBio} dangerouslySetInnerHTML={{ __html: bio.html }} />
              )}
            </div>
          );
        })}
      </div>
    </Section>
  </OnVisible>
);
