// extracted by mini-css-extract-plugin
export var answerFormFooter = "QuestionAnswer-module--answer-form-footer--3riK+";
export var answerType = "QuestionAnswer-module--answer-type--RJvAu";
export var button = "QuestionAnswer-module--button--OYLWT";
export var editAnswerLink = "QuestionAnswer-module--edit-answer-link--SvPi2";
export var error = "QuestionAnswer-module--error--HUBj3";
export var offerRoomContentBlock = "QuestionAnswer-module--offer-room-content-block--WHQIM";
export var primary = "#06004d";
export var qaBlock = "QuestionAnswer-module--qa-block--Mn+4e";
export var qaBody = "QuestionAnswer-module--qa-body--cw5MW";
export var qaCard = "QuestionAnswer-module--qa-card--x3Wz5";
export var qaCtaCopy = "QuestionAnswer-module--qa-cta-copy--hJbkK";
export var qaHeader = "QuestionAnswer-module--qa-header--bunvk";
export var qaPair = "QuestionAnswer-module--qa-pair--Xky7Y";
export var qaTimerContainer = "QuestionAnswer-module--qa-timer-container--8zKwc";
export var qaTitle = "QuestionAnswer-module--qa-title--J9bbK";
export var qaTitleCreatorEmail = "QuestionAnswer-module--qa-title-creator-email--lNzDD";
export var qaTitleCreatorRole = "QuestionAnswer-module--qa-title-creator-role--U+3D5";
export var secondary = "#4dafd7";