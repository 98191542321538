import React from 'react';
import { Link } from './Link';

export const Button = props => {
  // const { to, title, children, theme = 'primary', outline, onClick = () => {}, className, buttonClassname } = props;
  const { to, title, children, theme = 'primary', outline, onClick = () => {}, className, inheritedClassname } = props;

  // const inheritedClassname = className || '';

  const linkProps = {
    to: to || '/',
    title: title || '',
    className: `button ${theme} ${outline ? 'outline' : ''} ${inheritedClassname || ''}`,
    onClick,
  };
  return <Link {...linkProps}>{children}</Link>;
};
