import { Link } from 'gatsby';
import React from 'react';
import { siteName } from '../../config/website';
import { convertToCamelCase } from '../utils';
import * as styles from './Logo.module.scss';
import { Image } from './Image';

// Logo type options
// icon-primary
// secondary-dark, secondary-light, icon-secondary
// teritary-dark, tertiary-light, icon-tertiary
// icon-off-white
// icon-white

export const Logo = ({ type = 'secondary-dark', useSpan, inheritedClassname, customLogo }) => {
  const convertedTypeName = convertToCamelCase(type);

  if (customLogo) {
    return <Image image={customLogo} alt={customLogo?.alt} />;
  }

  if (useSpan)
    return (
      <span className={`${styles.logo} ${styles[convertedTypeName]} ${inheritedClassname || ''}`}>{siteName}</span>
    );

  return (
    <Link className={`${styles.logo} ${styles[convertedTypeName]} ${inheritedClassname || ''}`} to="/">
      {siteName}
    </Link>
  );
};
