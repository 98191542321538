import React from 'react';
import { CategoryCard } from './CategoryCard';
import * as styles from './CategorySpecificSection.module.scss';
import Section from '../Section';

export const CategorySpecificSection = ({ pathname, categoryPosts, inheritedClassnames }) => {
  const validClassnames = inheritedClassnames || {};
  return (
    <Section
      className="category-specific-posts"
      containerClassName={`blog wrapper ${validClassnames.blog} ${validClassnames.wrapper}`}
      spacing="n"
    >
      <div className={styles.categoryCards}>
        {categoryPosts?.map(({ fieldValue: categoryUid, edges: posts }) => (
          <CategoryCard posts={posts} name={categoryUid} pathname={pathname} key={categoryUid} />
        ))}
      </div>
    </Section>
  );
};
