import fetch from 'isomorphic-fetch';
import { AUTH_TOKEN } from '../utils/constants/cookieNames';
import { setCookie } from '../apis/cookie';
import { refreshJwtToken } from '../apis/local';

const checkIfExpiredTokenError = error => error?.extensions?.code === 'EXPIRED_TOKEN';

// This reference to the refreshingPromise will let us check later on if we are executing getting the refresh token.
let isRefreshingPromise = false;

// Create customFetch function for handling re-authorization
// This customFetch (or any fetch you pass to the link) gets uri and options as arguments. We'll use those when we actually execute a fetch.
const customFetch = async (uri, options) => {
  // Create initial fetch, this is what would normally be executed in the link without the override
  const initialRequest = await fetch(uri, options);
  const reqClone = initialRequest.clone();
  const initialReqJson = await reqClone.json();

  // Check if the initial request had expired token error
  if (initialReqJson?.errors?.some(checkIfExpiredTokenError) && !isRefreshingPromise) {
    isRefreshingPromise = true;
    // Fetch new token from auth server
    const newToken = await refreshJwtToken();
    isRefreshingPromise = false;
    // Set new auth token to users browser
    setCookie({ token: newToken, cookieName: AUTH_TOKEN });
    options.headers.authorization = `Bearer ${newToken}`;

    // Retry original request
    return fetch(uri, options);
  }

  return initialRequest;
};

export default customFetch;
