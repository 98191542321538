import React from 'react';
import OnVisible from 'react-on-visible';
import { Image, Link, Section } from 'components';
import { SectionHeading } from 'components/DealRoom';
import shortid from 'shortid';
import * as styles from './TextBlock.module.scss';

export const TextBlock = ({
  data: {
    primary: { heading, custom_class: customClass },
    items,
  },
  template,
}) => (
  <OnVisible
    className={`offer-room-content-block ${styles.textBlock} ${styles[template]} ${styles.customClass?.text || ''}`}
    visibleClassName={styles.visible}
    id="offer"
    percent={20}
  >
    <Section noContainer sliceName="TextBlock" spacing="n">
      {heading && <SectionHeading heading={heading} />}
      {items.map(item => {
        const { image, content, linked_image: hasLinkedImage } = item;
        const showImage = image && (image.localFile || image.url);
        const imageProps = {
          image,
          alt: image?.alt,
          key: shortid.generate(),
          inheritedClassname: styles.textBlockImage,
        };
        const offerRoomContentClassname = `offerRoomContent${customClass?.text}`;
        return (
          <div key={shortid.generate()}>
            {hasLinkedImage && showImage && (
              <Link to={image?.url} target="_blank" className={styles.linkedImage}>
                <Image {...imageProps} />
              </Link>
            )}
            {!hasLinkedImage && showImage && <Image {...imageProps} />}
            {content && (
              <div
                dangerouslySetInnerHTML={{ __html: content.html }}
                key={content.html}
                className={styles[offerRoomContentClassname || '']}
              />
            )}
          </div>
        );
      })}
    </Section>
  </OnVisible>
);
