import React from 'react';
import OnVisible from 'react-on-visible';
import shortid from 'shortid';
import { Section } from 'components';
import { SectionHeading } from '../../components/DealRoom';
import * as styles from './styles.module.scss';

export const KeyPoints = ({ data: { items, primary }, template }) => {
  if (!items) return null;

  return (
    <OnVisible
      className={`offer-room-content-block ${styles[template]}`}
      percent={20}
      visibleClassName={styles.visible}
    >
      <Section noContainer sliceName="KeyPoints" spacing="n">
        {primary && <SectionHeading heading={primary.heading} />}
        <ol className={`${styles.fancyOl}`}>
          {items.map((keyPoint, index) => {
            const {
              point_title: { text: title },
              point_description: { html: content },
            } = keyPoint;
            return (
              <li data-count={index + 1} key={shortid.generate()}>
                <div className="fancy-ol-content">
                  <strong className="fancy-ol-title">{title}</strong>
                  <div dangerouslySetInnerHTML={{ __html: content }} />
                </div>
              </li>
            );
          })}
        </ol>
      </Section>
    </OnVisible>
  );
};
