import React, { useContext, useEffect, useState } from 'react';
import { Button } from 'components';
import { KeyPoints } from 'components/Page';
import { PageTile } from './PageTile';
import { trackAmplitudeEvent } from '../../../utils';
import { AuthContext, TrackEventContext } from '../../../context';
import { userData } from '../../../utils/userDataToAmplitude';
import './CaseStudyTile.scss';

export const CaseStudyTile = props => {
  const { data, route, cta = 'raise', tileLocation } = props;
  const { companyName, keyPoints, link, tileDescription, tileImage } = data || {};
  const uid = link?.document?.uid;
  const { resource: user } = useContext(AuthContext) || {};
  const { intercomAttributes, intercomEmail } = useContext(TrackEventContext) || {};

  const [currentUrl, setCurrentUrl] = useState('');

  useEffect(() => {
    setCurrentUrl(window.location.pathname);
  }, []);

  const handleClick = (text, company) => {
    console.log(`Clicked Casestudy Tile ${text} Button`);
    try {
      trackAmplitudeEvent({
        email: user?.email || intercomEmail,
        eventName: `Clicked Case Study Button`,
        eventData: {
          button: text,
          company: company?.text,
          previousLink: currentUrl,
          caseStudyTilelocation: tileLocation,
        },
        userData: user?.email ? userData(user) : intercomAttributes || {},
      });
    } catch (error) {
      console.error('Amplitude event Clicked Tile Link failed');
    }
  };

  return (
    <PageTile
      header={companyName?.text}
      link={`/blog/${uid}`}
      shouldLink={Boolean(uid)}
      description={tileDescription?.text}
      tileImage={tileImage}
      tileType="Case Study Tile"
    >
      <KeyPoints keyPoints={keyPoints} />
      <div className="buttons">
        {cta === 'raise' ? (
          <Button
            onClick={() => handleClick('Raise Funds', companyName)}
            to="https://docs.google.com/forms/d/e/1FAIpQLSeEbmurSaLrgSoRq6z4ixPcWiVDwcFzRChrt59x4us8aSlfDw/viewform?usp=sf_link"
          >
            Raise Funds
          </Button>
        ) : null}
        {uid && (
          <Button onClick={() => handleClick('Case Study', companyName)} to={`/${route}/${uid}`}>
            Case Study
          </Button>
        )}
      </div>
    </PageTile>
  );
};
