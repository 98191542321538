// extracted by mini-css-extract-plugin
export var categories = "MostPopularSection-module--categories--y29Sb";
export var popular = "MostPopularSection-module--popular--fKmVx";
export var popularPosts = "MostPopularSection-module--popular-posts--H5Tr-";
export var postCard = "MostPopularSection-module--post-card--SzZVA";
export var postCardContent = "MostPopularSection-module--post-card-content--LHy6k";
export var postCardImage = "MostPopularSection-module--post-card-image--fR17B";
export var postCardImageWrapper = "MostPopularSection-module--post-card-image-wrapper--MiNhV";
export var postCardInfo = "MostPopularSection-module--post-card-info--1TGCx";
export var postCardTitle = "MostPopularSection-module--post-card-title--KQzpK";
export var primary = "#06004d";
export var secondary = "#4dafd7";
export var wrapper = "MostPopularSection-module--wrapper--1NafD";