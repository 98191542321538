import React, { useContext } from 'react';
import { StaticQuery, graphql } from 'gatsby';
import { GeoContext } from '../../context';
import { TileGrid } from '../../components/TileGrid';
import { TileCarousel } from '../../components/TileCarousel';
import * as styles from './styles.module.scss';

const warningQuery = graphql`
  query {
    ...WarningStatementsFragment
  }
`;

export const Tiles = props => {
  const { data } = props;
  const { primary, items: tiles } = data || {};
  const { tagline, title, no_tiles_copy: noTilesCopy, layout } = primary;

  const isCarousel = layout === 'carousel';

  // Get user's country of residence
  const { countryName: countryOfResidence } = useContext(GeoContext) || {};

  // Filter tiles per user's country of residence
  const localisedTiles = tiles.filter(tile => {
    const { type: documentType, data: documentData } = tile?.document || {};
    return documentType !== 'deal' || documentData?.country === countryOfResidence;
  });

  return (
    <StaticQuery
      query={warningQuery}
      render={queryData => {
        const { warning_statements: warningStatements } = queryData?.settings?.data || {};
        return (
          <TileContainer
            isCarousel={isCarousel}
            title={title}
            tagline={tagline}
            tiles={localisedTiles}
            warningStatements={warningStatements}
            noTilesCopy={noTilesCopy}
          />
        );
      }}
    />
  );
};

const TileContainer = ({ isCarousel, ...props }) =>
  isCarousel ? <TileCarousel {...props} /> : <TileGrid {...props} />;
