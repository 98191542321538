import React, { useState, useEffect } from 'react';
import shortid from 'shortid';
import { PostCard } from './PostCard';
import * as styles from './MostPopularSection.module.scss';
import Section from '../Section';

const postCardClassnames = {
  postCard: styles.postCard,
  type: styles.popular,
  postCardImageWrapper: styles.postCardImageWrapper,
  postCardImage: styles.postCardImage,
  postCardTitle: styles.postCardTitle,
  categories: styles.categories,
  postCardInfo: styles.postCardInfo,
  postCardContent: styles.postCardContent,
};

export const MostPopularSection = ({ posts, pathname, inheritedClassnames }) => {
  const validClassnames = inheritedClassnames || {};

  return (
    <Section
      containerClassName={`${styles.wrapper} ${validClassnames.wrapper || ''} wrapper ${validClassnames.blog ||
        ''} blog`}
      spacing="n"
    >
      <div className={styles.popularPosts}>
        <h3 className={`section-title ${validClassnames.sectionTitle || ''}`}>Most Popular</h3>
        {/* TODO: Will need to use GA and the api to calculate the most popular posts */}
        {posts &&
          posts.map(({ popular_post: post }) => (
            <PostCard
              post={post}
              pathname={pathname}
              type="popular"
              key={shortid.generate()}
              contentCharLimit={80}
              inheritedClassnames={postCardClassnames}
              tileLocation="MostPopularSection"
            />
          ))}
      </div>
    </Section>
  );
};
