import React from 'react';
import { Button, Image, Tagline } from 'components';
import { Tooltip } from 'react-tippy';
import * as styles from './TestimonialCard.module.scss';

export const TestimonialCard = props => {
  const { data, setIsHoveredOverQuote } = props || {};
  const {
    full_name: fullName,
    button: { text: buttonText = {} },
    button_link: { url = {} },
    profile_picture: profilePicture,
    quote: { html: quote = {} },
    tagline,
    job_title: jobTitle,
  } = data || {};

  const quoteText = `${quote} ${fullName.text}, ${jobTitle.text}`;

  const handleMouseOver = () => {
    setIsHoveredOverQuote(true);
  };

  return (
    <div className={styles.testimonialCard}>
      <div className={styles.testimonialHeader}>
        <Image image={profilePicture} className={styles.profilePicture} />
        <div className={styles.testimonialHeaderDetails}>
          <span className={styles.testimonialFullName}>{fullName && fullName.text}</span>
          <span className={styles.testimonialJobTitle}>{jobTitle && jobTitle.text}</span>
          <div className={styles.taglineRow}>
            <Tagline inheritedClassname={styles.tagline} tagline={tagline} />
          </div>
        </div>
      </div>
      <Tooltip
        title={quoteText}
        interactive
        position="top"
        arrow
        sticky
        theme="light"
        arrowSize="small"
        popperOptions={{ preventOverflow: true }}
      >
        {quote && (
          <div
            onMouseEnter={handleMouseOver}
            dangerouslySetInnerHTML={{ __html: quote }}
            className={styles.testimonialQuote}
          />
        )}
      </Tooltip>
      {url && buttonText && (
        <Button className={`${styles.button}`} to={url}>
          {buttonText}
        </Button>
      )}
    </div>
  );
};
