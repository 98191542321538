import React from 'react';
import { Video, Section } from 'components';

export const VideoField = props => {
  const { data } = props;
  const { primary } = data;
  const { video_link: videoLink } = primary;

  return (
    <Section noContainer spacing="n" sliceName="VideoField">
      <Video src={videoLink.url} />
    </Section>
  );
};
