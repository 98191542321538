// extracted by mini-css-extract-plugin
export var active = "InvestDropdown-module--active--tKxaA";
export var icon = "InvestDropdown-module--icon--PPGxX";
export var investDropdown = "InvestDropdown-module--invest-dropdown--t0Tqr";
export var investDropdownFooter = "InvestDropdown-module--invest-dropdown-footer--SIz14";
export var investDropdownOptions = "InvestDropdown-module--invest-dropdown-options--8rofy";
export var investDropdownOptionsItem = "InvestDropdown-module--invest-dropdown-options-item--C2alK";
export var investDropdownToggle = "InvestDropdown-module--invest-dropdown-toggle--YB08m";
export var logo = "InvestDropdown-module--logo--WMvQA";
export var primary = "#06004d";
export var secondary = "#4dafd7";