import React, { useContext } from 'react';
import prune from 'voca/prune';
import { Image, Link } from 'components';
import { capitaliseWord, stripWhitespace, truncateText, formatDate } from 'utils';
import { trackAmplitudeEvent } from '../../utils';
import { AuthContext, TrackEventContext } from '../../context';
import { userData } from '../../utils/userDataToAmplitude';
import * as styles from './PostCard.module.scss';

export const PostCard = ({
  post,
  type,
  pathname,
  contentCharLimit = 80,
  headingCharLimit = 40,
  displayDate,
  hidePostInfo,
  hideContent,
  inheritedClassnames,
  tileLocation,
  isLandingPage,
  isAllBlogPosts,
}) => {
  const { resource: user } = useContext(AuthContext) || {};
  const { intercomAttributes, intercomEmail } = useContext(TrackEventContext) || {};
  // document will need to be destructured off a featured post
  const { document: prismicDocument } = post;
  // if this component is attempting to render a featured post and it has not been provided by the Prismic user return null (given the graphql response of object document: null)
  if (prismicDocument === null) return null;
  // otherwise this component is a node from an array of edges (query for multiple blog posts) and will need to be destructured
  const { node } = post;
  const uid = (prismicDocument && prismicDocument.uid) || node.uid;
  const data = (prismicDocument && prismicDocument.data) || node.data;
  const { categories, content: postContent, date_published: datePublished, tile_image: image, title } = data;
  // Strip redundant whitespace between words in the heading and body of post
  const heading = stripWhitespace(title.text);
  const content = stripWhitespace(postContent.text);
  const showPostcardImage = image && (image.localFile || image.url);

  const validClassnames = inheritedClassnames || {};

  const handleClick = () => {
    try {
      trackAmplitudeEvent({
        email: user?.email || intercomEmail,
        eventName: `Clicked Postcard Link`,
        eventData: {
          title: heading,
          postCardLocation: tileLocation,
        },
        userData: user?.email ? userData(user) : intercomAttributes || {},
      });
    } catch (error) {
      console.error('Amplitude event Clicked PostCard Link failed');
    }
  };

  return (
    <div
      className={`${styles.postCard} post-card ${type || ''} ${validClassnames.postCard || ''} ${validClassnames.type ||
        ''} ${isLandingPage && styles.isLandingPageCard}`}
    >
      <Link
        className={`${validClassnames.postCardImageWrapper || ''} ${
          styles.postCardImageWrapper
        } post-card-image-wrapper`}
        to={`/${pathname}/${uid}`}
        onClick={() => handleClick()}
      >
        {showPostcardImage && (
          <Image
            image={image}
            className={`${validClassnames.postCardImage || ''} ${styles.gatsbyImage} ${
              styles.postCardImage
            } ${isLandingPage && styles.isLandingPageImage} post-card-image`}
            onClick={() => handleClick()}
          />
        )}
      </Link>
      {!hidePostInfo && (
        <div className={`${validClassnames.postCardInfo || ''} ${styles.postCardInfo} post-card-info`}>
          <PostCategories categories={categories} postUid={uid} />
        </div>
      )}
      <h4 className={`${validClassnames.postCardTitle || ''} ${styles.postCardTitle} post-card-title`}>
        <Link to={`/${pathname}/${uid}`} onClick={() => handleClick()}>
          {truncateText(heading, headingCharLimit, '...')}
        </Link>
      </h4>
      {content && !hideContent && (
        <p className={`${validClassnames.postCardContent || ''} ${styles.postCardContent} post-card-content`}>
          {truncateText(content, contentCharLimit, '...')}
        </p>
      )}
      {displayDate && (
        <span className={`${validClassnames.date || ''} ${styles.date} date`}>
          {datePublished && formatDate(datePublished)}
        </span>
      )}
      {isLandingPage && !isAllBlogPosts && (
        <div className={styles.buttonWrapper}>
          <Link to={`/blog/${uid}`} className={`${styles.button} button`}>
            View Blog
          </Link>
        </div>
      )}
    </div>
  );
};

const PostCategories = ({ categories, postUid }) => {
  const { resource: user } = useContext(AuthContext) || {};
  const { intercomAttributes, intercomEmail } = useContext(TrackEventContext) || {};
  if (!categories || categories.length < 1) return null;
  const firstAndSecondCategories = categories?.slice(0, 2);

  const handlePostCategoryLinkClick = link => {
    try {
      trackAmplitudeEvent({
        email: user?.email || intercomEmail,
        eventName: `Clicked Postcard Category`,
        eventData: {
          title: link,
        },
        userData: user?.email ? userData(user) : intercomAttributes || {},
      });
    } catch (error) {
      console.error('Amplitude event Clicked Postcard Category failed');
    }
  };

  return (
    <div className={styles.postCardCategories}>
      {firstAndSecondCategories?.map((categoryObj, index) => {
        const uid = categoryObj?.category?.uid;
        const blogPath = `/blog/${uid}`;
        const truncatedUid = prune(uid);
        return (
          <span className={styles.postCardCategory} key={`${postUid}-${uid}`}>
            {index > 0 && categoryObj && <span className={styles.categoryTagDivider}>|</span>}
            <Link
              to={blogPath}
              onClick={() => handlePostCategoryLinkClick(capitaliseWord(truncatedUid)?.replace(/-/g, ' '))}
            >
              {capitaliseWord(truncatedUid)?.replace(/-/g, ' ')}
            </Link>
          </span>
        );
      })}
    </div>
  );
};
