import { setContext } from '@apollo/client/link/context';
import { getCookie } from '../apis/cookie';
import { AUTH_TOKEN } from '../utils/constants/cookieNames';

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = getCookie(AUTH_TOKEN);
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : null,
    },
  };
});

export default authLink;
