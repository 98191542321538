import React from 'react';
import { Button, Card, Image, Tagline, Section } from 'components';
import { KeyPoints } from 'components/Page';
import * as styles from './styles.module.scss';

export const CaseStudy = ({ data }) => {
  const { primary } = data;
  const { icon, tagline, image_postion: imagePosition, case_study: caseStudyObj } = primary;

  // test comment
  const caseStudy = caseStudyObj?.document?.data;
  const companyName = caseStudy?.company_name?.text;
  const keyPoints = caseStudy?.key_points;
  const description = caseStudy?.long_description?.html;
  const image = caseStudy?.tile_image;
  const link = caseStudy?.link?.document?.uid;
  const cardClassnames = {
    card: styles.card,
    cardImageWrapper: styles.cardImageWrapper,
    cardBody: styles.cardBody,
  };
  const keyPointsClassnames = {
    keyPoints: styles.keyPoints,
    keyPoint: styles.keyPoint,
    label: styles.label,
    figure: styles.figure,
  };

  return (
    <Section className={styles.caseStudyContainer} containerClassName={styles.wrapper} sliceName="CaseStudy">
      <Card
        imageProps={{ image }}
        inheritedClassnames={cardClassnames}
        className={`${styles.caseStudySection} ${(imagePosition && imagePosition.toLowerCase()) || ''}`}
      >
        <div className={styles.taglineWrapper}>
          {icon && <Image image={icon} className={styles.taglineIcon} />}
          {tagline && tagline.text && <Tagline tagline={tagline} />}
        </div>
        {companyName && <h3 className="company-name">{companyName}</h3>}
        {description && <div dangerouslySetInnerHTML={{ __html: description }} className={styles.description} />}
        <KeyPoints inheritedClassnames={keyPointsClassnames} keyPoints={keyPoints} />
        <Button to={`/blog/${link}`}>View Case Study</Button>
      </Card>
    </Section>
  );
};
