export const shareLinks = (uid, companyName) => {
  // slug is the retrieved prop - 'uid' from prismicDealData
  const slug = uid;

  // companyTag is the retrieved prop and then empty spaces are removed so twitter can make hastag
  const companyTag = companyName?.replace(/\s/g, '');

  // Custom Twitter message
  const twitterText = `Check this great investment opportunity in ${companyName} via @Equitise. Click the link below to find out more about the company and how you can invest`;

  const homeUrl = process.env.GATSBY_SITE_URL;

  const socialShareLinks = [
    {
      link: { url: `https://www.facebook.com/sharer/sharer.php?u=${homeUrl}/offer/${slug}` },
      platform: 'Facebook',
    },
    {
      link: {
        url: `https://twitter.com/intent/tweet?text=${twitterText}&url=${homeUrl}%2Foffer%2F${slug}&hashtags=Equitise,crowdfunding,${companyTag}`,
      },
      platform: 'Twitter',
    },
    {
      link: {
        url: `https://www.linkedin.com/shareArticle?mini=true&url=${homeUrl}/offer/${slug}`,
      },
      platform: 'LinkedIn',
    },
  ];
  return socialShareLinks;
};
