// extracted by mini-css-extract-plugin
export var blogCarouselHeader = "styles-module--blog-carousel-header--mOWTN";
export var blogCta = "styles-module--blog-cta--EM1xc";
export var blogfeed = "styles-module--blogfeed--yBJDa";
export var pageSliceHeader = "styles-module--page-slice-header--FkSO0";
export var postCard = "styles-module--post-card--2PKQo";
export var postCardContent = "styles-module--post-card-content--a4oRc";
export var postCardImageWrapper = "styles-module--post-card-image-wrapper--qTQJl";
export var postCardInfo = "styles-module--post-card-info--v0GCe";
export var postCardTitle = "styles-module--post-card-title--ZpLrX";
export var primary = "#06004d";
export var secondary = "#4dafd7";