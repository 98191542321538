import React, { useContext, useEffect, useState } from 'react';
import smoothScrollIntoView from 'smooth-scroll-into-view-if-needed';
import { DealContext } from '../../context/DealContext';
import { Arrow } from '../Arrow';
import { capitaliseString, trackAmplitudeEvent } from '../../utils';
import { RETAIL } from '../../utils/constants/offerTypes';
import { AuthContext, TrackEventContext } from '../../context';
import { userData } from '../../utils/userDataToAmplitude';
import * as styles from './Sidebar.module.scss';

let ResizeSensor;

export const Sidebar = ({ headerHeight, sticky }) => {
  const { dbDealData, prismicDealData } = useContext(DealContext);
  const { body: sliceData, isEoi, offerType } = prismicDealData;
  const { questions } = dbDealData || {};
  const { resource: user } = useContext(AuthContext) || {};
  const { intercomAttributes, intercomEmail } = useContext(TrackEventContext) || {};

  const [active, setActive] = useState(null);

  // TODO convert to camel when I convert other pages slices to camel
  const prismicAnchors = sliceData
    .filter(slice => slice.slice_type === 'anchor_point')
    ?.map(anchor => ({ name: anchor?.primary?.name?.text }));

  const qaLink = { name: 'communications' };

  const fixedAnchors = isEoi ? [] : [qaLink];

  // Set state by plucking just the name off the slice object
  const [sidebarLinks, setSidebarLinks] = useState([...prismicAnchors, ...fixedAnchors]);

  const getLinkOffsets = () => {
    // Loop over sidebar items and get the offsetTop of the related divs
    sidebarLinks.forEach((link, index) => {
      const linkElement = document.getElementById(link.name);
      sidebarLinks[index].offset = linkElement ? linkElement.offsetTop - 10 : 9999;
    });
    // Save sideLinks with offset keys to state
    setSidebarLinks(sidebarLinks);
    setActive(active || sidebarLinks[0]);
  };

  const scrollListener = () => {
    const top = window.pageYOffset;
    const scrollActive = sidebarLinks.find((link, index, self) => {
      // First sidebar link selected when scroll is less than first item offset
      if (index === 0 && top < link.offset) return true;
      // Last sidebar link selected when check reaches end of array
      if (!self[index + 1]) return true;
      // Check whether top falls between active and next item's offset
      return top > link.offset && top < self[index + 1].offset;
    });
    if (scrollActive && scrollActive !== active) {
      setActive(scrollActive);
    }
  };

  const scrollToAnchor = (event, linkId) => {
    event.preventDefault();
    const sectionElement = document.getElementById(linkId);
    smoothScrollIntoView(sectionElement, { behavior: 'smooth', block: 'start' });

    try {
      trackAmplitudeEvent({
        email: user?.email || intercomEmail,
        eventName: `Clicked Anchor Link`,
        eventData: {
          sidebarLink: linkId,
        },
        userData: user?.email ? userData(user) : intercomAttributes || {},
      });
    } catch (error) {
      console.error('Amplitude event Clicked Anchor Link failed');
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', scrollListener);
    // Fetch offsetTop values for elements matching sidebar ids
    getLinkOffsets();
    // Refetch offsets when container is resized (accordion open/close etc)
    const layoutElement = document.getElementById('layout');
    // eslint-disable-next-line
    ResizeSensor = require('resize-sensor');
    // eslint-disable-next-line
    new ResizeSensor(layoutElement, getLinkOffsets);
    // Tidy up when unmounting component from DOM
    return () => {
      window.removeEventListener('scroll', scrollListener);
    };
  }, []);

  const isRetail = offerType === RETAIL;
  const showQaLink = !isEoi && isRetail;

  const numQuestions = questions?.length;
  const numQuesUi = Boolean(numQuestions) && numQuestions > 0 ? ` [${numQuestions}]` : '';

  return (
    <>
      <section className={`${styles.offerRoomSidebar} ${sticky ? styles.sticky : ''}`} id="offer-room-sidebar">
        <ul className="offer-room-sidebar-items" style={{ top: sticky ? `${headerHeight}px` : null }}>
          {prismicAnchors.map(({ name }) => (
            <SidebarLink
              active={active}
              displayName={name}
              key={name}
              location={name}
              scrollToAnchor={scrollToAnchor}
            />
          ))}
          {showQaLink && (
            <SidebarLink
              active={active}
              displayName={`Q&A Portal${numQuesUi}`}
              location={qaLink?.name}
              scrollToAnchor={scrollToAnchor}
            />
          )}
        </ul>
      </section>
    </>
  );
};

const inheritedClassnames = {
  arrowIcon: styles.arrowIcon,
  arrowIconFill: styles.arrowIconFill,
  arrowIconCircle: styles.arrowIconCircle,
};

const SidebarLink = ({ scrollToAnchor, location, displayName, active }) => (
  <li>
    <a
      href={`#${location}`}
      onClick={event => scrollToAnchor(event, location)}
      title={location}
      className={active?.name === location ? styles.active : 'inactive'}
    >
      {capitaliseString(displayName)}
      <Arrow inheritedClassnames={inheritedClassnames} />
    </a>
  </li>
);
