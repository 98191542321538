import React, { useContext, useState } from 'react';
import OnVisible from 'react-on-visible';
import { SectionHeading } from 'components/DealRoom';
import { Section } from 'components';
import { AuthContext, TrackEventContext, UtmContext, DealContext, LandingPageContext } from '../../context';
import { trackAmplitudeEvent, pushDataLayerEvent, trackOrtto } from '../../utils';
import { DocumentsModal } from './DocumentsModal';
import csv from '../../images/icons/csv.svg';
import xls from '../../images/icons/xls.svg';
import pdf from '../../images/icons/pdf.svg';
import doc from '../../images/icons/doc.svg';
import file from '../../images/icons/file.svg';
import jpg from '../../images/icons/jpg.svg';
import png from '../../images/icons/png.svg';
import pdfSyndicate from '../../images/icons/pdf-syndicate.svg';
import * as styles from './Documents.module.scss';

const getFileExtension = fileName => {
  if (!fileName) return null;
  return fileName.substr(fileName.lastIndexOf('.') + 1);
};

const icons = {
  csv,
  xls,
  pdf,
  doc,
  file,
  jpg,
  png,
  pdfSyndicate,
};

const DocumentLink = props => {
  const { document: fileObj, name, location } = props;
  const { text: documentName } = name;

  const [modalOpen, setModalOpen] = useState(false);

  const { resource: user, error } = useContext(AuthContext) || {};

  const { prismicDealData } = useContext(DealContext) || {};

  const { isSyndicateDeal } = useContext(LandingPageContext) || {};

  const { trackIntercomEvent, intercomId } = useContext(TrackEventContext) || {};

  const { utmParams } = useContext(UtmContext) || {};

  const { utmSource, utmMedium, utmCampaign } = utmParams || {};

  const { companyName } = prismicDealData || {};

  const fileExtension = getFileExtension(fileObj?.url);

  // const headingStyle = {
  //   color: `${isSyndicateDeal && primaryColour}`,
  // };

  const onDownloadClick = () => {
    try {
      trackOrtto({
        email: user?.email,
        tags: [`DOWNLOAD IM - ${companyName?.text}`],
      });
    } catch (orttoDownloadImError) {
      console.error('Ortto download IM event failed', orttoDownloadImError);
    }
    if (user?.email || intercomId) {
      try {
        trackIntercomEvent({
          email: user?.email,
          eventName: 'Download IM',
          metadata: {
            offerName: companyName?.text,
            source: utmSource,
            medium: utmMedium,
            campaign: utmCampaign,
          },
        });
      } catch (trackDLInterErr) {
        console.error('Intercom event Download IM failed', {
          error: trackDLInterErr,
          offer: companyName?.text,
        });
      }
    }

    try {
      trackAmplitudeEvent({
        email: user?.email,
        eventName: 'Download IM',
        eventData: {
          offerName: companyName?.text,
        },
        userData: {
          email: user?.email,
          firstName: user?.firstName,
          lastName: user?.lastName,
          source: utmSource,
          medium: utmMedium,
          campaign: utmCampaign,
          state: user?.address?.state,
          subscribeToEmails: user?.subscribeToComms,
          sophisticatedStatus: user?.profile?.sophisticatedStatus?.name,
          telephone: user?.profile?.telephone,
          identityVerifiedAt: user?.identityVerifiedAt,
        },
      });
    } catch (trackDLAmpErr) {
      console.error('Amplitude event Download IM failed', {
        error: trackDLAmpErr,
        offer: companyName?.text,
      });
    }
    try {
      pushDataLayerEvent({ eventName: 'Download IM', eventData: { offerName: companyName?.text } });
    } catch (pushDataLayerDLFailed) {
      console.error('Data layer event Download IM failed', {
        error: pushDataLayerDLFailed,
        offer: companyName?.text,
      });
    }
  };

  const onModalClick = event => {
    event.preventDefault();

    return setModalOpen(true);
  };

  if (user || error === 'Email not verified') {
    return (
      <a
        href={fileObj.url}
        className={`${styles.offerRoomDocumentsItem} ${fileExtension} ${isSyndicateDeal && styles.sydicateDealDocs}`}
        download
        target="__blank"
        onClick={onDownloadClick}
      >
        <img src={isSyndicateDeal ? icons.pdfSyndicate : icons[fileExtension] || file} alt={documentName} />
        {documentName}
      </a>
    );
  }

  return (
    <>
      <button
        type="button"
        className={`${styles.offerRoomDocumentsItem} ${fileExtension} ${isSyndicateDeal && styles.sydicateDealDocs}`}
        onClick={onModalClick}
      >
        <img src={isSyndicateDeal ? icons.pdfSyndicate : icons[fileExtension] || file} alt={documentName} />
        {documentName}
      </button>
      <DocumentsModal modalOpen={modalOpen} setModalOpen={setModalOpen} location={location} />
    </>
  );
};

export const Documents = props => {
  const { data, deal, location, template } = props;
  const { primary, items } = data;
  const { isSyndicateDeal } = useContext(LandingPageContext) || {};

  return (
    <OnVisible
      className={`offer-room-content-block ${styles.offerRoomDocuments} ${styles[template] || ''} ${isSyndicateDeal &&
        styles.sydicateDealOfferRoomDocs}`}
      visibleClassName={styles.visible}
      percent={20}
    >
      <Section noContainer spacing="n" sliceName="Documents">
        {primary && (
          <SectionHeading
            className={`${styles.sectionHeading} ${isSyndicateDeal && styles.sydicateDealSectionHeeading}`}
            heading={primary.heading}
          />
        )}
        {items && (
          <div className={`${styles.offerRoomDocumentsList} ${isSyndicateDeal && styles.sydicateDealDocsList}`}>
            {items.map(documentLink => (
              <DocumentLink {...documentLink} key={documentLink.name.text} deal={deal} location={location} />
            ))}
          </div>
        )}
      </Section>
    </OnVisible>
  );
};
