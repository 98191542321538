import React from 'react';
import { Image, SocialLink } from 'components';
import * as styles from './TeamMembers.module.scss';

export const TeamMembers = ({ teamMembers, isSyndicateDeal }) => (
  <div className={styles.teamMembersContainer}>
    {teamMembers.map(teamMember => {
      const {
        team_member_avatar: avatar,
        founder: featured,
        linkedin_url: linkedInUrl,
        position,
        name,
        bio,
      } = teamMember;
      return (
        <div className={`${styles.teamMember} ${featured === 'yes' ? 'founder' : 'basic'}`} key={name && name.text}>
          <div className={styles.teamMemberContent}>
            <div className={styles.avatar}>
              <Image image={avatar} alt={avatar.alt || 'Team Member'} />
            </div>
            <div className={styles.teamMemberDetails}>
              <div className={styles.teamMemberName}>
                <h4 className={`${styles.memberName} ${isSyndicateDeal && styles.syndicateMemberName}`}>
                  {name && name.text}
                </h4>
                {linkedInUrl?.url?.length > 0 && (
                  <SocialLink
                    type="LinkedIn"
                    theme={`${isSyndicateDeal ? 'secondary' : 'primary'}`}
                    url={linkedInUrl.url}
                  />
                )}
              </div>
              <span className={`${styles.position} ${isSyndicateDeal && styles.syndicateMemberPosition}`}>
                {position && position.text}
              </span>
            </div>
          </div>
          {/* {featured === 'yes' && bio && (
          <div className={styles.offerRoomTeamMemberBio} dangerouslySetInnerHTML={{ __html: bio.html }} />
        )} */}
        </div>
      );
    })}
  </div>
);
