// extracted by mini-css-extract-plugin
export var basic = "styles-module--basic--mB60C";
export var dealRoom = "styles-module--deal-room--DcmRM";
export var featured = "styles-module--featured--6zR4w";
export var gatsbyImage = "styles-module--gatsby-image--gsoFC";
export var offerRoomAvatar = "styles-module--offer-room-avatar--cw3XI";
export var offerRoomTeamMember = "styles-module--offer-room-team-member--E6dMs";
export var offerRoomTeamMemberBio = "styles-module--offer-room-team-member-bio--E2M5F";
export var offerRoomTeamMemberContent = "styles-module--offer-room-team-member-content--oKapV";
export var offerRoomTeamMemberDetails = "styles-module--offer-room-team-member-details--vL2dC";
export var offerRoomTeamMemberName = "styles-module--offer-room-team-member-name--b6faF";
export var offerRoomTeamMembers = "styles-module--offer-room-team-members--D05hG";
export var primary = "#06004d";
export var secondary = "#4dafd7";
export var teamBlock = "styles-module--team-block--GuHS0";
export var visible = "styles-module--visible--ES0Xk";