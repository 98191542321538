import React, { useState } from 'react';
import OnVisible from 'react-on-visible';
import { StaticQuery, graphql } from 'gatsby';
import { Image, Section } from 'components';
import * as styles from './styles.module.scss';

const logosQuery = graphql`
  query {
    ...LogosFragment
  }
`;

export const Logos = () => {
  const [visible, setVisible] = useState(false);

  return (
    <StaticQuery
      query={logosQuery}
      render={queryData => {
        const { logos = [] } = queryData;
        const logoArr = logos.data.logos;
        return (
          <OnVisible className={styles.logos} onChange={isVisible => setVisible(isVisible)}>
            <Section className={styles.wrapper} noContainer sliceName="Logos">
              <span className={styles.text}>We have been featured in the following publications</span>
              {visible && (
                <div className={styles.grid}>
                  {logoArr.map(item => (
                    <div className={styles.gridItem} key={item.logo.alt || item.logo.url}>
                      <Image className={styles.img} image={item.logo} />
                    </div>
                  ))}
                </div>
              )}
            </Section>
          </OnVisible>
        );
      }}
    />
  );
};
