import React, { useContext } from 'react';
import { DealRoomCta } from '.';
import { TrackEventContext, DealContext } from '../../context';
import { SocialLinks } from '..';
import { formatCurrency, shareLinks } from '../../utils';
import { WHOLESALE } from '../../utils/constants/offerTypes';
import * as styles from './Aside.module.scss';

export const Aside = props => {
  const { sticky, headerHeight } = props;
  const { prismicDealData, dbDealData } = useContext(DealContext);
  const { intercomId } = useContext(TrackEventContext) || {};
  const {
    currency,
    companyName,
    isEoi,
    hideRaisedAmount,
    offerType,
    roundMaximum: maxInvestment,
    tileDescription,
    uid,
    prismicId,
  } = prismicDealData;
  const { raisedAmount } = dbDealData || {};
  const inheritedClassnames = {
    button: styles.button,
    investOr: styles.investOr,
    investLogin: styles.investLogin,
  };

  const regShareLinks = shareLinks(uid, companyName.text);
  const location = 'Aside Section';

  const isManuDeal = prismicId === 'ZBlAbREAAH-q8Wp5';

  return (
    <section className={`${styles.offerRoomAside} ${styles[sticky ? 'sticky' : '']}`} id="offer-room-aside">
      <div className={styles.offerRoomAsideContents} style={{ top: sticky ? `${headerHeight}px` : null }}>
        <div className={styles.socialsWrapper}>
          <SocialLinks
            inheritedClassname={styles.shareLinks}
            theme="primary"
            socialLinks={regShareLinks}
            event={companyName.text}
            user={intercomId}
            area={location}
          />
        </div>
        <div className={styles.offerRoomAsideMessage} dangerouslySetInnerHTML={{ __html: tileDescription.html }} />
        {offerType === WHOLESALE && maxInvestment && (
          <span className={styles.offerRoomAsideRaised}>Round Max: {formatCurrency(maxInvestment, currency)}</span>
        )}
        {!isEoi && <RaisedAmount raisedAmount={raisedAmount} hideRaisedAmount={hideRaisedAmount} />}
        <DealRoomCta inheritedClassnames={inheritedClassnames} isManuCtaAside={isManuDeal} />
      </div>
    </section>
  );
};

const RaisedAmount = ({ raisedAmount, hideRaisedAmount }) => {
  if (!raisedAmount || raisedAmount === 0 || hideRaisedAmount)
    return <span className={styles.offerRoomAsideRaised}>Offer Live</span>;

  return <span className={styles.offerRoomAsideRaised}>Raised: {raisedAmount}</span>;
};
