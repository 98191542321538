import { pluralize } from 'utils';

// Takes a dayjs Timespan object and returns
// x minutes, hours, days, months or years since
export const timeSince = dateObj => {
  const time = {
    minutes: dateObj?.minutes,
    hours: dateObj?.hours,
    days: dateObj?.days,
    months: dateObj?.months,
    years: dateObj?.years,
  };

  if (time.years > 0) {
    return `${time.years} ${pluralize('year', time.years)} ago`;
  }
  if (time.months > 0) {
    return `${time.months} ${pluralize('month', time.months)} ago`;
  }
  if (time.days > 0) {
    return `${time.days} ${pluralize('day', time.days)} ago`;
  }
  if (time.hours > 0) {
    return `${time.hours} ${pluralize('hour', time.hours)} ago`;
  }
  if (time.minutes > 0) {
    return `${time.minutes} ${pluralize('minute', time.minutes)} ago`;
  }
  return 'Less than one minute ago';
};
