import React, { useState, useEffect, useContext } from 'react';
import { Modal } from 'components';
import { getCookie } from '../apis';
import { IP_COUNTRY } from '../utils/constants/cookieNames';
import { AuthContext } from '../context';
import * as styles from './ForeignDealNotice.module.scss';

export const ForeignDealNotice = ({ prismicDealRoom }) => {
  const ipCountryCookie = getCookie(IP_COUNTRY);
  const { resource: user } = useContext(AuthContext) || {};
  const userCountry = user?.address?.country?.name;
  const userIsInternational = user
    ? userCountry !== 'Australia' && userCountry !== 'New Zealand'
    : ipCountryCookie !== 'Australia' && ipCountryCookie !== 'New Zealand';

  const [modalMessage, setModalMessage] = useState('');
  const [modalOpen, setModalOpen] = useState(false);
  const closeModal = () => setModalOpen(false);

  useEffect(() => {
    if (ipCountryCookie && prismicDealRoom.data.country === 'Australia') {
      if (userCountry === undefined) {
        if (ipCountryCookie === 'Australia') {
          setModalOpen(false);
        } else {
          setModalOpen(true);
        }
      } else if (userCountry === 'Australia') {
        setModalOpen(false);
      } else if (userCountry !== 'Australia') {
        if (user?.profile?.sophisticatedStatus?.id === '0') {
          setModalOpen(true);
        } else {
          setModalOpen(false);
        }
      } else {
        setModalOpen(false);
      }

      setModalMessage(
        userIsInternational
          ? '<p>To invest from outside of Australia or New Zealand you will need to certify that you are a wholesale or sophisticated investor in your jurisdiction. For more information, <a href="https://www.equitise.com/blog/who-can-invest" target="_blank">click here.</a></p>'
          : '<p>To invest in this deal, you have to be an Australian resident, or a New Zealand Wholesale/Qualifying Investor.</p>'
      );
    }

    if (ipCountryCookie && prismicDealRoom.data.country === 'New Zealand') {
      if (userCountry === undefined) {
        if (ipCountryCookie === 'New Zealand') {
          setModalOpen(false);
        } else {
          setModalOpen(true);
        }
      } else if (userCountry === 'New Zealand') {
        setModalOpen(false);
      } else if (userCountry !== 'New Zealand') {
        if (user?.profile?.sophisticatedStatus?.id === '0') {
          setModalOpen(true);
        } else {
          setModalOpen(false);
        }
      } else {
        setModalOpen(false);
      }

      setModalMessage(
        userIsInternational
          ? '<p>To invest from outside of Australia or New Zealand you will need to certify that you are a wholesale or sophisticated investor in your jurisdiction. For more information, <a href="https://www.equitise.com/blog/who-can-invest" target="_blank">click here.</a></p>'
          : '<p>To invest in this deal, you have to be a New Zealand resident, or an Australian Sophisticated Investor.</p>'
      );
    }
  }, [userCountry, ipCountryCookie]);

  return (
    <Modal active={modalOpen} closeModal={closeModal}>
      <div className={styles.modalContainer}>
        <div className={styles.modalText} dangerouslySetInnerHTML={{ __html: modalMessage }} />
        <a href="#close" onClick={closeModal} className={`${styles.button} button`}>
          close
        </a>
      </div>
    </Modal>
  );
};
