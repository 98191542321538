import React, { useState, useEffect } from 'react';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import duration from 'dayjs/plugin/duration';
import relativeTime from 'dayjs/plugin/relativeTime';
import * as styles from './Timer.module.scss';

export const Timer = ({
  endDate,
  prefix = '',
  suffix = ' remaining',
  closedMsg,
  showClosedMsg,
  timerClassnames: inheritedClassnames,
  inheritedClassname,
}) => {
  dayjs.extend(utc);
  dayjs.extend(duration);
  dayjs.extend(relativeTime);

  const [timeLeft, setTimeLeft] = useState('');
  const [intervalId, setIntervalId] = useState(0);

  useEffect(() => {
    setIntervalId(setInterval(formatTimer, 1000));

    return () => clearInterval(intervalId);
  }, []);

  // Need to parse date to yyyy-MM-DDTHH:mm:ss:Z format for Safari browser
  // 2021-03-05T13:00:00+0000 (breaks some Safari versions)
  // 2021-03-30T13:00:00+00:00 (correct format)

  // Query returns different string on different browsers (Safari 13.11) so using regex
  // for edge cases
  const parsedEndDate = endDate?.replace(/([+\-]\d\d)(\d\d)$/, '$1:$2');
  const exp = dayjs.utc(parsedEndDate);

  const formatTimer = () => {
    const now = dayjs.utc();
    const timeRemaining = dayjs.duration(exp.diff(now)).$d;

    // Days js time remaining does a rough estimate for months
    // Since we don't need months we can get the total number of days between two dates and floor the result
    // Let the difference do the heavy lifting in terms of splitting out hours, minutes and seconds components of partial days
    const days = Math.floor(exp.diff(now, 'days', true));

    const { hours, minutes, seconds } = timeRemaining;

    const show = unit => unit > 0;
    const time = [];

    if (show(days)) time.push(`${days}d`);
    if (show(hours)) time.push(`${hours}h`);
    if (show(minutes)) time.push(`${minutes}m`);
    if (show(seconds) && time.length > 0) {
      const timeString = time.join(', ');
      return setTimeLeft(`${timeString} and ${seconds}s`);
    }
    if (show(seconds) && time.length === 0) {
      return setTimeLeft(`${seconds}s`);
    }
    return setTimeLeft(time.join(', '));
  };
  // All dates are stored in UTC time so we need to check if deal is expired using UTC
  const now = dayjs.utc();

  const isLive = now.isBefore(exp) && !showClosedMsg;

  const validClassnames = inheritedClassnames || {};

  if (isLive) {
    return (
      <span
        className={`${validClassnames.offerRoomHeaderTimer || ''} ${inheritedClassname || ''} offer-room-header-timer`}
      >
        {prefix}
        <span className={`${styles.timeRemaining} ${validClassnames.timeRemaining || ''} time-remaining`}>
          {timeLeft}
        </span>
        {suffix}
      </span>
    );
  }
  return <span className={styles.offerRoomClosed}>{closedMsg}</span>;
};
