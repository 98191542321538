import React from 'react';
import { Image } from 'components';
import { useCountUp } from 'react-countup';
import OnVisible from 'react-on-visible';

import * as styles from './MetricCard.module.scss';

export const MetricCard = props => {
  const { title, label, prefix, number, suffix, icon, decimals = 0, duration, style } = props;
  const countUpRef = React.useRef(null);
  const { start } = useCountUp({
    ref: countUpRef,
    start: 0,
    end: number,
    decimals,
    duration,
    delay: 0,
    separator: ',',
    prefix,
    suffix,
    startOnMount: false,
    style,
  });

  return (
    <OnVisible
      className={`${style || styles.metric}`}
      id="metric"
      percent={20}
      onChange={isVisible => isVisible && start()}
    >
      {icon && (
        <div className={styles.metricIcon}>
          <Image image={icon} />
        </div>
      )}
      {number && <div className={styles.metricNumber} ref={countUpRef} />}
      {label && <span className={styles.metricLabel}>{label}</span>}
      {title && <span className={styles.metricTitle}>{title}</span>}
    </OnVisible>
  );
};
