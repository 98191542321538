// extracted by mini-css-extract-plugin
export var buttonsWrapperTestimonials = "styles-module--buttons-wrapper-testimonials--MPuuT";
export var embla = "styles-module--embla--7rP5X";
export var pageSliceHeader = "styles-module--page-slice-header--zrOu+";
export var primary = "#06004d";
export var secondary = "#4dafd7";
export var tabsPrefix = "styles-module--tabs-prefix--oeNQ3";
export var tabsWrapper = "styles-module--tabs-wrapper--S3TAL";
export var testimonials = "styles-module--testimonials--svV+W";
export var testimonialsBody = "styles-module--testimonials-body--b3D3x";
export var testimonialsCarouselHeader = "styles-module--testimonials-carousel-header--A1q8z";