// extracted by mini-css-extract-plugin
export var button = "styles-module--button--pw9Bv";
export var card = "styles-module--card--KFqUf";
export var cardBody = "styles-module--card-body--r8lbd";
export var cardImageWrapper = "styles-module--card-image-wrapper--B63K4";
export var gatsbyImage = "styles-module--gatsby-image--Q8DJj";
export var imageText = "styles-module--image-text--LDVWd";
export var imageTextCard = "styles-module--image-text-card--Z3FjL";
export var imageTextDescription = "styles-module--image-text-description--F3YX5";
export var imageTextTitle = "styles-module--image-text-title--xi9bD";
export var primary = "#06004d";
export var right = "styles-module--right--aaPkt";
export var secondary = "#4dafd7";
export var tagline = "styles-module--tagline--ZVkCk";
export var wrapper = "styles-module--wrapper--z8dTT";