// extracted by mini-css-extract-plugin
export var large = "Spinner-module--large--w+6ws";
export var lightSpinner = "Spinner-module--light-spinner--Zm6DI";
export var primary = "#06004d";
export var secondary = "#4dafd7";
export var skCircle = "Spinner-module--sk-circle--srk3E";
export var skCircle10 = "Spinner-module--sk-circle10--H7cmi";
export var skCircle11 = "Spinner-module--sk-circle11--wbob7";
export var skCircle12 = "Spinner-module--sk-circle12--JDaU1";
export var skCircle2 = "Spinner-module--sk-circle2--coexV";
export var skCircle3 = "Spinner-module--sk-circle3--rCRJ4";
export var skCircle4 = "Spinner-module--sk-circle4--AU0kr";
export var skCircle5 = "Spinner-module--sk-circle5--lGAfI";
export var skCircle6 = "Spinner-module--sk-circle6--c6Mxw";
export var skCircle7 = "Spinner-module--sk-circle7--w7JWh";
export var skCircle8 = "Spinner-module--sk-circle8--iiMJ-";
export var skCircle9 = "Spinner-module--sk-circle9--cqZtM";
export var skCircleFadeDelay = "Spinner-module--sk-circleFadeDelay--QlFvJ";
export var skFadingCircle = "Spinner-module--sk-fading-circle--ui1Dc";
export var small = "Spinner-module--small--Jmyo5";