module.exports = {
  pathPrefix: '/', // Prefix for all links. If you deploy your site to example.com/portfolio your pathPrefix should be "portfolio"
  title: 'Crowdfunding Australia & New Zealand | About | Equitise', // Default Site Title used for SEO & PWA
  description: 'Equitise is the leader in online equity crowdfunding enabling high growth & innovative companies to partner with investors who believe in them.', // Default Site Decription used for SEO
  siteName: 'Equitise', // Sitename for Facebook
  siteLanguage: 'en', // Language Tag on <html> element
  banner: '/logos/logo-1024.jpg', // Default OpenGraph image
  ogLanguage: 'en_US', // Facebook Language

  // JSONLD / Manifest
  favicon: 'src/favicon.png', // Used for manifest favicon generation
  shortName: 'Equitise', // shortname for manifest. MUST be shorter than 12 characters
  author: 'Equitise', // Author for schemaORGJSONLD
  themeColor: '#06004D',
  backgroundColor: '#06004D',

  twitter: '', // Twitter Username
  googleTagManagerId: '',
};
