import React, { useState, useContext } from 'react';
import { SearchModal } from './SearchModal';
import { trackAmplitudeEvent } from '../../utils';
import { AuthContext, TrackEventContext } from '../../context';
import { userData } from '../../utils/userDataToAmplitude';
import * as styles from './SearchComponent.module.scss';

export const SearchComponent = ({
  isSlice,
  isOnHeader,
  isOnNavbar,
  searchBarPlaceholder,
  searchInputPlaceholder,
  className,
  inheritedClassname,
  isSyndicateDeal,
}) => {
  const { resource: user } = useContext(AuthContext) || {};
  const { intercomAttributes, intercomEmail } = useContext(TrackEventContext) || {};
  // modal related events
  const [modalOpen, setModalOpen] = useState(false);
  const onModalClick = event => {
    event.preventDefault();

    try {
      trackAmplitudeEvent({
        email: user?.email || intercomEmail,
        eventName: `Clicked Search`,
        userData: user?.email ? userData(user) : intercomAttributes || {},
      });
    } catch (error) {
      console.error('Amplitude event clicked Search failed');
    }

    return setModalOpen(true);
  };
  const closeModal = () => setModalOpen(false);

  const placeHolderText = () => {
    if (isSlice) {
      return searchInputPlaceholder?.text || 'Search for deals and blogs';
    }
    if (isOnHeader) {
      return searchBarPlaceholder?.text || 'Search for deals and blogs';
    }
  };

  return (
    <section className={`${styles.searchBarContainer} ${className || ''}`}>
      {/* Display this section if search bar is a slice or is on header */}
      {(isSlice || isOnHeader) && (
        <input
          className={`${inheritedClassname || ''}`}
          type="text"
          onClick={onModalClick}
          placeholder={placeHolderText()}
        />
      )}

      {/* Display this section if search bar is on navbar */}
      {!isSyndicateDeal && isOnNavbar && (
        <span
          aria-hidden="true"
          onClick={onModalClick}
          className={`${styles.icon} ${styles.iconSearchWhite} icon icon-search-white`}
        ></span>
      )}
      <SearchModal modalOpen={modalOpen} closeModal={closeModal} />
    </section>
  );
};
