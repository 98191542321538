import React from 'react';
// Npm package that formats date
import moment from 'moment';
import { Section } from 'components';
import { graphql, useStaticQuery } from 'gatsby';
import TimelineComponent from '../../components/TimelineComponent';
import * as styles from './styles.module.scss';

// Timeline component that is rendered in the newsfeed page
export const BlogTimeline = props => {
  const staticResponse = useStaticQuery(query);
  const edges = staticResponse?.allPrismicBlogPost?.edges;
  const blogItems = [];

  // do nothing with slice data except the slice_type, which is used by the page template to render the slice, use the blogs retrieved from the query below
  // restructure blog posts to the same object structure as the items from the newsfeed
  // then push to blogItems array
  edges.map(blog => {
    const { data, uid } = blog?.node;
    const blogObject = {
      date: data?.date_published,
      description: {
        text: data?.content?.text,
      },
      link: {
        url: `${process.env.GATSBY_SITE_URL}/blog/${uid}`,
      },
      link_title: {
        text: 'Read the full blog post here',
      },
      timeline_image: {
        url: data?.tile_image?.url,
      },
      timeline_tagline: data?.categories[0]?.category?.uid,
      title: {
        text: data?.title?.text,
      },
    };
    return blogItems.push(blogObject);
  });

  // This variable is defined to specify which grid position each card element in the list will be placed in
  let row = 1;
  return (
    <Section className={styles.container} containerClassName={styles.wrapper} sliceName="Timeline" spacing="n">
      {blogItems.map(item => {
        row += 1;
        return (
          <div className={styles.child} style={{ gridRowStart: row - 1, gridRowEnd: row + 1 }}>
            <div className={styles.circle}>
              <div className={styles.iconDashContainer}>
                <span className={styles.iconDash} />
              </div>
              <span
                // Dynamically setting icons, relative to the element's tagline
                className={
                  (item.timeline_tagline === 'success story' && 'icon icon-rocket') ||
                  (item.timeline_tagline === 'trends-and-insights' && 'icon icon-info') ||
                  (item.timeline_tagline === 'for-investors' && 'icon icon-money') ||
                  (item.timeline_tagline === 'for-companies' && 'icon icon-money') ||
                  (item.timeline_tagline === 'educational-resources' && 'icon icon-search-white') ||
                  'icon icon-info'
                }
              ></span>
            </div>
            {item.date ? (
              <p className={styles.date}>{item.date && moment(item.date).format('MMMM Do YYYY')}</p>
            ) : (
              <p className={styles.emptyParagraph}></p>
            )}
            <div className={styles.card}>
              <TimelineComponent item={item} />
            </div>
          </div>
        );
      })}
    </Section>
  );
};

const query = graphql`
  query AllBlogs {
    allPrismicBlogPost(sort: { fields: data___date_published, order: DESC }) {
      edges {
        node {
          id
          uid
          data {
            date_published
            tile_image {
              url
            }
            title {
              text
            }
            content {
              text
            }
            categories {
              category {
                uid
              }
            }
          }
        }
      }
    }
  }
`;
