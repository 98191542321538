import React, { createContext } from 'react';
import { getName } from 'country-list';
import { useFetchResource } from '../components/CustomHooks';
import { getCookie, setCookie } from '../apis';
import { COUNTRY_OF_RESIDENCE, IP_COUNTRY } from '../utils/constants/cookieNames';
import { AUSTRALIA, NEW_ZEALAND } from '../utils';

export const GeoContext = createContext(null);

export const GeoProvider = ({ children }) => {
  // Cookie set off user data post login attempt
  const authCountryCookie = getCookie(COUNTRY_OF_RESIDENCE);
  // Cookie set using response from geo locating request (if no auth country cookie could be found)
  const ipCountryCookie = getCookie(IP_COUNTRY);

  const countryNameCookie = authCountryCookie || ipCountryCookie;

  const countryCodeToName = code => {
    switch (code) {
      case 'AU':
        return AUSTRALIA;
      case 'NZ':
        return NEW_ZEALAND;
      default:
        return getName(code);
    }
  };

  const onGeoLocateCompleted = countryCode => {
    const countryName = countryCodeToName(countryCode);
    return setCookie({ value: countryName, cookieName: IP_COUNTRY });
  };

  // If no country cookie make a geolocating request and set the result to a cookie for future use
  const { resource: countryCode, loading } = useFetchResource({
    endPoint: process.env.GEO_WORKER_URL,
    key: 'countryCode',
    condition: !countryNameCookie || countryNameCookie === 'undefined',
    fallback: 'AU',
    onCompleted: onGeoLocateCompleted,
    isExternal: true,
  });

  const countryName = countryNameCookie || countryCodeToName(countryCode);
  const geoData = { countryName, loading };

  return <GeoContext.Provider value={geoData}>{children}</GeoContext.Provider>;
};

GeoContext.displayName = 'GeoContext';
