import React, { useState, useContext } from 'react';
import { useMutation } from '@apollo/client';
import { FormikForm, Link } from 'components';
import { TextArea } from '../FormikInputs';
import { TrackEventContext } from '../../context';
import { trackAmplitudeEvent } from '../../utils/analytics';
import * as styles from './QuestionAnswerForm.module.scss';

import { CREATE_DEAL_QUESTION_ANSWER, UPDATE_DEAL_QUESTION_ANSWER } from './QuestionAnswerMutations';

const QuestionAnswerForm = ({
  description = '',
  answerId,
  questionId,
  refreshQuestions,
  respondee,
  setDisplayForm,
  type = 'answer',
  user,
  companyName,
  questionDescription,
  uid,
  setSubmitError,
}) => {
  const { trackIntercomEvent } = useContext(TrackEventContext) || {};

  const dealQuestionId = questionId;

  const [createDealQuestionAnswer] = useMutation(CREATE_DEAL_QUESTION_ANSWER);
  const [updateDealQuestionAnswer] = useMutation(UPDATE_DEAL_QUESTION_ANSWER);

  const urlOfferLink = `https://equitise.com/offer/${uid}`;

  const onSubmit = async values => {
    try {
      if (type === 'edit') {
        await updateDealQuestionAnswer({
          variables: {
            answerId,
            answerInput: {
              description: values.responseDescription,
            },
          },
        });
      } else {
        // Creates an answer to the question using mutation to node app
        await createDealQuestionAnswer({
          variables: {
            userId: user?.id,
            dealQuestionId,
            answerInput: {
              description: values?.responseDescription,
            },
          },
        });

        // This event is attributed to the question asker (respondee) which is triggers an email sent notifying them via Intercom
        await trackIntercomEvent({
          email: respondee?.email,
          name: `${respondee?.firstName} ${respondee?.lastName}`,
          eventName: 'Question answered',
          metadata: {
            offerName: companyName?.text,
            question: questionDescription,
            answer: values?.responseDescription,
            urlOfferLink,
          },
          questionAnswered: true,
        });

        await trackAmplitudeEvent({
          email: respondee?.email,
          eventName: 'Question answered',
          eventData: {
            offerName: companyName?.text,
          },
          userData: {
            email: respondee?.email,
            firstName: respondee?.firstName,
            lastName: respondee?.lastName,
          },
        });
      }

      setDisplayForm(false);
      setSubmitError('');
      return refreshQuestions();
    } catch (submitDealQuestionAnswerError) {
      console.error('onSubmit error failed for answer form', { error: submitDealQuestionAnswerError });
      if (submitDealQuestionAnswerError?.message === 'Not Authorised!') {
        setSubmitError(
          `${submitDealQuestionAnswerError?.message} Your session may have expired, please login again. If you have written a response, please save it elsewhere before trying again.`
        );
      } else {
        setSubmitError(
          `${submitDealQuestionAnswerError?.message} If you have written a response, please save it elsewhere before trying again.`
        );
      }
    }
  };

  const onCancelClick = event => {
    event.preventDefault();
    setSubmitError('');
    setDisplayForm(false);
  };

  const validationFunction = values => {
    const { responseDescription } = values;

    if (!responseDescription || responseDescription?.trim() === '') {
      return { responseDescription: 'Answer field can not be empty' };
    }
  };

  return (
    <FormikForm
      initialValues={{ description }}
      validationFunction={validationFunction}
      onSubmit={onSubmit}
      hideSubmitButton
    >
      <TextArea name="responseDescription" placeholder={`Responding to ${respondee?.firstName}`} />
      <div className={styles.answerFormFooter}>
        <Link className="cancel-answer-link" onClick={onCancelClick}>
          Cancel
        </Link>
        <button className={`${styles.button} primary`} type="submit">
          {type === 'edit' ? 'Edit' : 'Post'}
        </button>
      </div>
    </FormikForm>
  );
};

export default QuestionAnswerForm;
