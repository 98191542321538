import React, { useState } from 'react';
import { Card, Logo, Tagline } from 'components';
import * as styles from './TeamMemberCard.module.scss';

export const TeamMemberCard = ({
  data: {
    featured,
    profile_picture: profilePicture,
    hover_image: hoverImage,
    full_name: { text: fullName } = {},
    role: { text: role } = {},
    description: { html: description } = {},
    single_tagline: singleTagline,
    single_team_name: singleTeamName,
  },
  inheritedClassnames,
  className,
  cardImageWrapperClass,
  cardBodyClass,
  alteredCustomClassName,
}) => {
  const [image, setImage] = useState(profilePicture);
  const hasHoverImage = hoverImage?.url;
  const imageHoverOn = () => setImage(hasHoverImage ? hoverImage : profilePicture);
  const imageHoverOff = () => setImage(profilePicture);
  const validClassnames = inheritedClassnames || {};
  const imageProps = {
    image,
    imgClassName:
      image === profilePicture ? `${validClassnames.displayImage || ''} display-image` : 'hover-display-image',
  };

  // give margin top to card if it does not have tagline and team name
  const singleCardStyle = () => {
    if (!singleTagline?.text) {
      const style = {
        marginTop: '95px',
      };
      return style;
    }
  };

  return (
    <div>
      {singleTagline?.text && (
        <div className={styles.teamSectionHeading}>
          <Logo inheritedClassname={styles.logo} type="icon-tertiary" />
          <div>
            <Tagline inheritedClassname={styles.tagline} tagline={singleTagline} />
            <h2>{singleTeamName?.text}</h2>
          </div>
        </div>
      )}
      <div style={singleCardStyle()}>
        <Card
          cardImageWrapperClass={cardImageWrapperClass}
          cardBodyClass={cardBodyClass}
          inheritedClassname={className}
          imageProps={imageProps}
          imageHoverOn={imageHoverOn}
          imageHoverOff={imageHoverOff}
          alteredCustomClassName={alteredCustomClassName}
        >
          <div className={validClassnames.cardHeader || ''}>
            {fullName && <h4>{fullName}</h4>}
            {role && <span>{role}</span>}
          </div>
          {featured === 'Yes' && description && (
            <div dangerouslySetInnerHTML={{ __html: description }} className="description" />
          )}
        </Card>
      </div>
    </div>
  );
};
