// extracted by mini-css-extract-plugin
export var accordion = "Accordion-module--accordion--o-k+X";
export var accordionItem = "Accordion-module--accordion-item--Tpn49";
export var accordionItemBody = "Accordion-module--accordion-item-body--DzUqq";
export var accordionItemTitle = "Accordion-module--accordion-item-title--dLnix";
export var open = "Accordion-module--open--Ynm++";
export var primary = "#06004d";
export var rewardItemBody = "Accordion-module--rewardItemBody--A+nbt";
export var rewardThreshold = "Accordion-module--rewardThreshold--Jq8Ga";
export var rewardTitle = "Accordion-module--rewardTitle--+AT4M";
export var secondary = "#4dafd7";
export var thresholdAmount = "Accordion-module--thresholdAmount--cqET2";