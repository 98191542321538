import React, { useCallback } from 'react';

import { Image } from 'components';
import * as styles from './Card.module.scss';

export const Card = ({
  children,
  imageProps,
  className,
  inheritedClassname,
  inheritedClassnames,
  cardImageWrapperClass,
  cardBodyClass,
  imageHoverOn,
  imageHoverOff,
  alteredCustomClassName,
}) => {
  const enter = useCallback(() => imageHoverOn(), []);
  const leave = useCallback(() => imageHoverOff(), []);
  const validClassnames = inheritedClassnames || {};
  return (
    <div className={`${styles.card} ${className || ''} ${inheritedClassname || ''} ${validClassnames.card || ''}`}>
      {imageProps && (
        <div
          className={`${styles.cardImageWrapper} ${cardImageWrapperClass || ''} ${validClassnames.cardImageWrapper ||
            ''}`}
          onMouseEnter={imageHoverOn && enter}
          onMouseLeave={imageHoverOff && leave}
        >
          <Image {...imageProps} inheritedClassname={validClassnames.gatsbyImage || ''} />
        </div>
      )}
      <div
        className={`${styles.cardBody} ${
          alteredCustomClassName ? styles.boardMembers : styles.regularTeam
        } ${validClassnames.cardBody || ''} ${cardBodyClass || ''}`}
      >
        {children}
      </div>
    </div>
  );
};
