import React, { useContext, useEffect, useState } from 'react';
import OnVisible from 'react-on-visible';
import { Link, Button } from 'components';
import { QuestionAnswerQuestion, QuestionForm } from '.';
import { AuthContext, DealContext } from '../../context';

import * as styles from './QuestionAnswer.module.scss';

const QuestionAnswer = props => {
  const { location } = props;

  const [mounted, setMounted] = useState(false);
  const [submitError, setSubmitError] = useState('');

  useEffect(() => {
    setMounted(true);
  }, []);

  const { prismicDealData, dbDealData, refetchDeal: refreshQuestions } = useContext(DealContext);
  const { companyName, uid } = prismicDealData;
  const { id: dealId, members, questions } = dbDealData || {};

  const { resource: user } = useContext(AuthContext) || {};
  const isAdmin = user?.roles?.map(role => role.id)?.some(id => [1, 2, 3, 4, 5].includes(id));

  const numQuestions = questions?.length;
  const isDealMember = members?.map(member => member.id)?.includes(user?.id);
  const hasAnswerPrivilege = isDealMember || isAdmin;
  const hasAskPrivilege = Boolean(user) && !isDealMember;

  const loginUrl = `${process.env.CORE_APP_URL}/login${location.href ? `?redirect=${location.href}` : ''}`;
  const registerUrl = `${process.env.CORE_APP_URL}/register${location.href ? `?redirect=${location.href}` : ''}`;

  if (!mounted) return null;

  const submitErrorFunction = errorMessage => setSubmitError(errorMessage);

  return (
    <OnVisible className={`${styles.qaBlock} ${styles.offerRoomContentBlock}`} percent={20} id="communications">
      <h3>{`Question & Answers${numQuestions > 0 ? ` (${numQuestions})` : ''}`}</h3>
      {questions?.map(question => (
        <QuestionAnswerQuestion
          {...question}
          hasAnswerPrivilege={hasAnswerPrivilege}
          hasAskPrivilege={hasAskPrivilege}
          hasEditPrivilege={isAdmin}
          key={question.id}
          refreshQuestions={refreshQuestions}
          user={user}
          companyName={companyName}
          uid={uid}
          setSubmitError={submitErrorFunction}
        />
      ))}
      {hasAskPrivilege && (
        <QuestionForm
          companyName={companyName}
          dealId={dealId}
          refreshQuestions={refreshQuestions}
          user={user}
          setSubmitError={submitErrorFunction}
          uid={uid}
          dbDealData={dbDealData}
        />
      )}
      {submitError ? <div className={styles.error}>Error: {submitError}</div> : <div className={styles.error} />}
      {!user && (
        <section className="qa-cta">
          <h4>Want to know more?</h4>
          <p className={styles.qaCtaCopy}>
            <Link to={loginUrl}>Login</Link> or <Link to={registerUrl}>join</Link> Equitise to ask {companyName?.text} a
            question
          </p>
          <Button to={loginUrl}>Login</Button>
        </section>
      )}
    </OnVisible>
  );
};

export default QuestionAnswer;
