import React from 'react';
import { Section } from 'components';
import OnVisible from 'react-on-visible';
import { Accordion } from '../../components/Accordion';
import { formatNumber } from '../../utils';

import * as styles from './styles.module.scss';

export const InvestorRewards = props => {
  const {
    data: { items },
  } = props;

  // format items to be suited for the accordion component
  const formattedItems = items.map(item => {
    const obj = {
      title: item?.header?.text,
      body: item?.description1?.html,
      threshold: item?.threshold,
      rangeDescription: item?.range_description?.text,
    };
    return obj;
  });

  // range text display logic
  const rangeText = (list, item, text) => {
    if (!text) {
      if (list.indexOf(item) === list.length - 1) {
        return 'For investments above: ';
      }
      return 'For investments between: ';
    }
    return `${text} `;
  };

  // range values display logic
  const rangeValues = (list, item, index) => {
    if (list.indexOf(item) === list.length - 1) {
      return `$${formatNumber(item?.threshold)}`;
    }
    return `$${formatNumber(item?.threshold || 0)} - $${formatNumber(list[index + 1]?.threshold - 1)}`;
  };

  return (
    <OnVisible visibleClassName="slide-in" className="animate">
      <Section noContainer spacing="s">
        <h3>Investor Rewards</h3>
        <Accordion
          items={formattedItems.reverse()}
          isInvestorRewardsSlice
          rangeText={rangeText}
          rangeValues={rangeValues}
          accordionEventType="Investor Reward"
        />
      </Section>
    </OnVisible>
  );
};
