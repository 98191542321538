import React, { useEffect, useContext } from 'react';
import { graphql, navigate } from 'gatsby';
import { Layout } from 'components';
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews';
import { GeoContext } from '../context';
import { Slice } from '../slices/Slice';
import { countryToRoute } from '../utils';
import * as styles from './page.module.scss';

const Page = ({ location, data: staticData }) => {
  const data = staticData;

  const { prismicPage: page, prismicSettings } = data;

  const { data: pageData, uid } = page;

  const { warning_statements: warningStatements } = prismicSettings?.data || {};

  const {
    body: sliceData,
    header_tagline: tagline,
    header_title: title,
    header_content: content,
    header_image: image,
    header_custom_class: customClass,
    display_invest_dropdown: displayDropdown,
    display_deals_button: displayDealsButton,
    display_how_to_invest_link: displayHowToLink,
    display_hero_tile: displayHeroTile,
    hero_deal: heroDeal,
    _just_launched__banner_on_tile: justLaunchedBannerOnTile,
    closing_soon_banner_on_tile: closingSoonBannerOnTile,
    search_bar_on_header: searchBarOnHeader,
    search_bar_placeholder: searchBarPlaceholder,
    reduce_header_size: reduceHeaderSize,
  } = pageData;

  const headerProps = {
    sliceData,
    tagline,
    title,
    content,
    image,
    customClass,
    displayDropdown,
    displayDealsButton,
    displayHowToLink,
    displayHeroTile,
    heroDeal,
    justLaunchedBannerOnTile,
    closingSoonBannerOnTile,
    heroWarningStatement: warningStatements,
    searchBarOnHeader,
    searchBarPlaceholder,
    reduceHeaderSize,
  };

  const layoutProps = {
    location,
    pageData,
    headerProps,
    bodyClassName: 'page',
  };

  return (
    <Layout {...layoutProps}>
      {/* <RedirectRetail prismicSettings={prismicSettings} uid={uid}> */}
      <div className="page-wrapper">
        {sliceData.map(slice => (
          <Slice template="page" customClass={customClass} key={slice.id} data={slice} location={location} />
        ))}
      </div>
      {/* </RedirectRetail> */}
    </Layout>
  );
};

// const RedirectRetail = ({ prismicSettings, uid, children }) => {
//   const { countryName: countryOfResidence } = useContext(GeoContext) || {};
//   useEffect(() => {
//     const {
//       au_retail_page: { uid: auRetailUid },
//       nz_retail_page: { uid: nzRetailUid },
//     } = prismicSettings?.data;
//     const redirectUid = countryToRoute({
//       country: countryOfResidence,
//       auRetailUid,
//       nzRetailUid,
//     });
//     const isRetailPage = [auRetailUid, nzRetailUid].includes(uid);
//     const shouldRedirect = isRetailPage && redirectUid !== uid;

//     if (shouldRedirect) navigate(`/${redirectUid}`);
//   }, [countryOfResidence]);

//   return <>{children}</>;
// };

export default withPrismicPreview(Page);

export const pageQuery = graphql`
  query PageBySlug($uid: String!) {
    prismicPage(uid: { eq: $uid }) {
      id
      uid
      prismicId
      _previewable
      data {
        meta_title {
          text
        }
        meta_description {
          text
        }
        open_graph_image {
          url
        }
        schema {
          text
        }
        header_tagline {
          text
        }
        header_title {
          text
        }
        header_content {
          html
        }
        search_bar_on_header
        search_bar_placeholder {
          text
        }
        reduce_header_size
        display_invest_dropdown
        display_deals_button
        display_how_to_invest_link
        display_hero_tile
        _just_launched__banner_on_tile
        closing_soon_banner_on_tile
        hero_deal {
          id
          uid
          document {
            ... on PrismicDealRoom {
              data {
                country
                round_maximum
                round_minimum
                offer_type
                hide_raised_amount
                hide_timer
                end_date(formatString: "yyyy-MM-DDTHH:mm:SSZ")
                is_eoi
                is_maxed_out
                visibility
                eoi_footer {
                  html
                }
                company_name {
                  text
                }
                tile_description {
                  text
                }
                tile_image {
                  alt
                  url
                  localFile {
                    childImageSharp {
                      gatsbyImageData(width: 800, placeholder: BLURRED)
                    }
                  }
                }
                tile_logo {
                  alt
                  url
                  localFile {
                    childImageSharp {
                      gatsbyImageData(width: 200, placeholder: BLURRED)
                    }
                  }
                }
                tile_footer {
                  text
                }
              }
            }
          }
        }
        header_image {
          localFile {
            childImageSharp {
              gatsbyImageData(width: 1600, placeholder: BLURRED)
            }
          }
          alt
        }
        header_custom_class {
          text
        }
        body {
          ...BlogFeedPageFragment
          ...ColumnsPageFragment
          ...CtaPageFragment
          ...DealCarouselPageFragment
          ...LogosPageFragment
          ...QuizPageFragment
          ...MetricsPageFragment
          ...TestimonialsPageFragment
          ...AccordionPageFragment
          ...CaseStudyPageFragment
          ...CapabilitiesPageFragment
          ...ImageAndTextPageFragment
          ...TilesPageFragment
          ...MainFormPageFragment
          ...TimelinePageFragment
          ...BlogTimelinePageFragment
          ...TextBlockPageFragment
          ...SearchbarPageFragment
        }
      }
    }
    prismicSettings {
      data {
        au_retail_page {
          uid
        }
        warning_statements {
          country
          statement {
            html
          }
          title {
            html
          }
          tooltip {
            html
          }
        }
      }
    }
  }
`;
