// extracted by mini-css-extract-plugin
export var button = "Aside-module--button--jKME4";
export var investLogin = "Aside-module--invest-login--4Z5Gk";
export var investOr = "Aside-module--invest-or--UwYRW";
export var loginOrContainer = "Aside-module--login-or-container--u8rG2";
export var offerRoomAside = "Aside-module--offer-room-aside--vIkt7";
export var offerRoomAsideContents = "Aside-module--offer-room-aside-contents--4xFey";
export var offerRoomAsideJoin = "Aside-module--offer-room-aside-join--d687i";
export var offerRoomAsideMessage = "Aside-module--offer-room-aside-message--odjhm";
export var offerRoomAsideRaised = "Aside-module--offer-room-aside-raised--59FMo";
export var primary = "#06004d";
export var secondary = "#4dafd7";
export var shareLinks = "Aside-module--share-links--Qs47+";
export var socialsWrapper = "Aside-module--socialsWrapper--Lb0ey";
export var sticky = "Aside-module--sticky--lwf6w";
export var viewingCounter = "Aside-module--viewing-counter--6qPGZ";