// extracted by mini-css-extract-plugin
export var iconOffWhite = "Logo-module--icon-off-white--bf3bR";
export var iconPrimary = "Logo-module--icon-primary--wr+X6";
export var iconSecondary = "Logo-module--icon-secondary--2GTNq";
export var iconTertiary = "Logo-module--icon-tertiary--bESIS";
export var iconWhite = "Logo-module--icon-white--vWB9b";
export var logo = "Logo-module--logo--WGo1q";
export var primary = "#06004d";
export var secondary = "#4dafd7";
export var secondaryDark = "Logo-module--secondary-dark--Opnbj";
export var secondaryLight = "Logo-module--secondary-light--5eOv2";
export var tertiaryDark = "Logo-module--tertiary-dark--j0cR7";
export var tertiaryLight = "Logo-module--tertiary-light--j6NSM";