// Pass in a number as either an int or string type
// Returns the string of the number in which 000 are seperated with commas
export const formatNumber = number => {
  // If the number is a string return that
  if (Number.isNaN(Math.round(number))) return number;
  return (
    (number || number === 0) &&
    Math.round(number)
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  );
};
