import Cookies from 'js-cookie';
import { AUTH_TOKEN } from '../utils/constants/cookieNames';

const defaultCookieExpiry = 365;

export const setCookie = ({ value, cookieName = AUTH_TOKEN, expiry = defaultCookieExpiry, path = '/' }) => {
  try {
    Cookies.set(cookieName, value, { expires: expiry, path, domain: process.env.DOMAIN });
  } catch (error) {
    console.error('Error setting cookie', error);
  }
};

export const getCookie = name => {
  try {
    const token = Cookies.get(name);
    return token;
  } catch (error) {
    console.error('Error getting cookie', error);
  }
};

export const destroy = (name = AUTH_TOKEN, path = '/') => {
  // remove cookie
  try {
    Cookies.remove(name, { path, domain: process.env.DOMAIN });
  } catch (error) {
    console.error('Error removing cookie', error);
  }
};
