import { gql } from '@apollo/client';

export const CREATE_DEAL_QUESTION_ANSWER = gql`
  mutation createDealQuestionAnswer($userId: Int!, $dealQuestionId: Int!, $answerInput: DealQuestionAnswerInput!) {
    createDealQuestionAnswer(userId: $userId, dealQuestionId: $dealQuestionId, answerInput: $answerInput) {
      id
    }
  }
`;

export const CREATE_DEAL_QUESTION = gql`
  mutation createDealQuestion($userId: Int!, $dealId: Int!, $questionInput: DealQuestionAnswerInput!) {
    createDealQuestion(userId: $userId, dealId: $dealId, questionInput: $questionInput) {
      id
      description
      title
      createdAt
      answer {
        id
      }
      author {
        id
      }
      deal {
        id
        name
      }
    }
  }
`;
export const UPDATE_DEAL_QUESTION_ANSWER = gql`
  mutation updateDealQuestionAnswer($answerId: Int!, $answerInput: DealQuestionAnswerInput!) {
    updateDealQuestionAnswer(answerId: $answerId, answerInput: $answerInput) {
      id
    }
  }
`;
