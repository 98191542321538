import { Link as GatsbyLink } from 'gatsby';
import React from 'react';

const investLinks = [
  'https://invest.equitise.com/login',
  'https://invest.equitise.com/register',
  'https://invest.equitise.com/dashboard',
  'https://invest-stg.equitise.com/login',
  'https://invest-stg.equitise.com/register',
  'https://invest-stg.equitise.com/dashboard',
];

const frontendLinks = [
  'https://equitise.com',
  'https://www.equitise.com',
  'https://v3-stg.equitise.com',
  'https://v3.equitise.com',
];

export const Link = props => {
  const { children, className, to, title, target = '_blank', onClick = () => {}, disableLink } = props;
  const isExternal = (to && to.indexOf('http') !== -1) || (to && to[0] === '#');
  const isInvestLink = to && investLinks.find(url => to.indexOf(url) !== -1);
  const isFrontendLink = !isInvestLink && to && frontendLinks.find(url => to.indexOf(url) !== -1);

  if (disableLink) {
    return (
      <div className={className || ''} title={title || ''}>
        {children}
      </div>
    );
  }

  if (isExternal && !isFrontendLink) {
    return (
      <a
        href={to}
        onClick={onClick}
        className={className || ''}
        title={title || ''}
        target={isInvestLink ? '_self' : target}
        rel="nofollow noopener noreferrer"
      >
        {children}
      </a>
    );
  }

  const linkTo = isFrontendLink ? to.replace(isFrontendLink, '') : to;

  return (
    <GatsbyLink to={linkTo || '/'} onClick={onClick} className={className || ''} title={title || null}>
      {children}
    </GatsbyLink>
  );
};
