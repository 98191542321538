// extracted by mini-css-extract-plugin
export var button = "DealTile-module--button--JlgnE";
export var dealTileActions = "DealTile-module--deal-tile-actions--M8Fvm";
export var dealTileCounter = "DealTile-module--deal-tile-counter--N4jMe";
export var dealTileMessage = "DealTile-module--deal-tile-message--blGO-";
export var dealTileStat = "DealTile-module--deal-tile-stat--ylBfE";
export var dealTileStats = "DealTile-module--deal-tile-stats--kcQdd";
export var dealTileStatus = "DealTile-module--deal-tile-status--DdlZu";
export var dealTileTooltip = "DealTile-module--deal-tile-tooltip--67LRv";
export var eoi = "DealTile-module--eoi--y8AbK";
export var heroTileDescription = "DealTile-module--hero-tile-description--NA1i3";
export var icon = "DealTile-module--icon--KqQKh";
export var iconInfoDark = "DealTile-module--icon-info-dark--Dt+GK";
export var investButtonContainer = "DealTile-module--invest-button-container--6tAjD";
export var offerRoomHeaderTimer = "DealTile-module--offer-room-header-timer--AdJxI";
export var pageTile = "DealTile-module--page-tile--weT0a";
export var primary = "#06004d";
export var progressBarTitle = "DealTile-module--progress-bar-title--sB+B8";
export var secondary = "#4dafd7";
export var tagline = "DealTile-module--tagline--qN3as";
export var tileDescription = "DealTile-module--tile-description--cpury";
export var timeRemaining = "DealTile-module--time-remaining--aNR9Z";