import React, { useState, useEffect, useContext } from 'react';
import { StaticQuery, graphql } from 'gatsby';
import { EmblaCarousel, Section } from 'components';
import { DealTile, CaseStudyTile } from 'components/Page';
import { GeoContext } from '../../context';
import { transformSnakeKeysToCamel } from '../../utils';
import * as styles from './styles.module.scss';

const offersQuery = graphql`
  query {
    ...WarningStatementsFragment
    ...DealSettingsFragment
    ...CaseStudiesSettingsFragment
  }
`;

export const DealCarousel = props => {
  const { data: carouselData } = props || {};
  const { primary } = carouselData || {};
  const { tagline, title, show, cta: carouselCta, cta_text: ctaText } = primary || {};
  // Pluck the filter (Prismic select field) selected by the content creator
  const [prismicFilter] = (show && show.split(' ')) || [];
  // Initialize a piece of state to filter the carousel items by this filter
  const [filter, setFilter] = useState(prismicFilter);

  // Get user's country of residence
  const { countryName: countryOfResidence } = useContext(GeoContext) || {};

  const Body = bodyProps => {
    const { settings, prismicSettings } = bodyProps;

    const { deal_tiles: dealTiles = [], case_study_tiles: caseStudyTiles } = prismicSettings?.data || {};
    const [filteredOffers, setFilteredOffers] = useState(dealTiles);

    useEffect(() => {
      const filterOffer = ({ deal }) => {
        const offerType = deal?.document?.data?.offer_type;
        return filter === 'All' || offerType === filter;
      };
      const localDealTiles = dealTiles.filter(({ deal }) => {
        if (!countryOfResidence) return true;
        const dealCountry = deal?.document?.data?.country;
        return dealCountry === countryOfResidence;
      });
      const filteredLocalTiles = localDealTiles?.filter(filterOffer);
      setFilteredOffers(filteredLocalTiles);
    }, [countryOfResidence, filter]);

    const { warning_statements: warningStatements } = settings?.data || {};

    const hasTiles = filteredOffers?.length > 0;

    const tabNamesArr = dealTiles.map(item => item.deal.document.data.offer_type).sort((a, b) => a.localeCompare(b));

    const tabNames = tabNamesArr.length > 1 ? ['All', ...new Set(tabNamesArr)] : [];

    const tabsProps = {
      tabNames,
      filter,
      startTab: 'All',
      selectTab: setFilter,
    };

    if (prismicFilter !== 'Case-studies' && !hasTiles) return;

    if (prismicFilter === 'Case-studies') {
      const caseStudySlides = caseStudyTiles.map(item => {
        const { data, id } = item?.case_study?.document || {};
        return <CaseStudyTile data={transformSnakeKeysToCamel(data)} key={id} route="blog" />;
      });

      return (
        <EmblaCarousel
          className="case-study-carousel"
          prismicFilter={prismicFilter}
          tabsProps={tabsProps}
          title={title}
          tagline={tagline}
          slides={caseStudySlides}
        />
      );
    }

    const dealSlides = filteredOffers.map(item => {
      const { deal, cta } = item || {};
      const { data, uid, prismicId } = deal?.document || {};
      return (
        <DealTile
          cta={cta}
          key={uid}
          prismicDealData={{
            ...transformSnakeKeysToCamel(data),
            prismicId,
            uid,
            warningStatements,
          }}
        />
      );
    });

    return (
      <Section className={styles.dealCarousel} noContainer spacing="n" sliceName="DealCarousel">
        <EmblaCarousel
          className="deal-tiles-carousel"
          inheritedClassname={styles.embla}
          prismicFilter={prismicFilter}
          tabsProps={tabsProps}
          title={title}
          tagline={tagline}
          slides={dealSlides}
          cta={carouselCta}
          ctaText={ctaText}
        />
      </Section>
    );
  };

  return <StaticQuery query={offersQuery} render={queryData => <Body {...queryData} />} />;
};
