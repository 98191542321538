import React, { useState, useEffect, useContext } from 'react';
import { truncateText } from 'utils';
import { Link } from 'components';
import { navigate } from 'gatsby';
import { defineQueries, algoliaKeys } from '../../utils/algoliaUtils';
import { SearchOverlay } from './SearchOverlay';
import { trackAmplitudeEvent } from '../../utils';
import { AuthContext, TrackEventContext } from '../../context';
import { userData } from '../../utils/userDataToAmplitude';
import * as styles from './SearchModal.module.scss';

export const SearchModal = ({ modalOpen, closeModal }) => {
  const [searchResults, setSearchResults] = useState([]);
  const [searchResultsMessage, setSearchResultsMessage] = useState(null);
  const [searchResultsCount, setSearchResultsCount] = useState(0);
  const [dealsSearchResults, setDealsSearchResults] = useState([]);
  const [blogsSearchResults, setBlogsSearchResults] = useState([]);
  const [searchInput, setSearchInput] = useState('');
  const [linkDestination, setLinkDestination] = useState('');
  const [isTablet, setIsTablet] = useState(false);
  const [bodyLocked, setBodyLock] = useState(false);
  const { resource: user } = useContext(AuthContext) || {};
  const { intercomAttributes, intercomEmail } = useContext(TrackEventContext) || {};

  // Algolia config
  const client = algoliaKeys();
  const queries = defineQueries(searchInput);

  // disable background scrolling ability when search modal is open
  const lockBody = () => {
    const scrollPosition = window.pageYOffset;
    document.body.style.top = `-${scrollPosition}px`;
    document.body.classList.add(styles.virtualKeyboardOpen);
    if (scrollPosition > 0) document.body.classList.add(styles.scrolledBeforeLock);
    setBodyLock(true);
  };

  // unlock disabled scrolling
  const unlockBody = () => {
    document.body.classList.remove(styles.virtualKeyboardOpen);
    document.body.classList.remove(styles.scrolledBeforeLock);
    document.body.style.top = '0px';
    setBodyLock(false);
  };

  useEffect(() => {
    if (modalOpen && !bodyLocked) lockBody();
    if (!modalOpen && bodyLocked) unlockBody();
    return () => {
      unlockBody();
    };
  }, [modalOpen]);

  // This useEffect fuction make a call to the Algolia API and updates search results with every input change
  useEffect(() => {
    const findHits = async () => {
      if (!searchInput || searchInput.length < 1) {
        return setSearchResults([]);
      }

      // API call that performs the queries - https://www.algolia.com/doc/api-reference/api-methods/multiple-queries/
      const algoliaResponse = await client.multipleQueries(queries);
      setDealsSearchResults(algoliaResponse?.results[0]?.hits);
      setBlogsSearchResults(algoliaResponse?.results[1]?.hits);
      setSearchResultsCount(
        algoliaResponse?.results[0]?.hits.filter(
          result =>
            result?.visibility !== 'Inactive' && result?.visibility !== 'Private' && result?.hideOnProduction !== true
        ).length + algoliaResponse?.results[1]?.hits?.length
      );
    };
    findHits();
  }, [searchInput]);

  const handleAllResultsLink = () => {
    try {
      trackAmplitudeEvent({
        email: user?.email || intercomEmail,
        eventName: `Clicked All Results Link`,
        eventData: {
          searchResult: searchResultsMessage,
          searchTerm: searchInput,
        },
        userData: user?.email ? userData(user) : intercomAttributes || {},
      });
    } catch (error) {
      console.error('Amplitude event clicked all search results failed');
    }
  };

  const handleSearchResultItem = item => {
    try {
      trackAmplitudeEvent({
        email: user?.email || intercomEmail,
        eventName: `Clicked Search Result`,
        eventData: {
          searchResultitem: item.title,
          searchResultType: item.resultType,
          searchResultMessage: searchResultsMessage,
          searchTerm: searchInput,
        },
        userData: {
          email: user?.email,
          state: user?.address?.state,
          subscribeToEmails: user?.subscribeToComms,
          sophisticatedStatus: user?.profile?.sophisticatedStatus?.name,
          telephone: user?.profile?.telephone,
          identityVerifiedAt: user?.identityVerifiedAt,
        },
      });
    } catch (error) {
      console.error('Amplitude event clicked search result failed');
    }
  };

  // This useEffect fuction formats deals search results, blogs search results, and results message to be displayed in the search modal
  useEffect(() => {
    // place all deals and blogs search results in this array back to back
    const combinedResults = [];
    combinedResults.push(
      dealsSearchResults
        .filter(result => result?.visibility === 'Public' && result?.hideOnProduction !== true)
        .map(result => ({
          title: result?.isEoi ? `${result?.companyName?.text} EOI` : `${result?.companyName?.text} Offer`,
          slug: result?.slug,
          tagline: result?.offerType,
          description: result?.tileDescription?.text,
          resultType: 'deal',
        }))
    );
    combinedResults.push(
      blogsSearchResults.map(result => ({
        title: result?.title.text,
        slug: result?.slug,
        tagline: result?.categories[0] ? result?.categories[0]?.category?.uid?.replaceAll('-', ' ') : 'news',
        description: result?.content?.text,
        resultType: 'blog',
      }))
    );

    // set search results message and link destination
    if (searchResultsCount === 0) {
      setSearchResultsMessage(`No results found for “${searchInput}”. Check out all live deals.`);
      setLinkDestination('/invest');
    } else if (searchResultsCount > 3) {
      setSearchResultsMessage(`See all ${searchResultsCount} results`);
      setLinkDestination(`/search-results/?search=${searchInput}`);
    }

    // display only three results in modal
    setSearchResults(combinedResults.flat().slice(0, 3));
  }, [searchResultsCount, searchInput]);

  // styling that sets results dropdown to not show on initial opening
  const displayNone = {
    display: searchResults.length >= 1 || (searchResults.length === 0 && searchInput.length > 0) ? 'block' : 'none',
  };

  // onClick function that clears the following states when the cross icon is clicked
  const clearInput = () => {
    setSearchInput('');
    setSearchResults([]);
    setSearchResultsCount(0);
  };

  // This function runs every time the cross icon is clicked to make sure all the states are cleared, whithout this function, we have to click the cross icon twice to clear the search results dropdown
  useEffect(() => {
    if (searchInput === '') {
      clearInput();
    }
  }, [searchResultsCount, searchInput]);

  // on cross button click, clear input and close modal
  const clickHandler = () => {
    clearInput();
    closeModal();
  };

  // go to search results when 'enter' key is pressed
  const handleKeyPress = event => {
    if (event.key === 'Enter') {
      navigate(linkDestination);
      closeModal();

      try {
        trackAmplitudeEvent({
          email: user?.email || intercomEmail,
          eventName: `Pressed Enter`,
          eventData: {
            searchResult: searchResultsMessage,
            searchTerm: searchInput,
          },
          userData: {
            email: user?.email,
            state: user?.address?.state,
            subscribeToEmails: user?.subscribeToComms,
            sophisticatedStatus: user?.profile?.sophisticatedStatus?.name,
            telephone: user?.profile?.telephone,
            identityVerifiedAt: user?.identityVerifiedAt,
          },
        });
      } catch (error) {
        console.error('Amplitude event clicked all search results failed');
      }
    }
  };

  // This useEffect determines whether the screen size represents a tablet
  useEffect(() => {
    window.addEventListener(
      'resize',
      () => {
        const isTab = window.innerWidth <= 768 && window.innerWidth >= 500;
        if (isTab !== isTablet) setIsTablet(isTab);
      },
      false
    );
  }, [isTablet]);

  return (
    <SearchOverlay active={modalOpen} closeModal={closeModal} className="search-bar-modal">
      {/* Search bar with icons */}
      <div className={styles.inputContainer}>
        <div className={styles.searchIconInputContainer}>
          <span className="icon icon-highlight-search"></span>

          <input
            type="text"
            onChange={e => setSearchInput(e.target.value)}
            onKeyPress={handleKeyPress}
            value={searchInput}
            placeholder="Search"
            className={styles.searchInput}
            autoFocus
          />
        </div>

        <span
          role="button"
          tabIndex={0}
          aria-label="delete search input"
          onClick={clickHandler}
          className={`icon icon-highlight-cross ${styles.iconHighlightCross}`}
        ></span>
      </div>

      {/* search results displayed as individual links */}
      <div style={displayNone}>
        <div className={`${styles.searchResultContainer} ${searchResults.length <= 0 && styles.noResult}`}>
          {searchResults.map(item => (
            <Link
              key={item.slug}
              to={item?.slug}
              className={`${styles.resultLink} ${isTablet && searchResults.length !== 1 && `${styles.isTabletSize}`}`}
            >
              <div
                onClick={() => handleSearchResultItem(item)}
                className={styles.searchResultItemContainer}
                aria-hidden="true"
              >
                <div className={styles.searchResultItemText}>
                  <p className={styles.resultTagline}>{item.tagline}</p>
                  <p className={styles.searchResultTitleStyles}>{truncateText(item.title, 25, '...')}</p>
                  <p className={styles.resultDescription}>{truncateText(item.description, 50, '...')}</p>
                </div>
              </div>
            </Link>
          ))}
        </div>

        {/* search results message link */}
        <div className={styles.searchResultItemContainerSearch}>
          <div className={styles.searchResultItemText}>
            <Link
              onKeyPress={handleKeyPress}
              onClick={closeModal}
              to={linkDestination}
              className={`${styles.showMore} ${styles.searchLink} ${searchResults.length <= 0 &&
                styles.noResultMargin}`}
            >
              <p onClick={() => handleAllResultsLink()}>{searchResultsMessage}</p>
            </Link>
          </div>
        </div>
      </div>
    </SearchOverlay>
  );
};
