// extracted by mini-css-extract-plugin
export var dealRoom = "TextBlock-module--deal-room--HdmQX";
export var legalPage = "TextBlock-module--legal-page--YaWki";
export var linked_image = "TextBlock-module--linked_image--rPoWx";
export var offerRoomContentPage = "TextBlock-module--offer-room-content-page--D7GvW";
export var offerRoomContentTitle = "TextBlock-module--offer-room-content-title--m9NHi";
export var page = "TextBlock-module--page--37HQr";
export var primary = "#06004d";
export var secondary = "#4dafd7";
export var textBlock = "TextBlock-module--text-block--RCek1";
export var textBlockImage = "TextBlock-module--text-block-image--2EEh1";
export var visible = "TextBlock-module--visible--iMWRA";