import React, { useContext, useState } from 'react';
import { gql, useQuery, useMutation } from '@apollo/client';
import { FormikForm, Link } from 'components';
import { TextArea } from '../FormikInputs';
import apolloClient from '../../gatsby-theme-apollo/client';
import { TrackEventContext } from '../../context';
import { trackAmplitudeEvent } from '../../utils/analytics';
import { VERIFY_IDENTITY } from '../../apis/routes';
import { CREATE_DEAL_QUESTION } from './QuestionAnswerMutations';

import * as styles from './QuestionForm.module.scss';

const QuestionForm = ({ companyName, dealId, refreshQuestions, user, setSubmitError, uid, dbDealData }) => {
  const creator = dbDealData?.creator;
  const memberRoles = dbDealData?.memberRoles;

  const directors = memberRoles.filter(member => member?.roleId === 5);

  const { trackIntercomEvent, intercomId } = useContext(TrackEventContext) || {};
  const [createDealQuestion] = useMutation(CREATE_DEAL_QUESTION);
  const { identityVerifiedAt } = user;
  const urlOfferLink = `https://equitise.com/offer/${uid}`;

  const validationFunction = values => {
    const { description } = values;

    if (description.trim() === '') return { description: 'Question field can not be empty' };
  };

  const onSubmit = async (values, { resetForm }) => {
    const trimmedEmail = user?.email.trim();

    try {
      await createDealQuestion({
        variables: {
          userId: user?.id,
          dealId,
          questionInput: {
            description: values.description,
          },
        },
      });

      // // Event attributed to asking user
      await trackIntercomEvent({
        intercomId,
        email: trimmedEmail,
        name: `${user?.firstName} ${user?.lastName}`,
        eventName: 'Asked Question',
        metadata: { offerName: companyName?.text },
      });

      // Event to intercom attributed to the creator of the deal, which sends a notification email
      await trackIntercomEvent({
        email: creator?.email,
        eventName: 'User Question Admin Email',
        metadata: {
          offerName: companyName?.text,
          userEmail: trimmedEmail,
          question: values?.description,
          urlOfferLink,
        },
        questionAsked: true,
      });

      await directors?.map(director =>
        trackIntercomEvent({
          email: director?.user?.email,
          eventName: 'User Question Admin Email',
          metadata: {
            offerName: companyName?.text,
            userEmail: trimmedEmail,
            question: values?.description,
            urlOfferLink,
          },
          questionAsked: true,
        })
      );

      await trackAmplitudeEvent({
        email: trimmedEmail,
        eventName: 'Asked Question',
        eventData: {
          offerName: companyName,
        },
        userData: {
          email: trimmedEmail,
          firstName: user?.firstName,
          lastName: user?.lastName,
          state: user?.address?.state,
          subscribeToEmails: user?.subscribeToComms,
          sophisticatedStatus: user?.profile?.sophisticatedStatus?.name,
          telephone: user?.profile?.telephone,
          identityVerifiedAt: user?.identityVerifiedAt,
        },
      });

      refreshQuestions();
      setSubmitError('');
      return resetForm();
    } catch (submitDealQuestionError) {
      console.error('onSubmit error failed for questionAnswer', { error: submitDealQuestionError });
      setSubmitError(submitDealQuestionError?.message);
    }
  };

  return (
    <div className="question-form-container">
      <h4>Want to know more? Ask a question</h4>
      {!identityVerifiedAt && (
        <p className={styles.questionError}>
          You have to be identified in order to ask a question.{' '}
          <Link to={VERIFY_IDENTITY} className={styles.dashboardLink}>
            Verify your identity.
          </Link>
        </p>
      )}
      {identityVerifiedAt && (
        <FormikForm
          initialValues={{ description: '' }}
          onSubmit={onSubmit}
          validationFunction={validationFunction}
          submitButtonText="Submit Question"
        >
          <TextArea name="description" placeholder={`Ask ${companyName?.text} a question about their raise`} />
        </FormikForm>
      )}
    </div>
  );
};

export default QuestionForm;
