import React, { useState, useEffect } from 'react';
import { LambdaClient } from '../../apis/local';

export const useCsvToJsonData = tableUrl => {
  const [tableData, setTableData] = useState(null);
  const fetchData = async url => {
    try {
      const res = await LambdaClient(`/csv-to-json?url=${url}`);
      setTableData(res.data);
    } catch (csvToJsonErr) {
      console.error('csv to json error', csvToJsonErr);
      // throw err;
    }
  };

  useEffect(() => {
    fetchData(tableUrl);
  }, []);

  return tableData;
};
