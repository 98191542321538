import React from 'react';
import { DealTile, CaseStudyTile } from 'components/Page';
import { transformSnakeKeysToCamel } from '../utils';
import { PostCard } from './BlogLandingPage/PostCard';

import * as styles from './Tile.module.scss';

export const Tile = props => {
  const { tile, warningStatements, cta, dealTileLocation, isLandingPage, isAllBlogPosts } = props;
  const { document } = tile || {};
  const { id, uid, prismicId, type: documentType, data: documentData } = document;

  const inheritedClassnames = {
    postCard: styles.postCard,
    postCardTitle: styles.postCardTitle,
    postCardImage: styles.postCardImage,
    postCardImageWrapper: styles.postCardImageWrapper,
    postCardInfo: styles.postCardInfo,
    postCardContent: styles.postCardContent,
  };

  if (documentType === 'deal_room')
    return (
      <DealTile
        prismicDealData={transformSnakeKeysToCamel(documentData)}
        key={prismicId}
        uid={uid}
        cta={cta?.toLowerCase()}
        prismicId={prismicId}
        route="offer"
        warningStatements={warningStatements}
        dealTileLocation={dealTileLocation}
      />
    );

  if (documentType === 'case_study')
    return (
      <CaseStudyTile
        data={transformSnakeKeysToCamel(documentData)}
        key={id}
        route="blog"
        tileLocation={dealTileLocation}
      />
    );

  if (documentType === 'blog_post')
    return (
      <PostCard
        post={tile}
        pathname="blog"
        inheritedClassnames={inheritedClassnames}
        tileLocation={dealTileLocation}
        isLandingPage={isLandingPage}
        isAllBlogPosts={isAllBlogPosts}
      />
    );

  return null;
};
