// extracted by mini-css-extract-plugin
export var imageRight = "styles-module--imageRight--JjQg2";
export var landingCtaImageTextContainerLeft = "styles-module--landing-cta-image-text-container-left--+Hwt2";
export var landingCtaImageTextContainerRight = "styles-module--landing-cta-image-text-container-right--9gOHc";
export var landingCtaImageTextDescriptionContainer = "styles-module--landing-cta-image-text-description-container--zUIZG";
export var landingCtaImageTextImage = "styles-module--landing-cta-image-text-image--B4Tur";
export var landingCtaImageTextImageContainer = "styles-module--landing-cta-image-text-image-container--+sV2s";
export var landingCtaImageTextWrapper = "styles-module--landing-cta-image-text-wrapper--R58pB";
export var primary = "#06004d";
export var secondary = "#4dafd7";
export var textDescription = "styles-module--textDescription--o-tL7";
export var title = "styles-module--title--m4m40";