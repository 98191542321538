import React, { useContext } from 'react';
import { BrandShape, Link } from 'components';
import { AuthContext, TrackEventContext } from '../../context';
import { capitaliseWord } from '../../utils';
import Section from '../Section';
import * as styles from './CategoryButtonSection.module.scss';

export const CategoryButtonSection = ({ pathname, categories }) => (
  <Section className={styles.categoryButtonSection} containerClassName={`${styles.wrapper} wrapper`}>
    <h3>Suggested Topics</h3>
    <div className={styles.categoryButtons}>
      {categories.map(uid => (
        <CategoryButton key={uid} pathname={pathname} uid={uid} />
      ))}
    </div>
  </Section>
);

const CategoryButton = ({ pathname, uid }) => {
  const { resource: user } = useContext(AuthContext) || {};
  const { trackIntercomEvent, intercomId } = useContext(TrackEventContext) || {};

  const onCategoryClick = () => {
    if (user?.email || intercomId)
      try {
        trackIntercomEvent({
          email: user?.email,
          eventName: 'Blog Category Button Clicked',
          metadata: { category: uid },
        });
      } catch (trackCategoryClickedErr) {
        console.error('Intercom event Blog Category Button Clicked failed', {
          error: trackCategoryClickedErr,
          category: uid,
        });
      }
  };

  return (
    // Note pathname must be different in production - i.e. LINK WORKS IN PRODUCTION BUT NOT LOCAL
    <Link to={`/${pathname}/${uid}`} className={styles.categoryButton} onClick={onCategoryClick}>
      <h5>{capitaliseWord(uid).replace(/-/g, ' ')}</h5>
      <BrandShape inheritedClassname={styles.brandShape} />
    </Link>
  );
};
