// extracted by mini-css-extract-plugin
export var date = "LatestPostsSection-module--date--wBSV+";
export var latest = "LatestPostsSection-module--latest--kGTcT";
export var latestPosts = "LatestPostsSection-module--latest-posts--qGyQp";
export var latestSection = "LatestPostsSection-module--latest-section--8NOa9";
export var postCard = "LatestPostsSection-module--post-card--czGQ2";
export var postCardContent = "LatestPostsSection-module--post-card-content--9lsWp";
export var postCardImage = "LatestPostsSection-module--post-card-image--GDI18";
export var postCardImageWrapper = "LatestPostsSection-module--post-card-image-wrapper--9DZ7B";
export var postCardInfo = "LatestPostsSection-module--post-card-info--ccJ8M";
export var postCardTitle = "LatestPostsSection-module--post-card-title--Ms7sg";
export var primary = "#06004d";
export var secondary = "#4dafd7";