import axios from 'axios';
import { LocalAPI } from './local';

export const requestDealByPrismicId = prismicId => axios(`${process.env.API_URL}/deal?prismic_id=${prismicId}`);

export const requestSubscribeEoi = values => LocalAPI.post('/eoi/subscribe', values);

export const requestAddDealQuestion = (dealId, values) => LocalAPI.post(`/deal/${dealId}/question`, values);

export const requestAnswerQuestion = (questionId, values) => LocalAPI.post(`/question/${questionId}/answer`, values);

export const requestEditAnswer = (answerId, values) => LocalAPI.put(`/answer/${answerId}`, values);

export const requestDealQuestions = dealId => LocalAPI(`/deal/${dealId}/question`);
