import React from 'react';
import { AuthProvider, GeoProvider, TrackEventProvider, UtmProvider } from './src/context';

import PreviewProvider from './src/components/PreviewProvider';

// This function updates the window object, previousPath can be accessed from anywhere.
export const onRouteUpdate = () => {
  window.locations = window.locations || [document.referrer];
  window.locations.push(window.location.pathname);
  window.previousPath = window.locations[window.locations.length - 2];
};

export const wrapRootElement = ({ element }) => (
  <GeoProvider>
    <AuthProvider>
      <UtmProvider>
        <TrackEventProvider>
          <PreviewProvider element={element} />
        </TrackEventProvider>
      </UtmProvider>
    </AuthProvider>
  </GeoProvider>
);
