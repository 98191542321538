import React from 'react';

export const DotButton = ({ selected, onClick, inheritedClassnames }) => {
  const validClassnames = inheritedClassnames || {};
  return (
    <button
      className={`${validClassnames.embla__dot || ''} ${
        selected && validClassnames.isSelected ? validClassnames.isSelected : ''
      }`}
      type="button"
      aria-label="dot"
      onClick={onClick}
    ></button>
  );
};

export const PrevButton = ({ enabled, onClick, inheritedClassnames }) => {
  const validClassnames = inheritedClassnames || {};
  return (
    <button
      className={`${validClassnames.embla__button || ''} ${validClassnames.embla__buttonPrev || ''}`}
      type="button"
      aria-label="prev"
      onClick={onClick}
      disabled={!enabled}
    ></button>
  );
};

export const NextButton = ({ enabled, onClick, inheritedClassnames }) => {
  const validClassnames = inheritedClassnames || {};
  return (
    <button
      className={`${validClassnames.embla__button || ''} ${validClassnames.embla__buttonNext || ''}`}
      type="button"
      aria-label="next"
      onClick={onClick}
      disabled={!enabled}
    ></button>
  );
};
