import { Image, Link, Section } from 'components';

import React from 'react';
import * as styles from './styles.module.scss';

export const CustomLogos = ({ data: { items } }) => (
  <Section as="div" className={styles.customLogos} noContainer spacing="n" sliceName="CustomLogos">
    {items.map(item => (
      <Logo data={item} />
    ))}
  </Section>
);

const Logo = ({
  data: {
    link: { url },
    logo,
  },
}) => (
  <Link to={url} className={styles.customLogo}>
    <Image image={logo} />
  </Link>
);
