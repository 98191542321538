import dayjs from 'dayjs';
import { AUSTRALIA } from './countryNames';

export const isArray = arrayToCheck => arrayToCheck && Array.isArray(arrayToCheck);

export const isFunction = functionToCheck =>
  functionToCheck && {}.toString.call(functionToCheck) === '[object Function]';

export const isObject = objectToCheck =>
  !isArray(objectToCheck) && !isFunction(objectToCheck) && objectToCheck === Object(objectToCheck);

export const removeVals = (values, valsToRemove) => {
  Object.keys(values).forEach(key => {
    const val = values[key];
    if (isObject(val)) return removeVals(val, valsToRemove);
    if (valsToRemove.includes(values[key])) delete values[key];
  });
};

export const transformFalsyValsToString = obj => {
  if (!obj) return '';
  const newObj = {};
  Object.keys(obj).forEach(key => {
    const val = obj[key];
    if (isObject(val)) newObj[key] = transformFalsyValsToString(val);
    else {
      newObj[key] = val || '';
    }
  });
  return newObj;
};

export const fetchAllResource = async (setResource, request, key = '') => {
  try {
    const result = await request();
    const { data } = result;
    if (key) return setResource(data[key]);
    return setResource(data.data ? data.data : data);
  } catch (error) {
    console.error(error);
    throw error;
  }
};

// Parses date string in format MMMM D YYYY HH:mm:ss and returns JavaScript date object
export const strToDate = dateStr => {
  if (!dateStr) return new Date();

  return new Date(dayjs(dateStr));
};

export const retailRouteMapping = { Australia: '/retail', 'New Zealand': '/retail-nz' };

export const countryToRoute = ({ country, auRetailUid, nzRetailUid }) =>
  country === AUSTRALIA ? auRetailUid : nzRetailUid;
