import React from 'react';
import { useField, useFormikContext } from 'formik';
import * as styles from './FormikInputs.module.scss';

const InputWrapper = ({ fieldType, hideError, ...props }) => {
  const {
    fieldclassname,
    id,
    name,
    label,
    labelclassname,
    select,
    reactSelect,
    children,
    mask,
    textArea,
    formikClassnames: inheritedClassnames,
  } = props;

  const validClassnames = inheritedClassnames || {};

  const selectProps = { ...props };

  delete selectProps.select;

  delete selectProps.reactSelect;

  delete selectProps.textArea;

  const [field, { touched, error, others }] = useField(props);

  const standardInput = !select && !mask && !reactSelect && !textArea;

  const displayError = !hideError && touched && error;

  // const fieldTypeProp = styles[fieldType + 'InputContainer'];

  return (
    <div className={`${fieldType || ''} field ${fieldclassname || ''}`}>
      {label && (
        <label className={`${styles[labelclassname] || ''}`} htmlFor={id || name}>
          {label}
        </label>
      )}
      {select && (
        <select {...field} {...selectProps}>
          {children}
        </select>
      )}
      {textArea && <textarea className={`${fieldType}-input`} {...field} {...selectProps} />}
      {standardInput && <input className={`${fieldType}-input`} {...field} {...props} />}
      {displayError ? (
        <div
          className={`${validClassnames.validationError || ''} ${validClassnames.active || ''} validation-error active`}
        >
          {error}
        </div>
      ) : (
        <div className={`${validClassnames.validationError || ''} validation-error`} />
      )}
    </div>
  );
};

export const TextInput = ({ type, ...props }) => <InputWrapper fieldType="text" type={type || 'text'} {...props} />;

export const TextArea = ({ rows = 5, cols = 33, ...props }) => (
  <InputWrapper fieldType="textarea" rows={rows} cols={cols} textArea {...props} />
);

export const DateInput = props => <InputWrapper fieldType="date" type="date" {...props} />;

export const NumberInput = props => <InputWrapper fieldType="number" type="number" {...props} />;

export const PositiveIntegerInput = props => {
  const { setFieldValue } = useFormikContext();
  const { name } = props;
  const onNumberInputChange = event => {
    const { value } = event.target;
    if (Number(value)) {
      setFieldValue(name, Math.round(value));
    }
  };
  const inputProps = { onChange: onNumberInputChange, min: 0, ...props };
  return <NumberInput {...inputProps} />;
};

export const SelectInput = props => <InputWrapper fieldType="select" as="select" select {...props} />;

export const CheckBoxRangeInput = props => {
  const { value } = props;
  const checkedFunction = inputValue => fieldValue => fieldValue.includes(inputValue);
  const onChangeFunction = inputValue => (setFieldValue, name, fieldValue) => {
    if (fieldValue.includes(inputValue)) {
      const nextValue = fieldValue.filter(val => val !== inputValue);
      setFieldValue(name, nextValue);
    } else {
      const inputArray = [inputValue];
      const nextValues = inputArray.concat(fieldValue);
      setFieldValue(name, nextValues);
    }
  };
  const checkBoxInputProps = {
    checkedFunction: checkedFunction(value),
    onChangeFunction: onChangeFunction(value),
    ...props,
  };
  return <CheckBoxInput {...checkBoxInputProps} />;
};

export const CheckBoxSingleInput = props => {
  const checkedFunction = fieldValue => fieldValue;
  const onChangeFunction = (setFieldValue, name, fieldValue) => {
    setFieldValue(name, !fieldValue);
  };

  const checkBoxInputProps = {
    checkedFunction,
    onChangeFunction,
    ...props,
  };
  return <CheckBoxInput {...checkBoxInputProps} />;
};

export const CheckBoxInput = ({ displayError, ...props }) => {
  const [field, { touched, error }] = useField(props);
  const { value: fieldValue } = field;
  const { setFieldValue } = useFormikContext();
  const {
    children,
    name,
    checkedFunction,
    onChangeFunction,
    formikClassnames: inheritedClassnames,
    fieldclassname,
  } = props;

  const validClassnames = inheritedClassnames || {};

  return (
    <label
      className={`${styles.checkboxContainer} ${styles[fieldclassname] || ''} ${validClassnames.checkboxContainer ||
        ''}`}
    >
      <input
        {...field}
        checked={checkedFunction(fieldValue)}
        onChange={() => onChangeFunction(setFieldValue, name, fieldValue)}
        type="checkbox"
        className={styles.checkboxInput}
      />
      <span className={styles.checkmark} />
      <span className={styles.checkboxLabel}>{children}</span>
      {displayError && touched && error ? (
        <div
          className={`${validClassnames.validationError || ''} ${validClassnames.active || ''} validation-error active`}
        >
          {error}
        </div>
      ) : (
        <div className={`${validClassnames.validationError || ''} validation-error`} />
      )}
    </label>
  );
};

export const TempPhoneInput = props => {
  const { name } = props;

  const { setFieldValue } = useFormikContext();

  const onPhoneNumberChange = event => {
    const phoneNumber = event?.target?.value;

    const strippedPhoneNumber = phoneNumber?.replace(/[^0-9-+]/g, '');

    return setFieldValue(name, strippedPhoneNumber);
  };

  const inputProps = { onChange: onPhoneNumberChange, onPaste: onPhoneNumberChange, ...props };

  return <TextInput {...inputProps} {...props} />;
};

export const FormikInputs = {
  TextInput,
  TextArea,
  DateInput,
  NumberInput,
  CheckBoxSingleInput,
  CheckBoxRangeInput,
  SelectInput,
  PositiveIntegerInput,
};
