import React, { useContext } from 'react';
// utility function to truncate text
import { truncateText } from 'utils';
// onvisible animation component
import OnVisible from 'react-on-visible';
// image component
import { Image } from 'components';
import * as timelineStyles from './TimelineComponent.module.scss';
// Icon component that will be used for the chevron sign
import { Icon } from './Icon';
import { userData } from '../utils/userDataToAmplitude';
import { trackAmplitudeEvent } from '../utils';
import { AuthContext, TrackEventContext } from '../context';

// card component that renders for each item in the items list
const TimelineComponent = props => {
  const { item } = props;

  const { resource: user } = useContext(AuthContext) || {};
  const { intercomAttributes, intercomEmail } = useContext(TrackEventContext) || {};

  const handleClick = link => {
    try {
      trackAmplitudeEvent({
        email: user?.email || intercomEmail,
        eventName: `Clicked Timeline Article Link`,
        eventData: {
          clickedArticle: link,
        },
        userData: user?.email ? userData(user) : intercomAttributes || {},
      });
    } catch (error) {
      console.error('Amplitude event clicked newsfeed link failed');
    }
  };

  return (
    <>
      <OnVisible visibleClassName="slide-in" className="animate">
        <section className={timelineStyles.container}>
          <Image className={timelineStyles.imageContainer} image={item.timeline_image} />
          <div className={timelineStyles.contents}>
            <p className={timelineStyles.tagline}>{item.timeline_tagline}</p>
            <p className={timelineStyles.title}>{truncateText(item.title.text, 100)}</p>
            <p className={timelineStyles.description}>{item.description.text}</p>
            {item.link_title.text && (
              <p className={timelineStyles.linkparagraph}>
                <a
                  className={timelineStyles.link}
                  href={item.link.url}
                  target="_blank"
                  rel="noreferrer"
                  onClick={() => handleClick(item.title.text)}
                >
                  {item.link_title.text} <Icon type="chevron_down" alt={item?.icon?.alt || item.title.text} />
                </a>
              </p>
            )}
          </div>
        </section>
      </OnVisible>
    </>
  );
};

export default TimelineComponent;
