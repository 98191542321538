import React, { useContext, useState, useEffect } from 'react';
import OnVisible from 'react-on-visible';
import { Tooltip } from 'react-tippy';
import { Image, Video } from 'components';
import { DealRoomCta, Timer } from '.';
import { DealContext } from '../../context/DealContext';
import { formatCurrency, IPO, refactoredFormatCurrency } from '../../utils';
import Section from '../Section';

import * as styles from './DealRoomHeader.module.scss';

export const DealRoomHeader = props => {
  const { prismicDealData, dbDealData, hasAgreedTerms, setHasAgreedTerms, hasClickedInvest } = useContext(DealContext);

  const {
    companyName,
    country,
    currency,
    endDate,
    headerDescription,
    hideRaisedAmount,
    hideTimer,
    isEoi,
    featuredImage,
    featuredVideo,
    leadManager,
    logo,
    offerType: type,
    pricePerShare,
    roundMaximum: maxInvestment,
    roundMinimum: minInvestment,
    terms,
    visibility,
    uid,
  } = prismicDealData;

  // latest douugh raise needs a specific type called 'ASX placement'
  const needsCustomType = uid === 'douugh-asx-placement-2022';

  const { metrics } = dbDealData || {};
  const { raisedAmount, isLive } = metrics || {};

  const showFeaturedImage = featuredImage?.localFile || featuredImage?.url;

  const showFeaturedVideo = featuredVideo?.url;

  const showLogo = logo?.localFile || logo?.url;

  const offerType = country === 'New Zealand' && type === 'Retail' ? `Retail and Wholesale` : type;

  const isWholesale = type === 'Wholesale';

  const showTermsTandC = Boolean(terms) && offerType === IPO;
  const showDealClosed = Boolean(metrics) && !isEoi && !isLive;
  const showDealRaised = Boolean(metrics) && !isEoi;

  // TODO look to fix this so currency in Prismic is NZD
  const currencyCode = currency === 'NZ' ? 'NZD' : currency;

  const inheritedClassnames = {
    investButtonContainer: styles.investButtonContainer,
    button: styles.button,
    investOr: styles.investOr,
    // investLoginContainer: styles.investLoginContainer,
    investLogin: styles.investLogin,
  };

  const checkboxValidation = Boolean(hasClickedInvest && !hasAgreedTerms);

  return (
    <Section className={styles.wrapperPrimary} containerClassName="wrapper" spacing="n">
      <OnVisible
        className={`${styles.offerRoomHeader} ${isEoi ? styles.eoiHeader : ''}`}
        visibleClassName={styles.visible}
        percent={20}
      >
        <div className={styles.offerRoomHeaderAside}>
          {(showFeaturedImage || showFeaturedVideo) &&
            (showFeaturedVideo ? (
              <Video src={featuredVideo.url} />
            ) : (
              <Image image={featuredImage} alt={featuredImage?.alt} />
            ))}
        </div>
        <div className={styles.offerRoomHeaderContent}>
          <div className={styles.offerRoomHeaderTop}>
            {showLogo && <Image image={logo} className={isEoi ? styles.eoiHeaderLogo : styles.offerRoomHeaderLogo} />}
            <div>
              <h1 className={styles.offerRoomHeaderTitle}>{companyName?.text}</h1>
              {isEoi ? (
                <div className={styles.eoiBadge}>
                  <Tooltip
                    title="We are currently completing our onboarding and due diligence processes on this business. The deal will be open for investment soon. Please register your interest to be notified of any updates."
                    interactive
                    position="bottom"
                    arrow
                    sticky
                    theme="light"
                    arrowSize="small"
                    popperOptions={{ preventOverflow: true }}
                  >
                    <DealRoomCta inheritedClassnames={inheritedClassnames} eoiTooltipText="Expression of Interest" />
                  </Tooltip>
                </div>
              ) : (
                <div className={styles.offerRoomHeaderBadges}>
                  <span className={`${styles.badge} badge`}>{visibility}</span>
                  <span className={`${styles.badge} badge`}>{type}</span>
                </div>
              )}
            </div>
          </div>
          {isEoi && (
            <div
              className={styles.offerRoomHeaderDescription}
              dangerouslySetInnerHTML={{ __html: headerDescription?.html }}
            />
          )}
          <div className={styles.offerRoomHeaderStats}>
            {isWholesale && (
              <span className={styles.offerRoomHeaderRaised}>Round Max: {formatCurrency(maxInvestment, currency)}</span>
            )}
            {showDealRaised && (
              <span className={styles.offerRoomHeaderRaised}>
                {!raisedAmount || hideRaisedAmount ? 'Offer Live' : `Raised ${formatCurrency(raisedAmount)}`}
              </span>
            )}
            {showDealClosed && <span className={styles.offerRoomHeaderRaised}>Deal Closed</span>}
            {!hideTimer && (
              <Timer inheritedClassname={styles.offerRoomHeaderTimer} endDate={endDate} prefix="Closes: " suffix="" />
            )}
          </div>
          {!isWholesale && (
            <ul className={styles.offerRoomType}>
              <li>Type: {needsCustomType ? 'ASX Placement' : offerType}</li>
              {(minInvestment || minInvestment === 0) && (
                <>
                  <li>Total Round Size (min): {formatCurrency(minInvestment, currency)}</li>
                  <li>Total Round Size (max): {formatCurrency(maxInvestment, currency)}</li>
                </>
              )}
              {pricePerShare && (
                <li>
                  Price per share: {currencyCode}${pricePerShare}
                </li>
              )}
              {leadManager?.text && <li>Lead manager: {leadManager.text}</li>}
            </ul>
          )}
          <DealRoomCta inheritedClassnames={inheritedClassnames} />
          {showTermsTandC && (
            <form className={styles.checkboxForm}>
              <input
                type="checkbox"
                value="hasAgreedTerms"
                name="t-and-c"
                onChange={() => setHasAgreedTerms(!hasAgreedTerms)}
                className={styles.checkboxInput}
              />
              <div className={styles.checkboxText} dangerouslySetInnerHTML={{ __html: terms.html }} />
              <div className={`${styles.validationError} ${checkboxValidation ? styles.active : ''}`}>Required</div>
            </form>
          )}
        </div>
      </OnVisible>
    </Section>
  );
};
