import React from 'react';
import { Button, Image, Link, Tagline, Section, EmblaCarousel } from 'components';
import * as styles from './styles.module.scss';
import { convertToCamelCase } from '../../utils';

export const Columns = props => {
  const { data, customClass: headerCustomClass, template } = props;
  const { primary, items } = data;
  const {
    tagline,
    title,
    content,
    action,
    action_link: actionLink,
    custom_class: customClass,
    information_link: { url: infoLinkUrl },
    information_link_subtext: { text: infoLinkText },
    icon,
    related_page_text: relatedPageText,
    related_page_cta: relatedPageCta,
    related_page_link: relatedPageUrl,
    hide_white_background: hideWhiteBackground,
  } = primary;

  const { html: relatedText } = relatedPageText || {};
  const { text: relatedCta } = relatedPageCta || {};
  const { url: relatedUrl } = relatedPageUrl || {};
  const sliderBreakpoints = {
    0: {
      items: 2,
    },
  };

  const slides = items && items.map(column => <Column key={column.title.text} {...column} />);

  const convertedHeaderCustomClass = convertToCamelCase(headerCustomClass.text);
  const convertedCustomClass = convertToCamelCase(customClass.text);

  const noBackground = hideWhiteBackground ? { background: 'none', boxShadow: 'none', padding: '40px 64px' } : {};

  return (
    <Section
      className={`${styles[template] || ''} ${styles.columnsSection} ${styles[convertedHeaderCustomClass] ||
        ''} ${styles[convertedCustomClass] || ''}`}
      containerClassName={styles.wrapper}
      sliceName="Columns"
    >
      <div className={styles.columnsBody} style={noBackground}>
        <div className={styles.columnsHeader}>
          <div className={styles.columnsTitleRow}>
            <div className={styles.columnsTitle}>
              <Tagline inheritedClassname={styles.tagline} tagline={tagline} />
              <h2>{title.text}</h2>
            </div>
            {action && action.text && (
              <Link to={actionLink.url} className={`${styles.button} button`}>
                {action.text}
              </Link>
            )}
          </div>
          {content && content.html && (
            <div className={styles.columnsDescription} dangerouslySetInnerHTML={{ __html: content.html }} />
          )}
        </div>
        <div className={styles.columnsGrid}>
          {items && items.map(column => <Column key={column.title.text} {...column} />)}
        </div>
        {infoLinkUrl && (
          <Link to={infoLinkUrl} className={styles.infoLink}>
            {/* <span className="icon icon-question-dark" /> */}
            <span className={styles.cta}>Want to know more?</span>&nbsp;
            <span className={styles.ctaSubText}>{infoLinkText}</span>
          </Link>
        )}
        <div className={styles.columnsCarousel}>
          <EmblaCarousel slides={slides} />
        </div>
      </div>
      {relatedText && relatedCta && relatedUrl && (
        <div className={styles.relatedPageSection}>
          <div className={styles.relatedPageImage}>
            <Image image={icon} inheritedClassname={styles.gatsbyImage} />
          </div>
          <div className={styles.relatedPageText} dangerouslySetInnerHTML={{ __html: relatedText }} />
          <div className={styles.relatedPageAction}>
            <Button to={relatedUrl} theme="highlight">
              {relatedCta}
            </Button>
          </div>
        </div>
      )}
    </Section>
  );
};

const Column = props => {
  const {
    title,
    subtitle,
    content,
    button,
    button_link: buttonLink,
    secondary_button: secondaryButton,
    secondary_button_link: secondaryButtonLink,
    column_icon: columnIcon,
  } = props;
  const showColumnIcon = columnIcon && (columnIcon.url || columnIcon.localFile);

  return (
    <div className={styles.columnGridItem}>
      {showColumnIcon && (
        <Image
          image={columnIcon}
          className={`${styles.columnIcon} column-icon`}
          inheritedClassname={styles.gatsbyImage}
        />
      )}
      <h2 className={styles.columnTitle}>{title.text}</h2>
      {subtitle && subtitle.html && (
        <div className={styles.columnSubtitle} dangerouslySetInnerHTML={{ __html: subtitle.html }} />
      )}
      {content && content.html && (
        <div className={styles.columnContent} dangerouslySetInnerHTML={{ __html: content.html }} />
      )}
      {button && button.text && (
        <div className={styles.columnActions}>
          {secondaryButton && secondaryButton.text && (
            <Link to={secondaryButtonLink.url} className="button">
              {secondaryButton.text}
            </Link>
          )}
          <Link to={buttonLink.url} className={`${styles.button} button ${styles.outline}`}>
            {button.text}
          </Link>
        </div>
      )}
    </div>
  );
};
