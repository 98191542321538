// extracted by mini-css-extract-plugin
export var minIndicator = "ProgressBar-module--min-indicator--Traol";
export var minIndicatorContainer = "ProgressBar-module--min-indicator-container--sFhN+";
export var minToMax = "ProgressBar-module--min-to-max--RUkuy";
export var primary = "#06004d";
export var progressBar = "ProgressBar-module--progress-bar--YPv1N";
export var progressBarItem = "ProgressBar-module--progress-bar-item--qlMyf";
export var progressBarItemFill = "ProgressBar-module--progress-bar-item-fill--KituD";
export var progressBarTitle = "ProgressBar-module--progress-bar-title--EeVTY";
export var progressSpanGrid = "ProgressBar-module--progress-span-grid--bSrJ8";
export var secondary = "ProgressBar-module--secondary--Uioco";
export var zeroToMin = "ProgressBar-module--zero-to-min--l9XII";