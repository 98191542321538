import React, { useContext } from 'react';
import OnVisible from 'react-on-visible';
import { Tooltip } from 'react-tippy';
import { DealContext } from '../../context/DealContext';
import '../../sass/modules/react-tippy.scss';
import './Content.scss';

export const Description = props => {
  const { prismicDealData } = useContext(DealContext);
  const { title, description, keyInformation } = prismicDealData;
  const { inheritedClassnames, template } = props;

  const validClassnames = inheritedClassnames ? inheritedClassnames : {};

  if (!title) return null;

  // TODO: Automate key information section by pulling live data from the API
  const sortKeyInformation = figures => {
    const toolTipFigures = figures.filter(figure => !!figure.tooltip?.text);
    const otherFigures = figures.filter(figure => !figure.tooltip?.text);
    return [...toolTipFigures, ...otherFigures];
  };

  const renderKeyInformation = figures =>
    sortKeyInformation(figures).map(figure => {
      const {
        field_name: label,
        number_value: val,
        text_value: { text = '' },
        tooltip,
      } = figure;
      return (
        <li key={label.text}>
          <Tooltip
            title={tooltip.text}
            position="bottom-start"
            className={`offer-room-key-point ${tooltip.text && `tooltip ${validClassnames.tooltip}`} ${validClassnames.offerRoomKeyPoint || ''}`}
            arrow
          >
            {label.text}
          </Tooltip>
          {`: `}
          {text || val}
        </li>
      );
    });

  return (
    <>
      <OnVisible className={`${validClassnames.offerRoomContentBlock || ''} offer-room-content-block text-block`} visibleClassName={validClassnames.visible} id="offer" percent={20}>
        <h2 className="offer-room-content-title">{title?.text}</h2>
        <div dangerouslySetInnerHTML={{ __html: description?.html }} />
        <ul>{renderKeyInformation(keyInformation)}</ul>
      </OnVisible>
    </>
  );
};
