// extracted by mini-css-extract-plugin
export var hamburger = "HamburgerNav-module--hamburger--40mAx";
export var hamburgerBg = "HamburgerNav-module--hamburger-bg--vW2t3";
export var hamburgerIcons = "HamburgerNav-module--hamburger-icons--HQ4-U";
export var hamburgerNav = "HamburgerNav-module--hamburger-nav--aJiop";
export var hamburgerNavOpen = "HamburgerNav-module--hamburger-nav-open--vnjPf";
export var header = "HamburgerNav-module--header--vxHqG";
export var logo = "HamburgerNav-module--logo--8SPeh";
export var mobileSearchBar = "HamburgerNav-module--mobile-search-bar--HgzvG";
export var primary = "#06004d";
export var secondary = "#4dafd7";
export var syndicateHamburgerBg = "HamburgerNav-module--syndicate-hamburger-bg--Wovhx";
export var syndicateHamburgerIcons = "HamburgerNav-module--syndicateHamburgerIcons--yC9TX";