import React, { useContext } from 'react';
import OnVisible from 'react-on-visible';
import { Image, Section } from 'components';
import * as styles from './styles.module.scss';
import { LandingPageContext } from '../../context';

export const CtaImageText = ({ data: { id, slice_type: sliceType, items: imageTextItems } }) => {
  const { primaryColour } = useContext(LandingPageContext) || {};
  const titleTextColour = {
    color: `${primaryColour}`,
  };

  return (
    <OnVisible percent={20} visibleClassName={styles.visible}>
      <Section noContainer sliceName="Team" className={styles.landingCtaImageTextWrapper}>
        {imageTextItems.map(item => {
          const layout =
            item?.image_left_or_right === 'left'
              ? styles.landingCtaImageTextContainerLeft
              : styles.landingCtaImageTextContainerRight;

          const rightImage = item?.image_left_or_right === 'left' ? styles.imageLeft : styles.imageRight;

          return (
            <div className={layout}>
              <div className={`${styles.landingCtaImageTextImageContainer} ${rightImage}`}>
                <Image className={styles.landingCtaImageTextImage} image={item?.image} alt={item.alt || ''} />
              </div>
              <div className={styles.landingCtaImageTextDescriptionContainer}>
                <h1 className={styles.title} style={titleTextColour}>
                  {item?.image_text_title?.text}
                </h1>
                <div
                  className={`${styles.textDescription} ${rightImage}`}
                  dangerouslySetInnerHTML={{ __html: item?.text_description?.html }}
                />
              </div>
            </div>
          );
        })}
      </Section>
    </OnVisible>
  );
};
