// extracted by mini-css-extract-plugin
export var close = "Modal-module--close--VYOcW";
export var customTitle = "Modal-module--custom-title--aQrfT";
export var eoi = "Modal-module--eoi--ggOYB";
export var ipoModalContent = "Modal-module--ipo-modal-content--B0SWl";
export var modal = "Modal-module--modal--h6Y06";
export var modalContent = "Modal-module--modal-content--ZzbDo";
export var modalContentBody = "Modal-module--modal-content-body--VBDz1";
export var modalContentTitle = "Modal-module--modal-content-title--HsFaf";
export var modalIcon = "Modal-module--modal-icon--9YqQs";
export var modalLogo = "Modal-module--modal-logo--zH+xm";
export var modalOpen = "Modal-module--modal-open--Ujf2O";
export var modalTitle = "Modal-module--modal-title--0gkej";
export var modalTitleContainer = "Modal-module--modal-title-container--FjfWw";
export var primary = "#06004d";
export var secondary = "#4dafd7";