import React from 'react';
import * as styles from './Arrow.module.scss';

export const Arrow = ({ inheritedClassnames }) => {
  const validClassnames = inheritedClassnames || {};
  return (
    <svg
      className={`${styles.arrowIcon} ${validClassnames.arrowIcon || ''} arrow-icon`}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 32 32"
    >
      <g
        fill="none"
        className={`${styles.arrowIconFill} ${validClassnames.arrowIconFill || ''} arrow-icon-fill`}
        strokeWidth="1.5"
        strokeLinejoin="round"
        strokeMiterlimit="10"
      >
        <circle
          className={`${styles.arrowIconCircle} ${validClassnames.arrowIconCircle || ''} arrow-icon--circle`}
          cx="16"
          cy="16"
          r="15.12"
        />
        <path
          className={`${styles.arrowIconArrow} arrow-icon--arrow`}
          d="M16.14 9.93L22.21 16l-6.07 6.07M8.23 16h13.98"
        />
      </g>
    </svg>
  );
};
