import React from 'react';
import { MetricCard, SliceHeader } from 'components/Page';
import { Image, Section } from 'components';
import { transformSnakeKeysToCamel } from '../../utils';

import * as styles from './styles.module.scss';

const MetricCards = ({ style, items }) => (
  <div className={style}>
    {items.map(item => (
      <MetricCard
        label={item?.label?.text}
        prefix={item?.prefix?.text}
        number={item?.number}
        suffix={item?.suffix?.text}
        icon={item?.icon}
        key={item?.label?.text}
        decimals={item?.number % 1 !== 0 ? 1 : 0}
        duration={2}
        style={styles.metricCard}
      />
    ))}
  </div>
);

// This component is when marketing etc want to show only metrics
const MetricsOnly = props => {
  const { items, tagline, title } = props;

  return (
    <Section className={styles.metricsOnlySection} containerClassName={styles.metricsOnlyContainer} spacing="s">
      <SliceHeader className={styles.pageSliceHeader} tagline={tagline} title={title} />
      {items.length < 5 ? (
        <MetricCards style={`${styles.metricOnlyCards} ${styles.metricOnlyCardsFourGrid}`} items={items} />
      ) : (
        <MetricCards style={styles.metricOnlyCards} items={items} />
      )}
    </Section>
  );
};

// Metrics & Logo Slice - This component is used to render the Logos added on prismic, the logic checks for either 2 or 4 logos and renders styling for either instance
const Logos = ({ logos }) => (
  <div className={styles.logosContainer}>
    {logos.map(logo => (
      <div className={styles.logo}>
        <Image image={logo} inheritedClassname={styles.metricLogo} objectFit="contain" alt={logo?.alt || 'Logo'} />
      </div>
    ))}
  </div>
);

// Metrics & Logo Slice - This combines all components and styling of Metrics & Logo Slice for easier readability at final return statement
const MetricsAndLogos = ({
  metricOneNumber,
  metricOnePrefix,
  metricOneSuffix,
  metricOneTitle,
  metricTwoNumber,
  metricTwoPrefix,
  metricTwoSuffix,
  metricTwoTitle,
  metricThreeNumber,
  metricThreePrefix,
  metricThreeSuffix,
  metricThreeTitle,
  metricFourNumber,
  metricFourPrefix,
  metricFourSuffix,
  metricFourTitle,
  logoOne,
  logoTwo,
  logoThree,
  logoFour,
  tagline,
  title,
  titleDark,
}) => {
  // Metrics & Logo Slice - Prismic variables put into an array of objects for easier mapping, these are only metrics 1,2,3
  const metricsObject = [
    { num: metricOneNumber, pre: metricOnePrefix, suf: metricOneSuffix, tit: metricOneTitle },
    { num: metricTwoNumber, pre: metricTwoPrefix, suf: metricTwoSuffix, tit: metricTwoTitle },
    { num: metricThreeNumber, pre: metricThreePrefix, suf: metricThreeSuffix, tit: metricThreeTitle },
  ];

  const logos = [logoOne, logoTwo, logoThree, logoFour];

  // Metrics & Logo Slice - Set Title colour for slice
  const titleColor = titleDark ? 'light' : 'dark';

  return (
    <>
      <div className={styles.titleContainer}>
        <span className={styles.metricsTagLine}>{tagline.text}</span>
        <span className={`${styles.metricsTitle} ${styles[titleColor]}`}>{title.text}</span>
      </div>
      <section className={styles.metricsAndLogosContainer}>
        <div className={`${styles.metricsContainer} ${styles.leftContainer}`}>
          {metricsObject.map(metric => (
            <MetricCard
              prefix={metric.pre.text}
              number={metric.num}
              suffix={metric.suf?.text}
              decimals={metric.num % 1 !== 0 ? 1 : 0}
              duration={2}
              title={metric.tit.text}
            />
          ))}
        </div>
        <div className={`${styles.metricsContainer} ${styles.rightContainer}`}>
          <MetricCard
            prefix={metricFourPrefix.text}
            number={metricFourNumber}
            suffix={metricFourSuffix.text}
            decimals={metricFourNumber % 1 !== 0 ? 1 : 0}
            duration={2}
            title={metricFourTitle.text}
          />
          <Logos logos={logos} />
        </div>
      </section>
    </>
  );
};

export const Metrics = props => {
  const { data } = transformSnakeKeysToCamel(props);
  const { primary, items } = data;
  const { tagline, title, logoOne } = primary;

  return (
    <>
      <Section noContainer className={styles.metricsWrapper} sliceName="Metrics">
        {(logoOne.url && <MetricsAndLogos {...primary} />) || (
          <MetricsOnly items={items} tagline={tagline} title={title} />
        )}
      </Section>
    </>
  );
};
