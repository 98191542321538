// extracted by mini-css-extract-plugin
export var gatsbyImage = "styles-module--gatsby-image--0g1VK";
export var primary = "#06004d";
export var quizElement = "styles-module--quiz-element--5+80t";
export var quizElementGrid = "styles-module--quiz-element-grid--6xNa4";
export var quizElementHeader = "styles-module--quiz-element-header--EEy2Z";
export var quizElementHeaderTitle = "styles-module--quiz-element-header-title--ABCIr";
export var quizElementItem = "styles-module--quiz-element-item--FmBHG";
export var quizElementTitle = "styles-module--quiz-element-title--7Pdpd";
export var secondary = "#4dafd7";
export var wrapper = "styles-module--wrapper--UeWSB";