import React, { createContext, useState } from 'react';
import { useQuery } from '@apollo/client';
import apolloClient from '../gatsby-theme-apollo/client';
import { GET_OWN_USER } from './AuthContextQueries';
import { getCookie } from '../apis';
import { AUTH_TOKEN } from '../utils/constants/cookieNames';

export const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
  const [resource, setResource] = useState(null);

  const retrievedToken = getCookie(AUTH_TOKEN);

  // Query own user
  const { data: ownUserData, loading, error, refetch } = useQuery(GET_OWN_USER, {
    client: apolloClient,
  });

  // Function that is passed to header for logout purposes
  // First destroys cookie token, then resets resource state and finally destroys token in back end
  const clearResource = async () => {
    try {
      // Resets resource state
      setResource(null);
    } catch (clearResourceError) {
      console.error('Clear resource failed', { error: clearResourceError });
    }
  };

  const refetchUserData = async () => {
    try {
      const { data: refetchedOwnUserData, refetchedLoading, refetchedError } = await refetch();
      const refetchedUserData = refetchedOwnUserData?.ownUser;
      if (retrievedToken && !refetchedLoading && !refetchedError) {
        setResource(refetchedUserData);
        if (authData.resource) {
          authData.resource = refetchedUserData;
        }
      }
    } catch (refetchUserDataError) {
      console.error('Refetch user data failed', refetchUserDataError);
    }
  };

  // Logic for setting the resource state as the retrieved user data
  if (retrievedToken && !loading && !error && !resource) {
    const userData = ownUserData?.ownUser;
    setResource(userData);
  }

  // auth data object provided to auth context provider
  const authData = {
    resource,
    error: error?.message || '',
    loading: loading || '',
    refreshRequest: refetchUserData || '',
    clearResource,
  };

  return <AuthContext.Provider value={authData}>{children}</AuthContext.Provider>;
};

AuthContext.displayName = 'AuthContext';
