import React, { useState, useContext, useEffect } from 'react';
import { Link } from 'components';
import { SearchComponent } from './SearchComponent/SearchComponent';
import { trackAmplitudeEvent } from '../utils';
import { userData } from '../utils/userDataToAmplitude';
import { AuthContext, TrackEventContext } from '../context';

export const Navigation = props => {
  const {
    classNamePrefix = 'main',
    links,
    actions,
    includeSearchIcon,
    inheritedClassname,
    navigationClassnames: inheritedClassnames,
    isSyndicateDeal,
  } = props;
  const [showSublinks, setShowSublinks] = useState(0); // mobile sublink nav appears

  const { resource: user } = useContext(AuthContext) || {};

  const { intercomAttributes, intercomEmail } = useContext(TrackEventContext) || {};

  const className = classNamePrefix ? `${classNamePrefix}-navigation` : 'navigation';

  const validClassnames = inheritedClassnames || {};

  const noOp = () => {};

  const toggleSublinkNav = (event, activeIndex) => {
    // setting toggle state for hamburger sublinks
    event.preventDefault();
    if (activeIndex === showSublinks) {
      setShowSublinks(false);
    } else {
      setShowSublinks(activeIndex);
    }
  };

  const [currentUrl, setCurrentUrl] = useState('');

  useEffect(() => {
    setCurrentUrl(window.location.pathname);
  }, []);

  const handlePageLinkClick = async link => {
    try {
      trackAmplitudeEvent({
        email: user?.email || intercomEmail,
        eventName: `Clicked Navbar Link`,
        eventData: {
          visitedLink: link,
          previousLink: currentUrl,
        },
        userData: user?.email ? userData(user) : intercomAttributes || {},
      });
    } catch (error) {
      console.error('Failed to send navigation link event');
    }
  };

  return (
    <nav className={`${className} ${inheritedClassname || ''}`}>
      {includeSearchIcon && <SearchComponent isOnNavbar isSyndicateDeal={isSyndicateDeal} />}
      {links &&
        links.map((link, index) => {
          const hasSublinks = link.sublinks;
          const showChevron = hasSublinks && validClassnames.showChevron ? validClassnames.showChevron : '';
          const sublinkNavOpen =
            showSublinks === index && validClassnames.showSublinks ? validClassnames.showSublinks : '';

          const containerClassName = `${validClassnames.item || ''} ${showChevron} ${sublinkNavOpen}`;
          const noop = () => {};

          return (
            <div className={containerClassName} key={link.text}>
              <Link
                key={link.text}
                className={`${validClassnames.link || ''} ${link.className || ''}`}
                to={link.to}
                onClick={event => toggleSublinkNav(event, index)}
              >
                {link.text}
              </Link>

              {hasSublinks && (
                <Link
                  to="#togglenav"
                  className={`${validClassnames.toggle || ''}`}
                  onClick={event => toggleSublinkNav(event, index)}
                >
                  <span className={`${validClassnames.toggleChevron || ''}`}>Open</span>
                </Link>
              )}

              {hasSublinks && (
                <div className={`${validClassnames.subnav || ''}`}>
                  {link.sublinks.map(sublink => (
                    <Link key={sublink.text} to={sublink.to} className={`${validClassnames.subnavLink || ''}`}>
                      <p onClick={() => handlePageLinkClick(sublink.text)}>{sublink.text}</p>
                    </Link>
                  ))}
                </div>
              )}
            </div>
          );
        })}
      {actions && (
        <div
          className={`${validClassnames.actions || ''} ${isSyndicateDeal && validClassnames.isSyndicateDealActions}`}
        >
          {actions.map(link => (
            <Link key={link.text} to={link.to} className={link.className} onClick={link.onClick || noOp}>
              {link.text}
            </Link>
          ))}
        </div>
      )}
    </nav>
  );
};
