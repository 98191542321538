import React, { useContext } from 'react';
import { Image, Link } from 'components';
import { truncateText } from 'utils';
import { Tooltip } from 'react-tippy';
import { trackAmplitudeEvent } from '../../../utils';
import { AuthContext, TrackEventContext } from '../../../context';
import { userData } from '../../../utils/userDataToAmplitude';
import * as styles from './PageTile.module.scss';
import silverFern from '../../../images/silver-fern.png';
import ausMap from '../../../images/au-map.png';

export const PageTile = props => {
  const {
    badge,
    children,
    description,
    header,
    link,
    imageTag,
    logo,
    shouldLink = true,
    tagline,
    tileImage,
    justLanchedBanner,
    closingSoonBanner,
    isMaxedOut,
    inheritedClassname,
    inheritedClassnames,
    nzDeal,
    tileType,
    country,
  } = props;

  const auDeal = country === 'Australia';
  const showBottomBanner = isMaxedOut || justLanchedBanner || nzDeal || closingSoonBanner || auDeal;
  const { resource: user } = useContext(AuthContext) || {};
  const { intercomAttributes, intercomEmail } = useContext(TrackEventContext) || {};
  const bannerIsNzDeal = nzDeal && !justLanchedBanner && !closingSoonBanner && !isMaxedOut;
  const bannerIsAuDeal = auDeal && !justLanchedBanner && !closingSoonBanner && !isMaxedOut;

  let banner = '';
  switch (showBottomBanner) {
    case isMaxedOut:
      banner = 'MAX TARGET REACHED';
      break;
    case justLanchedBanner:
      banner = 'JUST LAUNCHED';
      break;
    case closingSoonBanner:
      banner = 'CLOSING SOON';
      break;
    case nzDeal:
      banner = 'NZ Deal';
      break;
    case auDeal:
      banner = 'AU Deal';
      break;
    default:
      return '';
  }

  const handleClick = tileLink => {
    try {
      trackAmplitudeEvent({
        email: user?.email || intercomEmail,
        eventName: `Clicked ${tileType} Link`,
        eventData: {
          tileLink,
        },
        userData: user?.email ? userData(user) : intercomAttributes || {},
      });
    } catch (error) {
      console.error('Amplitude event Clicked Tile Link failed');
    }
  };

  const BannerText = () => {
    if ((nzDeal || auDeal) && !justLanchedBanner && !closingSoonBanner && !isMaxedOut) {
      return (
        <div className={styles.bannerContent}>
          {nzDeal && <img src={silverFern} alt="silver fern" className={styles.silverFern}></img>}
          {auDeal && <img src={ausMap} alt="aus map" className={styles.auIcon}></img>}
          {nzDeal ? (
            <Tooltip
              title="To invest in this deal, you have to be a New Zealand resident, or an Australian Sophisticated Investor"
              interactive
              position="top"
              arrow
              sticky
              theme="light"
              arrowSize="small"
              popperOptions={{ preventOverflow: true }}
            >
              {banner}
            </Tooltip>
          ) : (
            <p className={styles.auTooltipBannerText}>AU DEAL</p>
          )}
        </div>
      );
    }

    return banner;
  };

  return (
    <div className={`${styles.pageTile} ${inheritedClassname || ''}`}>
      <Link onClick={() => handleClick(header)} to={link} className={styles.pageTileImage} disableLink={!shouldLink}>
        <Image image={tileImage} />
        {imageTag}
      </Link>
      {showBottomBanner && (
        <div
          className={`${styles.pageTileBanner} ${bannerIsNzDeal ? styles.nz : ''} ${bannerIsAuDeal ? styles.au : ''}`}
        >
          <BannerText />
        </div>
      )}
      <div className={styles.pageTileHeader}>
        {tagline}
        {Boolean(header) && (
          <Link onClick={() => handleClick(header)} to={link} disableLink={!shouldLink}>
            <h4>{truncateText(header, 20, '...')}</h4>
          </Link>
        )}
        {Boolean(logo) && (
          <Link onClick={() => handleClick(header)} to={link} className={styles.pageTileLogo} disableLink={!shouldLink}>
            <Image image={logo} />
          </Link>
        )}
      </div>
      <div className={`${styles.pageTileBody} ${inheritedClassnames?.pageTileBody || ''}`}>
        {Boolean(description) && <p className={styles.pageTileDescription}>{truncateText(description, 70, '...')}</p>}
        {children}
      </div>
      {badge}
    </div>
  );
};
