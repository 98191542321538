import React, { useState, useContext } from 'react';
import { trackAmplitudeEvent } from '../../utils';
import { AuthContext, TrackEventContext } from '../../context';
import { userData } from '../../utils/userDataToAmplitude';
import * as styles from './Tabs.module.scss';

export const Tabs = ({ selectTab, tabNames, startTab, inheritedClassname, itemClassName, tabEventType }) => {
  const [active, setActive] = useState(null);
  const { resource: user } = useContext(AuthContext) || {};
  const { intercomAttributes, intercomEmail } = useContext(TrackEventContext) || {};

  if (!tabNames || tabNames.length < 1) return null;

  if (!active && startTab) setActive(startTab);

  const onTabClick = (event, name) => {
    event.preventDefault();
    setActive(name);
    selectTab(name);

    try {
      trackAmplitudeEvent({
        email: user?.email || intercomEmail,
        eventName: `Clicked Tab`,
        eventData: {
          accordionType: tabEventType,
          itemTitle: name,
        },
        userData: user?.email ? userData(user) : intercomAttributes || {},
      });
    } catch (error) {
      console.error('Amplitude event clicked tab failed');
    }
  };

  return (
    <div className={`${inheritedClassname || ''} ${styles.tabs}`}>
      {tabNames.map(name => (
        <a
          className={`${styles.tab} ${active === name ? styles.active : ''}`}
          onClick={event => onTabClick(event, name)}
          href={`#${name}`}
          key={name}
        >
          {name}
        </a>
      ))}
    </div>
  );
};
