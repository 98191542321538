import React from 'react';
// Npm package that formats date
import moment from 'moment';
import { Section } from 'components';
import TimelineComponent from '../../components/TimelineComponent';
import * as styles from './styles.module.scss';

// Timeline component that is rendered in the newsfeed page
export const Timeline = props => {
  const {
    data: { items },
  } = props;

  // This variable is defined to specify which grid position each card element in the list will be placed in
  let row = 1;
  return (
    <Section className={styles.container} containerClassName={styles.wrapper} sliceName="Timeline" spacing="n">
      {items.map(item => {
        row += 1;
        return (
          <div className={styles.child} style={{ gridRowStart: row - 1, gridRowEnd: row + 1 }}>
            <div className={styles.circle}>
              <div className={styles.iconDashContainer}>
                <span className={styles.iconDash} />
              </div>
              <span
                // Dynamically setting icons, relative to the element's tagline
                className={
                  (item.timeline_tagline === 'success story' && 'icon icon-rocket') ||
                  (item.timeline_tagline === 'news' && 'icon icon-info') ||
                  (item.timeline_tagline === 'deal' && 'icon icon-money')
                }
              ></span>
            </div>
            {item.date ? (
              <p className={styles.date}>{item.date && moment(item.date).format('MMMM Do')}</p>
            ) : (
              <p className={styles.emptyParagraph}></p>
            )}
            <div className={styles.card}>
              <TimelineComponent item={item} />
            </div>
          </div>
        );
      })}
    </Section>
  );
};
