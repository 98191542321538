// extracted by mini-css-extract-plugin
export var containerImage = "styles-module--containerImage--Crvha";
export var isNotVersionOne = "styles-module--isNotVersionOne--7O9sR";
export var landingCtaFormBodyHeadline = "styles-module--landing-cta-form-body-headline--yI7U5";
export var landingCtaFormBox = "styles-module--landing-cta-form-box--fV-FE";
export var landingCtaFormContainer = "styles-module--landing-cta-form-container--kMWZE";
export var landingCtaFormDescription = "styles-module--landing-cta-form-description--lfZ41";
export var landingCtaFormFormLinkContainer = "styles-module--landing-cta-form-form-link-container--2GokX";
export var landingCtaFormFormLinkContainerUnderHeadline = "styles-module--landingCtaFormFormLinkContainerUnderHeadline--TNzdy";
export var landingCtaFormLogo = "styles-module--landing-cta-form-logo--QH11i";
export var landingCtaFormTextLogoContainer = "styles-module--landing-cta-form-text-logo-container--N4iwB";
export var primary = "#06004d";
export var secondary = "#4dafd7";