import algoliasearch from 'algoliasearch/lite';

export const algoliaKeys = () => {
  // Algolia settings
  const client = algoliasearch(process.env.GATSBY_ALGOLIA_APP_ID, process.env.GATSBY_ALGOLIA_API_KEY);
  return client;
};

export const defineQueries = input => {
  // defining queries that will be requested from Algolio - https://www.algolia.com/doc/api-reference/api-methods/multiple-queries/
  const queries = [
    {
      indexName: 'Deals',
      query: input,
      params: {
        hitsPerPage: 500,
      },
    },
    {
      indexName: 'Blogs',
      query: input,
      params: {
        hitsPerPage: 500,
      },
    },
  ];
  return queries;
};
