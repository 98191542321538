// extracted by mini-css-extract-plugin
export var child = "styles-module--child--VKYR-";
export var circle = "styles-module--circle--PkehX";
export var container = "styles-module--container--wvRL1";
export var date = "styles-module--date--7Iqgw";
export var emptyParagraph = "styles-module--empty-paragraph--p3pn3";
export var iconDash = "styles-module--icon-dash--1ZdSX";
export var iconDashContainer = "styles-module--iconDashContainer--bDXGz";
export var primary = "#06004d";
export var secondary = "#4dafd7";
export var wrapper = "styles-module--wrapper--fynsH";