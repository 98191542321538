import OnVisible from 'react-on-visible';
import React from 'react';
import { Section } from 'components';
import { SectionHeading } from '../../components/DealRoom';
import * as styles from './styles.module.scss';

export const Quote = ({
  data: {
    primary: { heading, name_of_the_author: author, position, quote },
  },
  template,
}) => (
  <OnVisible
    className={`${styles[template]} ${styles.offerRoomContentBlock}`}
    id="summary"
    percent={20}
    visibleClassName={styles.visible}
  >
    <Section className={styles.quoteBlock} noContainer sliceName="Quote" spacing="n">
      {heading && <SectionHeading heading={heading} />}
      <blockquote className={styles.offerRoomQuote}>"{quote && quote.text}"</blockquote>
      <p className="offer-room-quote-author">
        {author && author.text}, {position && position.text}
      </p>
    </Section>
  </OnVisible>
);
