import React from 'react';
import { BrandShape } from 'components';
import { SearchComponent } from '../../components/SearchComponent/SearchComponent';
import * as styles from './styles.module.scss';

export const Searchbar = props => {
  const {
    data: {
      primary: { title, description, search_input_placeholder: searchInputPlaceholder },
    },
  } = props;

  return (
    <div className={styles.inputSliceContainer}>
      <div className={styles.halfWidthContainer}>
        <p className={styles.inputHeader}>{title?.text || 'Search'}</p>
        <p className={styles.extraInput}>{description?.text || 'Search for deals or blogs'}</p>
        <SearchComponent
          searchInputPlaceholder={searchInputPlaceholder}
          isSlice
          inheritedClassname={styles.inputClickButton}
        />
      </div>
      <BrandShape inheritedClassname={styles.brandShape} />
    </div>
  );
};
