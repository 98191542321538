import React, { useState } from 'react';
import { Accordion, Section } from 'components';
import { Tabs } from 'components/Page';
import * as styles from './styles.module.scss';

export const FaqSection = ({ data: { items }, template }) => {
  const [activeCategory, setActiveCategory] = useState('All');
  const categories = ['All', ...new Set(items.map(item => item.category))];
  const activeItems = items.filter(item => activeCategory === 'All' || activeCategory === item.category);

  const templateClass = styles[template] || '';

  return (
    <Section className={`${styles.container} ${templateClass}`} noContainer spacing="n" sliceName="Faq">
      <Tabs
        inheritedClassname={styles.tabs}
        itemClassName={styles.tab}
        tabNames={categories}
        selectTab={setActiveCategory}
        startTab="All"
        tabEventType="FAQ Tab"
      />
      <Category items={activeItems} />
    </Section>
  );
};

const Category = ({ items }) => {
  // Change Prismic Q&A keys to match accordian component
  const accordionItems = items.map(({ question: { text: title } = {}, answer: { html: body } = {} }) => {
    const item = { title, body };
    return item;
  });
  return <Accordion className={styles.accordion} items={accordionItems} accordionEventType="FAQ Item" />;
};
