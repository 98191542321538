// extracted by mini-css-extract-plugin
export var button = "styles-module--button--pErLp";
export var columnActions = "styles-module--column-actions--8vYAm";
export var columnContent = "styles-module--column-content--stwiz";
export var columnGridItem = "styles-module--column-grid-item--reO9M";
export var columnIcon = "styles-module--column-icon--0WNE6";
export var columnSubtitle = "styles-module--column-subtitle--bkwiG";
export var columnTitle = "styles-module--column-title--jhOGl";
export var columnsBody = "styles-module--columns-body--JxjTu";
export var columnsCarousel = "styles-module--columns-carousel--68EZL";
export var columnsDescription = "styles-module--columns-description--6hHnM";
export var columnsGrid = "styles-module--columns-grid--jjg+d";
export var columnsHeader = "styles-module--columns-header--38fpJ";
export var columnsSection = "styles-module--columns-section--uPUN8";
export var columnsTitle = "styles-module--columns-title--Vl6pv";
export var columnsTitleRow = "styles-module--columns-title-row--GiZ4b";
export var cta = "styles-module--cta--nzDDD";
export var ctaSubText = "styles-module--cta-sub-text--wLxQG";
export var gatsbyImage = "styles-module--gatsby-image--nDYiN";
export var infoLink = "styles-module--info-link--RDX1h";
export var investPage = "styles-module--invest-page--BbwW7";
export var page = "styles-module--page--hycet";
export var primary = "#06004d";
export var raise = "styles-module--raise--nYhFe";
export var relatedPageAction = "styles-module--related-page-action--fp4WD";
export var relatedPageImage = "styles-module--related-page-image--2bp+3";
export var relatedPageSection = "styles-module--related-page-section--d1Mp0";
export var relatedPageText = "styles-module--related-page-text--86tD4";
export var secondary = "#4dafd7";
export var tagline = "styles-module--tagline---4H2v";
export var wrapper = "styles-module--wrapper--SZAe7";