// extracted by mini-css-extract-plugin
export var button = "PostCard-module--button--+1Tv-";
export var buttonWrapper = "PostCard-module--buttonWrapper--SrueD";
export var categoryTagDivider = "PostCard-module--category-tag-divider--GSgRE";
export var date = "PostCard-module--date--uAvWb";
export var gatsbyImage = "PostCard-module--gatsby-image--RLdQW";
export var isLandingPageCard = "PostCard-module--isLandingPageCard--wE36m";
export var isLandingPageImage = "PostCard-module--isLandingPageImage--isPJr";
export var postCard = "PostCard-module--post-card--wuiBZ";
export var postCardCategories = "PostCard-module--post-card-categories--6+fDw";
export var postCardCategory = "PostCard-module--post-card-category--OjJCK";
export var postCardContent = "PostCard-module--post-card-content--KX2iZ";
export var postCardImage = "PostCard-module--post-card-image--RFGaw";
export var postCardImageWrapper = "PostCard-module--post-card-image-wrapper--4b37B";
export var postCardInfo = "PostCard-module--post-card-info--VJUEL";
export var postCardTitle = "PostCard-module--post-card-title--YdFCR";
export var postCardWrapper = "PostCard-module--post-card-wrapper--4cHX1";
export var primary = "#06004d";
export var secondary = "#4dafd7";