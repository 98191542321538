import React, { useState } from 'react';
import capitalize from 'voca/capitalize';
import { EmblaCarousel, Button, Section } from 'components';
import { SliceHeader, Tabs } from 'components/Page';
import { Tile } from './Tile';
import * as styles from './TileCarousel.module.scss';

export const TileCarousel = props => {
  // Initialize a piece of state to filter the carousel items by this filter
  const [filter, setFilter] = useState('All');
  const { tagline, title, tiles, warningStatements, noTilesCopy, cta, ctaText } = props;

  // Convert document type to name to be displayed in tab for filtering tiles
  const documentTypeToTabName = docType => capitalize(docType.split('_').join(' '));

  const filterTile = ({ tile }) => {
    const { type: documentType, data: documentData } = tile?.document || {};

    if (documentType === 'deal_room') {
      const { offer_type: offerType } = documentData;
      // Hide deal if it is foreign to the viewer (outside their country of residence - see Geo context)
      return filter === 'All' || filter === offerType;
    }

    // Document type is case study
    return filter === 'All' || filter === documentTypeToTabName(documentType);
  };

  const filteredTiles = tiles ? tiles.filter(filterTile) : [];
  const hasTiles = filteredTiles?.length > 0;
  const offerTypes = tiles
    .map(({ tile }) => {
      const { type: documentType, data: documentData } = tile?.document;
      if (documentType === 'deal_room') return documentData?.offer_type;
      return documentTypeToTabName(documentType);
    })
    .sort((a, b) => a.localeCompare(b));

  const uniqOfferTypes = new Set(offerTypes);

  const tabNames = uniqOfferTypes?.size > 1 ? ['All', ...uniqOfferTypes] : null;

  const filteredSlides = filteredTiles.map(tile => (
    <Tile {...tile} key={tile?.tile?.document?.uid} warningStatements={warningStatements} dealTileLocation="Carousel" />
  ));

  return (
    <Section
      className={`${styles.dealCarousel} buttonWrapperClassname`}
      containerClassName={styles.wrapper}
      sliceName="Tiles"
      spacing="s"
    >
      {hasTiles && (
        <>
          <div className={styles.dealCarouselHeader}>
            <SliceHeader inheritedClassname={styles.pageSliceHeader} title={title} tagline={tagline} />
            {tabNames && (
              <div className={styles.tabsWrapper}>
                <p className={styles.tabsPrefix}>Filter By</p>
                <Tabs tabNames={tabNames} startTab="All" selectTab={setFilter} tabEventType="Tile Carousel Tab" />
                {cta?.url && <Button to={cta?.url}>{ctaText?.text}</Button>}
              </div>
            )}
          </div>
          <EmblaCarousel
            slides={filteredSlides}
            buttonWrapperClassname={styles.buttonsWrapperDealCarousel}
            inheritedClassname={styles.embla}
            className="deal-carousel"
            hasTabs
          />
        </>
      )}
    </Section>
  );
};
