import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Helmet from 'react-helmet';
import Facebook from './Facebook';
import Twitter from './Twitter';

// Complete tutorial: https://www.gatsbyjs.org/docs/add-seo-component/

const SEO = props => {
  const {
    title,
    desc,
    banner,
    featuredImage,
    firstPublicationDate,
    lastPublicationDate,
    schema,
    pathname,
    article,
  } = props;

  const { site } = useStaticQuery(query);

  const {
    buildTime,
    siteMetadata: {
      siteUrl,
      defaultTitle,
      defaultDescription,
      defaultBanner,
      siteName,
      siteLanguage,
      ogLanguage,
      author,
      twitter,
    },
  } = site;

  const seo = {
    title: title || defaultTitle,
    description: desc || defaultDescription,
    image: banner || featuredImage || `${siteUrl}${defaultBanner}`,
    url: `${siteUrl}${pathname || ''}`,
  };

  // schema.org in JSONLD format
  // https://developers.google.com/search/docs/guides/intro-structured-data
  // You can fill out the 'author', 'creator' with more data or another type (e.g. 'Organization')

  const schemaOrgWebPage = {
    '@context': 'http://schema.org',
    '@type': 'WebPage',
    url: siteUrl,
    headline: seo.title,
    inLanguage: siteLanguage,
    mainEntityOfPage: siteUrl,
    description: seo.description,
    name: seo.title,
    author: {
      '@type': 'Person',
      name: author,
    },
    copyrightHolder: {
      '@type': 'Person',
      name: author,
    },
    copyrightYear: '2021',
    creator: {
      '@type': 'Person',
      name: author,
    },
    publisher: {
      '@type': 'Person',
      name: author,
    },
    datePublished: '2019-01-18T10:30:00+01:00',
    dateModified: buildTime,
    image: {
      '@type': 'ImageObject',
      url: seo.image,
    },
  };

  // Initial breadcrumb list

  const itemListElement = [
    {
      '@type': 'ListItem',
      item: {
        '@id': siteUrl,
        name: 'Homepage',
      },
      position: 1,
    },
  ];

  const organizationSchema = {
    '@context': 'http://www.schema.org',
    '@type': 'LocalBusiness',
    name: 'Equitise',
    url: 'https://equitise.com/',
    logo:
      'https://scontent.fceb5-1.fna.fbcdn.net/v/t1.6435-9/101966638_1535018936659332_3674698597762085442_n.png?_nc_cat=111&ccb=1-5&_nc_sid=09cbfe&_nc_ohc=VR2rGOyVpaMAX8ZrxaN&tn=gSjXnrarBBu7vD1P&_nc_ht=scontent.fceb5-1.fna&oh=ae4b0d577243783fc96ea82868ffa62a&oe=619E86DC',
    image:
      'https://scontent.fceb5-1.fna.fbcdn.net/v/t1.6435-9/101966638_1535018936659332_3674698597762085442_n.png?_nc_cat=111&ccb=1-5&_nc_sid=09cbfe&_nc_ohc=VR2rGOyVpaMAX8ZrxaN&tn=gSjXnrarBBu7vD1P&_nc_ht=scontent.fceb5-1.fna&oh=ae4b0d577243783fc96ea82868ffa62a&oe=619E86DC',
    description:
      'Equitise is an Australian & New Zealand investment platform that brings extraordinary opportunities to everyday people. Investing is free and all companies that we partner with are highly vetted.',
    address: {
      '@type': 'PostalAddress',
      streetAddress: '85 William St',
      addressLocality: 'Darlinghurst',
      addressRegion: 'New South Wales',
      postalCode: '2010',
      addressCountry: 'Australia',
    },
    geo: { '@type': 'GeoCoordinates', latitude: '-33.8744877', longitude: '151.2137245' },
    hasMap:
      'https://www.google.com/maps/place/Equitise/@-33.8744877,151.2137245,17z/data=!3m1!4b1!4m5!3m4!1s0x6b12ae418c2f3eb1:0x83d9ad186b4789d8!8m2!3d-33.8744877!4d151.2159132',
    openingHours: 'Mo 08:00-18:00 Tu 08:00-18:00 We 08:00-18:00 Th 08:00-18:00 Fr 08:00-18:00',
  };

  let schemaArticle = null;

  if (article) {
    schemaArticle = {
      '@context': 'https://schema.org',
      '@type': 'BlogPosting',
      author: {
        '@type': 'Person',
        name: author,
      },
      copyrightHolder: {
        '@type': 'Person',
        name: author,
      },
      creator: {
        '@type': 'Person',
        name: author,
      },
      publisher: {
        '@type': 'Organization',
        name: 'Equitise',
        logo: {
          '@type': 'ImageObject',
          url: seo.image,
        },
      },
      description: seo.image,
      headline: title,
      datePublished: firstPublicationDate,
      dateModified: lastPublicationDate,
      inLanguage: 'en',
      url: `${siteUrl}${pathname}`,
      name: title,
      image: {
        '@type': 'ImageObject',
        url: seo.image,
      },
      mainEntityOfPage: `${siteUrl}${pathname}`,
    };
  }

  const breadcrumb = {
    '@context': 'http://schema.org',
    '@type': 'BreadcrumbList',
    description: 'Breadcrumbs list',
    name: 'Breadcrumbs',
    itemListElement,
  };

  return (
    <>
      <Helmet title={seo.title}>
        <html lang={siteLanguage} />
        <meta name="description" content={seo.description} />
        <meta name="image" content={seo.image} />
        <meta name="gatsby-starter" content="Gatsby Starter Prismic" />
        {/* Insert schema.org data conditionally (webpage/article) + everytime (breadcrumbs) */}
        {!article && <script type="application/ld+json">{JSON.stringify(schema || schemaOrgWebPage)}</script>}
        {article && <script type="application/ld+json">{JSON.stringify(schema || schemaArticle)}</script>}
        <script type="application/ld+json">{JSON.stringify(organizationSchema)}</script>
        <script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
      </Helmet>
      <Facebook
        desc={seo.description}
        image={seo.image}
        title={seo.title}
        type={article ? 'article' : 'website'}
        url={seo.url}
        locale={ogLanguage}
        name={siteName}
      />
      <Twitter title={seo.title} image={seo.image} desc={seo.description} username={twitter} />
    </>
  );
};

export default SEO;

const query = graphql`
  query SEO {
    site {
      buildTime(formatString: "YYYY-MM-DD")
      siteMetadata {
        siteUrl
        defaultTitle: title
        defaultDescription: description
        defaultBanner: banner
        siteName
        siteLanguage
        ogLanguage
        author
        twitter
      }
    }
  }
`;
