// extracted by mini-css-extract-plugin
export var buttonsWrapperNoTabs = "embla-module--buttons-wrapper-no-tabs--kROnu";
export var embla = "embla-module--embla--msUr2";
export var emblaNavigation = "embla-module--embla-navigation--INS83";
export var embla__button = "embla-module--embla__button--BiSmt";
export var embla__buttonNext = "embla-module--embla__button-next--4rJ9i";
export var embla__buttonPrev = "embla-module--embla__button-prev--eU4u+";
export var embla__container = "embla-module--embla__container--C-Vtp";
export var embla__dot = "embla-module--embla__dot--YpHaw";
export var embla__dots = "embla-module--embla__dots--Fr6hf";
export var embla__slide = "embla-module--embla__slide--nSCPb";
export var embla__viewport = "embla-module--embla__viewport--4cA9H";
export var isDraggable = "embla-module--is-draggable---3wpc";
export var isDragging = "embla-module--is-dragging--iKB8D";
export var isSelected = "embla-module--is-selected--4XeTe";
export var offerRoom = "embla-module--offer-room--yCUs+";
export var primary = "#06004d";
export var secondary = "#4dafd7";