// extracted by mini-css-extract-plugin
export var button = "TestimonialCard-module--button--fLiCL";
export var primary = "#06004d";
export var profilePicture = "TestimonialCard-module--profile-picture--Yui75";
export var secondary = "#4dafd7";
export var tagline = "TestimonialCard-module--tagline--LDwHV";
export var taglineRow = "TestimonialCard-module--tagline-row--QNiIt";
export var testimonialCard = "TestimonialCard-module--testimonial-card--D5YEM";
export var testimonialFullName = "TestimonialCard-module--testimonial-full-name--oX42D";
export var testimonialHeader = "TestimonialCard-module--testimonial-header--nXjPc";
export var testimonialHeaderDetails = "TestimonialCard-module--testimonial-header-details--QEjJF";
export var testimonialJobTitle = "TestimonialCard-module--testimonial-job-title--bTdV5";
export var testimonialQuote = "TestimonialCard-module--testimonial-quote--ESXA1";