import { Image, Link, Tagline, Section } from 'components';

import React from 'react';
import * as styles from './styles.module.scss';

export const QuizElement = props => {
  const { data } = props;
  const { primary, items } = data;
  const { tagline, question } = primary;
  return (
    <Section className={styles.quizElement} containerClassName={styles.wrapper} sliceName="QuizElement">
      <div className={styles.quizElementHeader}>
        <Tagline tagline={tagline} icon="question-secondary" />
        <span className={styles.quizElementHeaderTitle}>{question.text}</span>
      </div>
      <div className={styles.quizElementGrid}>
        {items && items.map(item => <QuizElementItem key={item.option.text} {...item} />)}
      </div>
    </Section>
  );
};

const QuizElementItem = props => {
  const { image, option, typeform_url: typeformUrl } = props;
  return (
    <Link to={typeformUrl?.url} className={styles.quizElementItem}>
      <Image image={image} inheritedClassname={styles.gatsbyImage} />
      <span className={styles.quizElementTitle}>{option.text}</span>
    </Link>
  );
};
