import { Map, Section } from 'components';
import React from 'react';
import * as styles from './styles.module.scss';

export const Contact = ({
  data: {
    primary: {
      address: { text: address } = {},
      country: { text: country } = {},
      email: { text: email } = {},
      phone: { text: phone } = {},
      twitter_handle: { text: twitter } = {},
    },
  },
}) => (
  <Section className={styles.contactSection} noContainer spacing="n" sliceName="Contact">
    {address && <Map address={address} height="300" width="400" />}
    <div className={styles.addressContainer}>
      {country && <span className={styles.country}>{country}</span>}
      {address && <span className={styles.address}>{address}</span>}
    </div>
    <div className={styles.contactDetailsContainer}>
      {phone && <span>{phone}</span>}
      {email && <span>{email}</span>}
      {twitter && <span>{twitter}</span>}
    </div>
  </Section>
);
