// extracted by mini-css-extract-plugin
export var body = "styles-module--body--7MgnC";
export var card = "styles-module--card--BZxYU";
export var cardBody = "styles-module--card-body--8bYCR";
export var cardHeader = "styles-module--card-header--vIB9E";
export var cardImageWrapper = "styles-module--card-image-wrapper--ZTNxW";
export var displayImage = "styles-module--display-image--uQfgY";
export var displayImageHover = "styles-module--display-image-hover--9S71Z";
export var links = "styles-module--links--Wyw3-";
export var primary = "#06004d";
export var secondary = "#4dafd7";
export var socialLink = "styles-module--social-link--Qir4c";
export var teamMembers = "styles-module--team-members--jerpe";
export var teamSection = "styles-module--team-section--krcGb";
export var teamSectionBoard = "styles-module--team-section-board--TiqSl";
export var wrapper = "styles-module--wrapper--K7dZh";