// extracted by mini-css-extract-plugin
export var actions = "Header-module--actions--vMGv9";
export var brandShape = "Header-module--brand-shape--ZMqRU";
export var button = "Header-module--button--qwUh2";
export var ctaFlex = "Header-module--cta-flex--+AuRh";
export var ctaLink = "Header-module--cta-link--N2DRm";
export var header = "Header-module--header--3ERvK";
export var headerBody = "Header-module--header-body--bsomd";
export var headerBodyContent = "Header-module--header-body-content--xBjkJ";
export var headerBodyCta = "Header-module--header-body-cta--ruyb5";
export var headerBodyText = "Header-module--header-body-text--oUsIN";
export var headerBodyTile = "Header-module--header-body-tile--xrOGp";
export var headerBodyTitle = "Header-module--header-body-title--bkUmC";
export var headerCenterNavigation = "Header-module--header-center-navigation--oqgKP";
export var headerRightNavigation = "Header-module--header-right-navigation--d0Oml";
export var headerTopRow = "Header-module--header-top-row--EgHLT";
export var highlight = "Header-module--highlight--QEZzF";
export var homeHeader = "Header-module--home-header--3hqOZ";
export var inputClickButton = "Header-module--input-click-button--gykuF";
export var investDropdown = "Header-module--invest-dropdown--x+dXj";
export var investPage = "Header-module--invest-page--Bdpkp";
export var isSyndicateBackground = "Header-module--isSyndicateBackground--u0gb+";
export var isSyndicateDealActions = "Header-module--isSyndicateDealActions--qHmgu";
export var isSyndicateHeader = "Header-module--isSyndicateHeader--0t1M6";
export var item = "Header-module--item--m4ouf";
export var link = "Header-module--link--XPSsj";
export var logo = "Header-module--logo--m5asN";
export var outline = "Header-module--outline--z+3FQ";
export var pageTile = "Header-module--page-tile--5gUfh";
export var pageTileBody = "Header-module--page-tile-body--cpW1E";
export var primary = "#06004d";
export var raise = "Header-module--raise--ZGD3C";
export var scrolledBeforeLock = "Header-module--scrolled-before-lock--8MRQK";
export var secondary = "#4dafd7";
export var showChevron = "Header-module--show-chevron--2mUOP";
export var showSublinks = "Header-module--show-sublinks--fhS2g";
export var stickHeader = "Header-module--stick-header--on1x8";
export var subnav = "Header-module--subnav--YenLV";
export var subnavLink = "Header-module--subnav-link--B5uxo";
export var surveyPageTitle = "Header-module--surveyPageTitle--cW7ND";
export var tagline = "Header-module--tagline--lsI6Q";
export var toggle = "Header-module--toggle--0e1MI";
export var toggleChevron = "Header-module--toggle-chevron--GpTZ2";
export var visible = "Header-module--visible--e0U23";
export var white = "Header-module--white--Svcae";
export var wrapper = "Header-module--wrapper--vOGFo";