import React, { useState } from 'react';

import OnVisible from 'react-on-visible';

import * as styles from './ProgressBar.module.scss';

export const ProgressBar = ({
  label,
  metric,
  progress,
  secondaryBar,
  HtmlElement = 'div',
  showMetric,
  roundMinimum,
  roundMaximum,
  raisedAmount,
  inheritedClassname,
}) => {
  const [visible, setVisible] = useState(false);
  const displayProgress = fraction => (!fraction || fraction === Infinity ? 0 : Math.floor(fraction * 100));

  const showProgressAsZero = !progress || progress === Infinity;

  // Hide progress bar if over 300%
  const noProgress = progress === undefined;
  const showProgress = !noProgress && (showProgressAsZero || progress <= 3);

  // Only show label if minimum target has been reached
  const showLabel = progress >= 1;

  const progressPercentage = `${Math.floor(progress * 100)}%`;

  // percentage that gets displayed on the first bar - if raised amount exceeds minumum, set to 1(becomes %100), or else the load speed is disproportionate
  const zeroToMinimumRaise = raisedAmount / roundMinimum > 1 ? 1 : raisedAmount / roundMinimum;

  // percentage that gets displayed on the second bar
  const minimumToMaximumRaise = raisedAmount > roundMinimum ? raisedAmount / roundMaximum : 0;

  return (
    <HtmlElement className={styles.progressBar}>
      <span className={`${inheritedClassname || ''} ${styles.progressBarTitle} progress-bar-title`}>
        <span>{showLabel && label}</span>
        {showProgress && showMetric && (
          <strong>
            {progressPercentage} {metric}
          </strong>
        )}
      </span>
      <div className={styles.progressSpanGrid}>
        <OnVisible className={styles.progressBarItem} onChange={isVisible => setVisible(isVisible)}>
          <span
            className={`${styles.progressBarItemFill} ${styles.zeroToMin}`}
            style={{ width: `${visible ? displayProgress(zeroToMinimumRaise) : 0}%` }}
          />
        </OnVisible>
        <OnVisible className={styles.progressBarItem} onChange={isVisible => setVisible(isVisible)}>
          <span
            className={`${styles.progressBarItemFill} ${styles.minToMax}`}
            style={{ width: `${visible ? displayProgress(minimumToMaximumRaise) : 0}%` }}
          />
        </OnVisible>
      </div>
      <div className={styles.minIndicatorContainer}>
        <div className={styles.minIndicator}>min</div>
      </div>
    </HtmlElement>
  );
};
