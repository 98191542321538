import { gql } from '@apollo/client';

export const UPDATE_INTERCOM_ID = gql`
  mutation updateUser($userId: Int!, $userInput: UserInput!) {
    updateUser(userId: $userId, userInput: $userInput) {
      id
      email
      intercomContactId
    }
  }
`;
