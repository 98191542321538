import React from 'react';
import { Tagline } from 'components';
import * as styles from './SliceHeader.module.scss';

export const SliceHeader = ({ tagline, title, className, children, inheritedClassname, titleName }) => (
  <div className={`${styles.pageSliceHeader} ${inheritedClassname || ''} ${className || ''} page-slice-header`}>
    <div className={styles.pageSliceTitleWrapper}>
      <Tagline tagline={tagline} />
      <span className={`${styles.pageSliceTitle} ${titleName || ''}`}>{title.text}</span>
    </div>
    <div className={styles.children}>{children}</div>
  </div>
);
