import React from 'react';
import * as styles from './Hamburger.module.scss';

export const Hamburger = ({ onClick, active, theme, inheritedClassname }) => (
  <button
    type="button"
    className={`${styles.hamburger} ${inheritedClassname || ''} ${active ? styles.toggled : ''} ${styles[theme] || ''}`}
    onClick={onClick}
  >
    Open Navigation
  </button>
);
