import React from 'react';
import { Modal, Button, Section } from 'components';
import { LOGIN, REGISTER } from '../../apis/routes';
import { REDIRECT_PATH } from '../../utils/constants/localStorageNames';

import * as styles from './DocumentsModal.module.scss';

export const DocumentsModal = props => {
  const { modalOpen, setModalOpen, location } = props;

  const url = location.href;

  const redirect = `${url ? `?redirect=${url}` : ''}`;

  const closeModal = () => setModalOpen(false);
  const onLinkClick = () => {
    localStorage.setItem(REDIRECT_PATH, url);

    return closeModal();
  };

  const loginLink = `${LOGIN}${redirect}`;
  const registerLink = `${REGISTER}${redirect}`;

  return (
    <Modal
      active={modalOpen}
      closeModal={closeModal}
      className="doc"
      title="You must be logged in to access this document"
      icon="illustration"
    >
      <Section as="div" className={styles.docModalContainer} spacing="n" noContainer sliceName="DocumentsModal">
        <div className="doc-modal-text">Please login or register a new account below</div>
        <div className={styles.docModalButton}>
          <Button to={loginLink} theme="highlight" onClick={onLinkClick}>
            Login
          </Button>
        </div>
        <div className={styles.docModalRegister}>
          New to Equitise?{' '}
          <a href={registerLink} target="_blank" rel="noreferrer" onClick={onLinkClick}>
            Register
          </a>
        </div>
      </Section>
    </Modal>
  );
};
