// extracted by mini-css-extract-plugin
export var avatar = "TeamMembers-module--avatar--s+Sv3";
export var basic = "TeamMembers-module--basic--IZmVE";
export var featured = "TeamMembers-module--featured--JegMY";
export var gatsbyImage = "TeamMembers-module--gatsby-image--2t2zf";
export var offerRoomTeamMemberBio = "TeamMembers-module--offer-room-team-member-bio--d-0JB";
export var primary = "#06004d";
export var secondary = "#4dafd7";
export var syndicateMemberName = "TeamMembers-module--syndicateMemberName--7Xt1Y";
export var syndicateMemberPosition = "TeamMembers-module--syndicateMemberPosition--qT44S";
export var teamMember = "TeamMembers-module--team-member--mG2Rp";
export var teamMemberContent = "TeamMembers-module--team-member-content--nkxN1";
export var teamMemberDetails = "TeamMembers-module--team-member-details--JB5ac";
export var teamMemberName = "TeamMembers-module--team-member-name--Vz7rQ";
export var teamMembersContainer = "TeamMembers-module--team-members-container--JThOr";