import React from 'react';
import OnVisible from 'react-on-visible';
import { SectionHeading, UserTable } from 'components/DealRoom';
import { Section } from 'components';
import * as styles from './styles.module.scss';

export const TableSection = ({ data: { primary = '', items: tables }, template }) => (
  <OnVisible className={`offer-room-content-block ${styles[template]}`} visibleClassName={styles.visible} percent={20}>
    <Section noContainer sliceName="TableSection" spacing="n">
      {primary && <SectionHeading heading={primary.heading} />}
      {primary && primary.content && <div dangerouslySetInnerHTML={{ __html: primary.content.html }} />}
      {tables.map(table => {
        const {
          csv: { url = '' },
          footnotes = '',
          content,
        } = table;
        return (
          <UserTable url={url} footnotes={footnotes} key={url} content={content && content.html} template={template} />
        );
      })}
    </Section>
  </OnVisible>
);
