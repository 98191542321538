// extracted by mini-css-extract-plugin
export var landingCtaFormFormLinkContainer = "styles-module--landingCtaFormFormLinkContainer--2ywVD";
export var landingCtaImageVideo = "styles-module--landing-cta-image-video--BSvKn";
export var landingCtaImageVideoContainer = "styles-module--landing-cta-image-video-container--KQ4Cd";
export var landingCtaImageVideoDescription = "styles-module--landing-cta-image-video-description--3BiY4";
export var landingCtaImageVideoHeadline = "styles-module--landing-cta-image-video-headline--WXVk6";
export var landingCtaImageVideoLogo = "styles-module--landing-cta-image-video-logo--KHRoi";
export var landingCtaImageVideoLogoContainer = "styles-module--landing-cta-image-video-logo-container--VRuuo";
export var landingCtaImageVideoTextTeamContainer = "styles-module--landing-cta-image-video-text-team-container---2kVm";
export var landingCtaImageVideoWrapper = "styles-module--landing-cta-image-video-wrapper--CBVcF";
export var primary = "#06004d";
export var secondary = "#4dafd7";