import React, { createContext, useState, useEffect } from 'react';
import { setCookie, getCookie, destroy } from '../apis';
import { UTM_SOURCE, UTM_MEDIUM, UTM_CAMPAIGN } from '../utils/constants/cookieNames';

export const UtmContext = createContext(null);

// Provides the initial query parameters globally
export const UtmProvider = ({ children }) => {
  const [utmParams, setUtmParams] = useState('');

  // looks at the url and pulls out source, medium and campaign utms if they exist and adds them to context.  If no utms found queryString.get returns null.
  useEffect(() => {
    const queryString = new URLSearchParams(window.location.search);
    const utmSource = queryString.get('utm_source') || getCookie(UTM_SOURCE);
    const utmMedium = queryString.get('utm_medium') || getCookie(UTM_MEDIUM);
    const utmCampaign = queryString.get('utm_campaign') || getCookie(UTM_CAMPAIGN);
    const utmObject = {};
    utmObject[UTM_SOURCE] = { utmValue: utmSource, name: 'utmSource' };
    utmObject[UTM_MEDIUM] = { utmValue: utmMedium, name: 'utmMedium' };
    utmObject[UTM_CAMPAIGN] = { utmValue: utmCampaign, name: 'utmCampaign' };

    const retrievedUtmParams = {};

    Object.entries(utmObject).forEach(([key, value]) => {
      if (value?.utmValue === 'undefined') {
        destroy(key);
      }
      if (value?.utmValue && value?.utmValue !== 'undefined') {
        setCookie({ value: value?.utmValue, cookieName: key, expiry: 7 });
        retrievedUtmParams[value?.name] = value?.utmValue;
      }
    });

    // set state with retrieved params OR cookie values IF value is NOT undefined
    setUtmParams(retrievedUtmParams);
  }, []);

  return <UtmContext.Provider value={{ utmParams }}>{children}</UtmContext.Provider>;
};

UtmContext.displayName = 'UtmContext';
