import React from 'react';
import shortid from 'shortid';
import { Logo, Tagline, Section } from 'components';
import { TeamMemberCard } from 'components/TeamPage';
import { convertToCamelCase } from '../../utils';
import * as styles from './styles.module.scss';

export const TeamSection = ({ data }) => {
  const { primary, items } = data;
  const { team_name: teamName, custom_class: customClass, tagline } = primary;

  const hasTeamName = teamName?.text;
  const hasCustomClass = customClass?.text;

  const alteredCustomClassName = convertToCamelCase(hasCustomClass);
  const teamMemberClassnames = {
    cardHeader: styles.cardHeader,
    displayImage: styles.displayImage,
  };

  return (
    <Section
      className={`${styles.teamSection} ${styles[alteredCustomClassName] || ''}`}
      containerClassName={`${styles.wrapper} wrapper`}
      sliceName="TeamSection"
    >
      <div className={styles.teamMembers}>
        {items.map(teamMember => (
          <TeamMemberCard
            cardImageWrapperClass={styles.cardImageWrapper}
            cardBodyClass={styles.cardBody}
            inheritedClassnames={teamMemberClassnames}
            className={styles.card}
            data={teamMember}
            key={shortid.generate()}
            alteredCustomClassName={alteredCustomClassName}
          />
        ))}
      </div>
    </Section>
  );
};
