import React, { useContext, useEffect, useState } from 'react';
import { gql, useQuery } from '@apollo/client';
import { Tooltip } from 'react-tippy';
import { Link, ProgressBar, Tagline } from 'components';
import { Timer, RegisterInterest } from 'components/DealRoom';
import { formatCurrency, IPO, RETAIL, NEW_ZEALAND, truncateText } from 'utils';
import apolloClient from '../../../gatsby-theme-apollo/client';
import { PageTile } from './PageTile';
import { trackAmplitudeEvent } from '../../../utils';
import { AuthContext, TrackEventContext } from '../../../context';
import { userData } from '../../../utils/userDataToAmplitude';
import * as styles from './DealTile.module.scss';

const GET_DB_DEAL_DATA = gql`
  query getDealByPrismicUid($prismicId: String) {
    deal(prismicId: $prismicId) {
      id
      metrics {
        minEquityPercentage
        maxEquityPercentage
        isLive
        numInvestments
        percentageFunded
        raisedAmount
      }
      valuation
    }
  }
`;

export const DealTile = props => {
  const {
    cta,
    prismicDealData,
    uid,
    prismicId,
    justLanchedBanner,
    closingSoonBanner,
    warningStatements,
    heroDealTileDescription,
    inheritedClassnames,
    dealTileLocation,
  } = props || {};
  const {
    companyName,
    country,
    endDate,
    isEoi,
    isMaxedOut,
    logo,
    hideRaisedAmount,
    hideTimer,
    hideEoiTag,
    offerType,
    roundMinimum,
    roundMaximum,
    tileDescription,
    tileFooter,
    tileImage,
    tileLogo,
    registerInterestFooter,
    visibility,
  } = prismicDealData || {};

  // TODO show shimmering container on load
  const { data: getDbDealDataRes, loading: getDbDealDataLoading } = useQuery(GET_DB_DEAL_DATA, {
    client: apolloClient,
    skip: isEoi,
    variables: { prismicId },
  });
  const { metrics } = getDbDealDataRes?.deal || {};
  const { minEquityPercentage, maxEquityPercentage, numInvestments, percentageFunded, raisedAmount, isLive } =
    metrics || {};
  const { tooltip } = warningStatements?.find(statement => statement.country === country) || {};
  const validClassnames = inheritedClassnames || {};
  const nzDeal = country === NEW_ZEALAND;
  const { resource: user } = useContext(AuthContext) || {};
  const { intercomAttributes, intercomEmail } = useContext(TrackEventContext) || {};

  const DealMetrics = () => {
    const maxPercentage = roundMaximum / roundMinimum;
    const raisedOrMaximum = isMaxedOut ? roundMaximum : raisedAmount;
    const showEquityPercentage = Boolean(minEquityPercentage !== undefined && maxEquityPercentage !== undefined);

    return (
      <>
        <ProgressBar
          label="Minimum target met"
          metric="raised"
          progress={isMaxedOut ? maxPercentage : percentageFunded}
          showMetric={offerType === RETAIL}
          inheritedClassname={styles.progressBarTitle}
          raisedAmount={raisedOrMaximum}
          roundMinimum={roundMinimum}
          roundMaximum={roundMaximum}
        />
        <div className={styles.dealTileStats}>
          <div className={styles.dealTileStat}>
            <span>Raised</span>
            <strong>{formatCurrency(raisedOrMaximum)}</strong>
          </div>
          {Boolean(roundMinimum) && (
            <div className={styles.dealTileStat}>
              <span>Equity</span>
              <strong>{showEquityPercentage && `${minEquityPercentage}-${maxEquityPercentage}%`}</strong>
            </div>
          )}
          <div className={styles.dealTileStat}>
            <span>Investments</span>
            <strong>{numInvestments}</strong>
          </div>
        </div>
      </>
    );
  };

  const ImageTag = () => (
    <span className={`${styles.dealTileStatus} ${visibility?.replace(/\s+/g, '-').toLowerCase()}`}>
      {(isEoi && 'Coming Soon') || (offerType === 'Wholesale' && offerType) || visibility}
    </span>
  );

  const OfferTypeTagline = () => (
    <Link to={offerType === RETAIL ? '/equity-crowdfunding-au' : `/${offerType?.toLowerCase()}`}>
      <Tagline
        tagline={{
          text: nzDeal ? `NZ - ${offerType}` : `AU - ${offerType}`,
        }}
        HtmlElement="span"
        inheritedClassname={`${styles.tagline} ${validClassnames.tagline || ''}`}
      />
    </Link>
  );

  const WarningTooltip = () => (
    <div className={styles.dealTileTooltip}>
      <Tooltip
        html={<div dangerouslySetInnerHTML={{ __html: tooltip?.html }} />}
        interactive
        position="top"
        arrow
        sticky
        theme="light"
        arrowSize="small"
        popperOptions={{
          preventOverflow: true,
        }}
      >
        <span className={`icon icon-info-dark ${styles.iconInfoDark}`} />
      </Tooltip>
    </div>
  );

  const showMetrics = !isEoi && !hideRaisedAmount;

  // if deal is an EOI and hero description is true, show description, OR
  // if deal is NOT an EOI, hideRaisedAmount is true, and hero description is true, show description
  const showDescription = (isEoi || hideRaisedAmount) && heroDealTileDescription;

  // Shows metrics, otherwise if deal is an EOI and shows raised amount, shows deal description
  // If no deal description, shows Expression of Interest Tag
  const TileContents = () => {
    // if deal is not an EOI and hideRaisedAmount is false, show metrics
    if (showMetrics) {
      return <DealMetrics />;
    }
    if (hideEoiTag) {
      return (
        <div className={styles.tileDescription}>
          <p>{truncateText(tileDescription?.text, 190)}</p>
        </div>
      );
    }
    if (showDescription) {
      return (
        <div className={styles.heroTileDescription}>
          <p>{truncateText(heroDealTileDescription, 190)}</p>
        </div>
      );
    }
    return (
      <p className={`${styles.dealTileMessage} ${styles.icon} ${styles.iconEoiDark} icon icon-eoi-dark`}>
        {tileFooter?.text || 'Expression of Interest'}
      </p>
    );
  };

  const showWarning = offerType !== IPO;
  const timerClassnames = {
    offerRoomHeaderTimer: styles.offerRoomHeaderTimer,
    timeRemaining: styles.timeRemaining,
  };

  const ShowTimer = () => {
    // If prismic field isMaxedOut is true, show message instead
    if (isMaxedOut) {
      return (
        <div>
          <span className={styles.offerRoomHeaderTimer || ''}>
            MAXIMUM TARGET REACHED
            <span> </span>
            <span role="img" aria-label="rocket">
              🚀
            </span>
          </span>
        </div>
      );
    }
    // Show timer if prismic field hideTimer is false
    if (!hideTimer && endDate) {
      return (
        <Timer
          endDate={endDate}
          prefix={isEoi ? 'Ends in ' : 'Closes in '}
          suffix=""
          closedMsg={isEoi ? 'Registration Ended' : 'Deal Closed'}
          timerClassnames={timerClassnames}
        />
      );
    }
    // Else if hide timer is true AND isMaxedOut is false, return nothing
    return null;
  };

  const [currentUrl, setCurrentUrl] = useState('');

  useEffect(() => {
    setCurrentUrl(window.location.pathname);
  }, []);

  const handleClick = (isEoiButton, company) => {
    try {
      trackAmplitudeEvent({
        email: user?.email || intercomEmail,
        eventName: `Clicked Deal Tile Button`,
        eventData: {
          button: isEoiButton ? 'Register Interest' : 'View Deal',
          company: company?.text,
          previousLink: currentUrl,
          tileLocation: dealTileLocation,
        },
        userData: user?.email ? userData(user) : intercomAttributes || {},
      });
    } catch (error) {
      console.error('Amplitude event Clicked Tile Link failed');
    }
  };

  return (
    <PageTile
      badge={showWarning && <WarningTooltip />}
      description={!hideEoiTag && tileDescription?.text}
      logo={tileLogo}
      link={`/offer/${uid}`}
      header={companyName?.text}
      imageTag={Boolean(visibility) && <ImageTag />}
      shouldLink={cta !== 'eoi'}
      tagline={<OfferTypeTagline />}
      tileImage={tileImage}
      justLanchedBanner={justLanchedBanner}
      closingSoonBanner={closingSoonBanner}
      isMaxedOut={isMaxedOut}
      inheritedClassname={`${styles.pageTile} ${validClassnames.pageTile || ''}`}
      inheritedClassnames={validClassnames}
      nzDeal={nzDeal}
      tileType="Deal Tile"
      country={country}
    >
      <TileContents />
      <div className={styles.dealTileActions}>
        {cta === 'eoi' ? (
          <RegisterInterest
            isTileEoi
            companyName={companyName?.text}
            country={country}
            isEoi={isEoi}
            logo={tileLogo}
            metrics={metrics}
            offerType={offerType}
            prismicId={prismicId}
            registerInterestFooter={registerInterestFooter}
            uid={uid}
            inheritedClassname={styles.investButtonContainer}
          />
        ) : (
          <Link
            to={`/offer/${uid}`}
            className={`${styles.button} button`}
            onClick={() => handleClick(isEoi, companyName)}
          >
            {isEoi ? 'Register Interest' : 'View Deal'}
          </Link>
        )}
      </div>
      <div className={styles.dealTileCounter}>
        <ShowTimer />
      </div>
    </PageTile>
  );
};
