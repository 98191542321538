module.exports = [{
      plugin: require('../node_modules/gatsby-theme-apollo/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Crowdfunding Australia & New Zealand | About | Equitise","short_name":"Equitise","description":"Equitise is the leader in online equity crowdfunding enabling high growth & innovative companies to partner with investors who believe in them.","start_url":"/","background_color":"#06004D","theme_color":"#06004D","display":"standalone","icon":"src/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"eff6b1244bc585deac4af8cf832478e8"},
    },{
      plugin: require('../node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://v3-stg.equitise.com"},
    },{
      plugin: require('../node_modules/gatsby-plugin-gatsby-cloud/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-TWFPW2W","gtmAuth":"Y2Q7G5tcSOXgXbtIocRjqQ","includeInDevelopment":true,"defaultDataLayer":null,"routeChangeEventName":"gatsby-route-change","enableWebVitalsTracking":false,"selfHostedOrigin":"https://www.googletagmanager.com"},
    },{
      plugin: require('../node_modules/gatsby-plugin-sentry/gatsby-browser.js'),
      options: {"plugins":[],"environment":"production","enabled":true,"ignoreErrors":["Non-Error exception captured","Non-Error promise rejection captured","ChunkLoadError","Illegal invocation","Cannot read properties of null (reading 'getAttribute')","Cannot read properties of null (reading 'tagName')"]},
    },{
      plugin: require('../node_modules/gatsby-plugin-prismic-previews/gatsby-browser.js'),
      options: {"plugins":[],"repositoryName":"equitise-v3","accessToken":"MC5ZamtWdUJFQUFDSUFkUlFY.77-9dO-_vQvvv73vv73vv73vv73vv70k77-9J--_vR5kdnZNKW7vv73vv73vv73vv70T77-9L--_ve-_ve-_vSrvv70","promptForAccessToken":true,"apiEndpoint":"https://equitise-v3.cdn.prismic.io/api/v2","lang":"*","pageSize":100,"imageImgixParams":{"auto":"compress,format","fit":"max"},"imagePlaceholderImgixParams":{"w":100,"blur":15},"toolbar":"new"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
