// extracted by mini-css-extract-plugin
export var footer = "Footer-module--footer--rLNBC";
export var footerAbout = "Footer-module--footer-about--TlyDa";
export var footerCol = "Footer-module--footer-col--tthKV";
export var footerColTitle = "Footer-module--footer-col-title--Mgsri";
export var footerColumns = "Footer-module--footer-columns--kaLE+";
export var footerCompany = "Footer-module--footer-company--4Bt5t";
export var footerLearn = "Footer-module--footer-learn--5Rb9p";
export var footerLegal = "Footer-module--footer-legal--wHGBC";
export var footerNewsletterSocial = "Footer-module--footer-newsletter-social--qx2Y+";
export var footerSocialLinks = "Footer-module--footer-social-links--jCp4P";
export var linkText = "Footer-module--linkText--PyYhp";
export var logo = "Footer-module--logo--WSN3h";
export var newsletterSignupSubtext = "Footer-module--newsletter-signup-subtext--jsYY+";
export var primary = "#06004d";
export var privacyTerms = "Footer-module--privacy-terms--M+cBE";
export var secondary = "#4dafd7";
export var sitemap = "Footer-module--sitemap--gaFZB";
export var sitemapRight = "Footer-module--sitemap-right--HwWoT";
export var wrapper = "Footer-module--wrapper--RmTmE";