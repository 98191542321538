export const userData = user => {
  const userDataObject = {
    email: user?.email,
    state: user?.address?.state,
    subscribeToEmails: user?.subscribeToComms,
    sophisticatedStatus: user?.profile?.sophisticatedStatus?.name,
    telephone: user?.profile?.telephone,
    identityVerifiedAt: user?.identityVerifiedAt,
  };
  return userDataObject;
};
