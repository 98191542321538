import React, { useEffect, useRef, useState, useContext } from 'react';
import OnVisible from 'react-on-visible';
import { DealTile } from 'components/Page';
import { useMutation } from '@apollo/client';
import { AuthContext, UtmContext, LandingPageContext } from '../context';
import { destroy, getCookie } from '../apis';
import { convertToCamelCase } from '../utils';
import { BrandShape, HamburgerNav, InvestDropdown, Logo, Navigation, Tagline, Button, Link } from '.';
import { HAS_CLEARED_INTERCOM } from '../utils/constants/cookieNames';
import { SearchComponent } from './SearchComponent/SearchComponent';
import Section from './Section';
import { DESTROY_TOKEN } from './HeaderMutations';
import apolloClient from '../gatsby-theme-apollo/client';
import { trackAmplitudeEvent } from '../utils/analytics';
import * as styles from './Header.module.scss';

export const Header = ({
  tagline,
  title,
  content,
  customClass: headerClass,
  displayDropdown,
  location,
  routes,
  displayHowToLink,
  displayDealsButton,
  displayHeroTile,
  heroDeal,
  justLaunchedBannerOnTile,
  closingSoonBannerOnTile,
  heroWarningStatement,
  searchBarOnHeader,
  searchBarPlaceholder,
  inheritedClassnames,
  reduceHeaderSize,
  platformLogo,
  prismicId,
  logo,
}) => {
  const { document, id: heroId, uid: heroUid } = heroDeal || {};
  const { data: heroDealData } = document || {};
  const { isSyndicateDeal } = useContext(LandingPageContext) || {};

  const validClassnames = inheritedClassnames || {};

  // Get all cookies
  const allCookies = getCookie();

  // Retrieve cookie values from visited deal room
  const visitedOfferCookieValues = Object.keys(allCookies || {})
    .filter(key => key.includes('eq_deal_offer'))
    .map(key => allCookies[key]);

  let visitedDeal;

  if (visitedOfferCookieValues && visitedOfferCookieValues?.length > 0) {
    try {
      visitedDeal = JSON.parse(visitedOfferCookieValues[0]);
    } catch (error) {
      console.error('Visited Deal JSON Parse Error: ', error);
    }
  }

  const heroDealPrismicDealData = {
    country: heroDealData?.country,
    roundMaximum: heroDealData?.round_maximum,
    roundMinimum: heroDealData?.round_minimum,
    offerType: heroDealData?.offer_type,
    hideRaisedAmount: heroDealData?.hide_raised_amount,
    hideTimer: heroDealData?.hide_timer,
    endDate: heroDealData?.end_date,
    isEoi: heroDealData?.is_eoi,
    isMaxedOut: heroDealData?.is_maxed_out,
    visibility: heroDealData?.visibility,
    eoiFooter: heroDealData?.eoi_footer,
    companyName: heroDealData?.company_name,
    tileImage: heroDealData?.tile_image,
    tileLogo: heroDealData?.tile_logo,
    tileFooter: heroDealData?.tile_footer,
  };

  const heroIdVisitedDeal = visitedDeal?.tile?.document?.id;
  const heroUidVisitedDeal = visitedDeal?.tile?.document?.uid;
  const heroTileDescriptionVisitedDeal = visitedDeal?.tile?.document?.data?.tile_description;

  const visitedPrismicDealData = {
    country: visitedDeal?.tile?.document?.data?.country,
    roundMaximum: Number(visitedDeal?.tile?.document?.data?.round_maximum),
    roundMinimum: Number(visitedDeal?.tile?.document?.data?.round_minimum),
    offerType: visitedDeal?.tile?.document?.data?.offer_type,
    hideRaisedAmount: true,
    hideTimer: visitedDeal?.tile?.document?.data?.hide_timer,
    endDate: visitedDeal?.tile?.document?.data?.end_date,
    isEoi: visitedDeal?.tile?.document?.data?.is_eoi,
    isMaxedOut: visitedDeal?.tile?.document?.data?.is_maxed_out,
    visibility: visitedDeal?.tile?.document?.data?.visibility,
    eoiFooter: visitedDeal?.tile?.document?.data?.eoi_footer,
    companyName: visitedDeal?.tile?.document?.data?.company_name,
    tileImage: visitedDeal?.tile?.document?.data?.tile_image,
    tileLogo: visitedDeal?.tile?.document?.data?.tile_logo,
    tileFooter: visitedDeal?.tile?.document?.data?.tile_footer,
    hideEoiTag: false,
  };

  const { utmParams } = useContext(UtmContext) || {};
  const { utmSource, utmMedium, utmCampaign } = utmParams || {};
  const [headerHeight, setHeaderHeight] = useState(0);
  const [scrolled, setScrolled] = useState(false);
  const [loginUrl, setLoginUrl] = useState(`${process.env.CORE_APP_URL}/login`);

  useEffect(() => {
    const redirect = location.href ? `?redirect=${location.href}` : '';
    setLoginUrl(`${loginUrl}${redirect}`);
  }, [location]);

  const headerElement = useRef(null);

  useEffect(() => {
    window.addEventListener('scroll', () => handleScroll(scrolled), true);
    setHeaderHeight(headerElement.current.offsetHeight);
    return () => {
      window.removeEventListener('scroll', () => handleScroll(scrolled));
    };
  }, [scrolled]);

  const [currentUrl, setCurrentUrl] = useState('');

  useEffect(() => {
    setCurrentUrl(window.location.href);
  }, []);

  const { resource: user, error: userError, loading: userLoading, clearResource: clearUser } =
    useContext(AuthContext) || {};
  const hasAuth = !userLoading && !!user;

  // Logs error if destory token mutation fails
  const onLogoutFail = error => {
    console.error('Destroy token failed', { error });
  };

  // Destroy auth token mutation
  const [destroyToken] = useMutation(DESTROY_TOKEN, {
    client: apolloClient,
    onError: onLogoutFail,
  });

  // If ownUser error is invalid, destroy token on back end
  if (userError && !userLoading) {
    const errorStatus = userError?.response?.status;
    if (errorStatus === 401) return destroyToken();
  }

  // pull the utm params from context, check to make sure they have a value instead of 'null' and create a string that can be attached to the url
  const containsUtm = utmParams?.utmSource !== null;
  const utmString = `utm_source=${utmParams?.utmSource}&utm_medium=${utmParams?.utmMedium}&utm_campaign=${utmParams?.utmCampaign}`;

  const { text: customClass } = headerClass || {};

  const containsBody = tagline || (title && title.text);
  const convertedCustomClass = customClass ? convertToCamelCase(customClass) : '';

  const handleScroll = hasScrolled => {
    const scrollY = window.pageYOffset;
    if (scrollY > 0 && !hasScrolled) setScrolled(true);
    if (scrollY === 0 && hasScrolled) setScrolled(false);
  };

  // First destroys token on back end, then destroys cookie then resets resource state
  const handleLogout = async event => {
    event.preventDefault();
    try {
      await destroyToken();
      await destroy();

      try {
        trackAmplitudeEvent({
          email: user?.email,
          eventName: 'Logged Out',
          eventData: {
            location: 'Marketing App',
            loggedOutFromUrl: currentUrl,
          },
          userData: {
            email: user?.email,
            source: utmSource,
            medium: utmMedium,
            campaign: utmCampaign,
            state: user?.address?.state,
            subscribeToEmails: user?.subscribeToComms,
            sophisticatedStatus: user?.profile?.sophisticatedStatus?.name,
            telephone: user?.profile?.telephone,
            identityVerifiedAt: user?.identityVerifiedAt,
          },
        });
      } catch (error) {
        console.error(error);
      }
    } catch (error) {
      console.error('Error handling logout', error);
    } finally {
      clearUser();
      // Clear cookie to ensure an anonymous intercom session is initiated next time user comes back to page
      destroy(HAS_CLEARED_INTERCOM);
    }
  };
  const centreNavLinks = [
    {
      to: '#invest',
      text: 'Invest',
      className: '',
      onClick: event => event.preventDefault(),
      sublinks: [
        {
          to: '/invest',
          text: routes?.invest?.text,
          className: '',
        },
        {
          ...routes?.retail,
          className: '',
        },
        {
          ...routes?.ipo,
          className: '',
        },
        {
          ...routes?.wholesale,
          className: '',
        },
        {
          to: '/why-invest',
          text: 'Why Invest',
          className: '',
        },
      ],
    },
    {
      to: '#raise',
      text: 'Raise',
      className: '',
      onClick: event => event.preventDefault(),
      sublinks: [
        {
          ...routes?.raiseProcess,
          className: '',
        },
        {
          to: '/why-partner',
          text: 'Why Partner With Us',
          className: '',
        },
        {
          to:
            'https://docs.google.com/forms/d/e/1FAIpQLSeEbmurSaLrgSoRq6z4ixPcWiVDwcFzRChrt59x4us8aSlfDw/viewform?usp=sf_link',
          text: 'Start Raise',
          className: '',
        },
      ],
    },
    {
      to: '#about',
      text: 'About',
      className: '',
      onClick: event => event.preventDefault(),
      sublinks: [
        {
          to: '/about-us',
          text: 'Our Story',
          className: '',
        },
        {
          ...routes?.team,
          className: '',
        },
      ],
    },
    {
      to: '#learn',
      text: 'Learn',
      className: '',
      onClick: event => event.preventDefault(),
      sublinks: [
        {
          to: '/news-feed',
          text: 'Newsfeed',
          className: '',
        },
        {
          ...routes?.blog,
          className: '',
        },
        {
          to: '/faq',
          text: 'Help Centre (FAQs)',
          className: '',
        },
      ],
    },
  ];

  const loginLink = {
    to: loginUrl,
    text: 'Login',
  };

  // if the the utm params have a value other than null, attach the string to the end of the url for redux to pick up in the invest app
  const registerLink = {
    to: `${process.env.CORE_APP_URL}/register${containsUtm ? `?${utmString}` : ''}`,
    text: 'Register',
  };

  const logoutLink = {
    to: `#logout`,
    text: 'Logout',
    onClick: handleLogout,
  };

  const dashboardLink = {
    to: `${process.env.CORE_APP_URL}/dashboard`,
    text: 'Dashboard',
  };

  const lhsButtonClass = `${styles.button} button ${styles.white} white ${styles.outline} outline`;
  const rhsButtonClass = `${styles.button} button ${
    scrolled || !containsBody ? `${styles.highlight} highlight` : `${styles.primary} primary`
  }`;
  const rhsHamburgerButtonClass = `${styles.button} button highlight`;

  const hamburgerNoAuthinks = [
    {
      ...loginLink,
      className: lhsButtonClass,
    },
    {
      ...registerLink,
      className: rhsHamburgerButtonClass,
    },
  ];
  const hamburgerAuthLinks = [
    {
      ...logoutLink,
      className: lhsButtonClass,
    },
    {
      ...dashboardLink,
      className: rhsHamburgerButtonClass,
    },
  ];

  const noAuthLinks = [
    {
      ...loginLink,
      className: lhsButtonClass,
    },
    {
      ...registerLink,
      className: rhsButtonClass,
    },
  ];

  const authenticatedLinks = [
    {
      ...logoutLink,
      className: lhsButtonClass,
    },
    {
      ...dashboardLink,
      className: `${styles.button} button ${
        scrolled || !containsBody ? `${styles.highlight} highlight` : styles.primary
      }`,
    },
  ];

  const hamburgerActions = hasAuth ? hamburgerAuthLinks : hamburgerNoAuthinks;
  const navigationClassnames = {
    item: styles.item,
    link: styles.link,
    toggle: styles.toggle,
    toggleChevron: styles.toggleChevron,
    subnav: styles.subnav,
    subnavLink: styles.subnavLink,
    actions: styles.actions,
    showChevron: styles.showChevron,
    showSublinks: styles.showSublinks,
    tagline: styles.tagline,
    investDropdown: styles.investDropdown,
    pageTile: styles.pageTile,
    isSyndicateDealActions: styles.isSyndicateDealActions,
  };
  const headerTileClassnames = {
    tagline: styles.tagline,
    pageTile: styles.pageTile,
    pageTileBody: styles.pageTileBody,
  };

  return (
    <OnVisible
      wrappingElement="header"
      id="header"
      bounce
      className={`${styles.header} ${validClassnames.header || ''} ${styles[convertedCustomClass] ||
        ''} ${isSyndicateDeal && styles.isSyndicateBackground} ${scrolled ? styles.stickHeader : ''}`}
      visibleClassName={styles.visible}
      style={{
        paddingTop: scrolled ? headerHeight : 0,
        overflow: containsBody ? 'hidden' : 'visible',
      }}
    >
      <section className={`${styles.headerTopRow} ${isSyndicateDeal && styles.isSyndicateHeader}`} ref={headerElement}>
        {/* <div className={`${styles.wrapper} ${validClassnames.wrapper || ''} wrapper`}> */}
        {prismicId === 'Zx8GDhEAAB0ARi3Q' ? (
          <div className={`${styles.wrapper} ${validClassnames.wrapper || ''} wrapper`}>
            {/* {!platformLogo?.url && <Logo type="tertiaryLight" inheritedClassname={styles.logo} />} */}
            {/* {platformLogo?.url && ( */}
            <Logo type="tertiaryLight" inheritedClassname={styles.logo} customLogo={logo} />
            {/* )} */}
            {/* <HamburgerNav isSyndicateDeal={isSyndicateDeal}>
              <Navigation
                navigationClassnames={navigationClassnames}
                inheritedClassname={styles.headerCenterNavigation}
                // links={!isSyndicateDeal && centreNavLinks}
                actions={hamburgerActions}
                isSyndicateDeal={isSyndicateDeal}
              />
            </HamburgerNav> */}
            <div />
            <Navigation
              navigationClassnames={navigationClassnames}
              inheritedClassname={styles.headerRightNavigation}
              actions={hasAuth ? authenticatedLinks : noAuthLinks}
              classNamePrefix="header-right"
              // includeSearchIcon
              isSyndicateDeal={isSyndicateDeal}
            />
          </div>
        ) : (
          <div className={`${styles.wrapper} ${validClassnames.wrapper || ''} wrapper`}>
            {!platformLogo?.url && <Logo type="tertiaryLight" inheritedClassname={styles.logo} />}
            {platformLogo?.url && (
              <Logo type="tertiaryLight" inheritedClassname={styles.logo} customLogo={platformLogo} />
            )}
            <HamburgerNav isSyndicateDeal={isSyndicateDeal}>
              <Navigation
                navigationClassnames={navigationClassnames}
                inheritedClassname={styles.headerCenterNavigation}
                links={!isSyndicateDeal && centreNavLinks}
                actions={hamburgerActions}
                isSyndicateDeal={isSyndicateDeal}
              />
            </HamburgerNav>
            <Navigation
              navigationClassnames={navigationClassnames}
              inheritedClassname={styles.headerRightNavigation}
              actions={hasAuth ? authenticatedLinks : noAuthLinks}
              classNamePrefix="header-right"
              includeSearchIcon
              isSyndicateDeal={isSyndicateDeal}
            />
          </div>
        )}
        {/* </div> */}
      </section>
      {containsBody && (
        <Section
          className={`${styles.headerBody} ${reduceHeaderSize && styles.surveyPageTitle} ${validClassnames.headerBody ||
            ''}`}
          containerClassName={`${styles.wrapper} ${validClassnames.wrapper || ''} wrapper`}
          as="div"
        >
          <div className={styles.headerBodyContent}>
            <Tagline tagline={tagline} inheritedClassname={styles.tagline} />
            {title && title.text && (
              <h1 className={`${styles.headerBodyTitle} ${reduceHeaderSize && styles.surveyPageTitle}`}>
                {title.text}
              </h1>
            )}
            {content && content.html && (
              <div className={styles.headerBodyText} dangerouslySetInnerHTML={{ __html: content.html }} />
            )}
            {displayDropdown === 'yes' && <InvestDropdown retailLink={routes?.retail} />}
            <div className={`${styles.headerBodyCta} ${displayHowToLink !== 'yes' && styles.ctaFlex}`}>
              {searchBarOnHeader && (displayHowToLink === 'no' || !displayHowToLink) && (
                <SearchComponent
                  searchBarPlaceholder={searchBarPlaceholder}
                  isOnHeader
                  inheritedClassname={styles.inputClickButton}
                  isSyndicateDeal={isSyndicateDeal}
                />
              )}
              {displayDealsButton === 'yes' && (
                <Button inheritedClassname={styles.button} theme={`${styles.highlight} highlight`} to="/invest">
                  View Deals
                </Button>
              )}
              {displayHowToLink === 'yes' && (
                <Link className={styles.ctaLink} to="/why-invest">
                  Why invest
                </Link>
              )}
            </div>
          </div>
          {displayHeroTile === 'yes' && (
            <>
              {visitedDeal ? (
                <div className={styles.headerBodyTile}>
                  <section>
                    <DealTile
                      uid={heroUidVisitedDeal}
                      key={heroIdVisitedDeal}
                      prismicId={heroIdVisitedDeal}
                      warningStatements={heroWarningStatement}
                      inheritedClassnames={headerTileClassnames}
                      dealTileLocation="Header"
                      prismicDealData={visitedPrismicDealData}
                      heroDealTileDescription={heroTileDescriptionVisitedDeal?.text || ''}
                    />
                  </section>
                </div>
              ) : (
                <div className={styles.headerBodyTile}>
                  <DealTile
                    uid={heroUid}
                    key={heroId}
                    prismicId={heroId}
                    justLanchedBanner={justLaunchedBannerOnTile}
                    closingSoonBanner={closingSoonBannerOnTile}
                    warningStatements={heroWarningStatement}
                    heroDealTileDescription={heroDealData?.tile_description?.text || ''}
                    inheritedClassnames={headerTileClassnames}
                    dealTileLocation="Header"
                    prismicDealData={heroDealPrismicDealData}
                  />
                </div>
              )}
            </>
          )}
        </Section>
      )}
      {containsBody && <BrandShape inheritedClassname={styles.brandShape} />}
    </OnVisible>
  );
};
