// extracted by mini-css-extract-plugin
export var active = "RegisterInterestModal-module--active--dO0sI";
export var button = "RegisterInterestModal-module--button--rwmip";
export var checkboxContainer = "RegisterInterestModal-module--checkbox-container--TUgMD";
export var checkboxLabel = "RegisterInterestModal-module--checkbox-label--zz69E";
export var eoiFormIcon = "RegisterInterestModal-module--eoi-form-icon--CMSoQ";
export var eoiSubmitted = "RegisterInterestModal-module--eoi-submitted--vAifr";
export var field = "RegisterInterestModal-module--field--mm4s8";
export var form = "RegisterInterestModal-module--form--RobVE";
export var formTwoContainer = "RegisterInterestModal-module--formTwo-container--T84UA";
export var icon = "RegisterInterestModal-module--icon--CNQ3s";
export var modal = "RegisterInterestModal-module--modal--lv3KD";
export var modalContent = "RegisterInterestModal-module--modal-content--6E716";
export var modalContentBody = "RegisterInterestModal-module--modal-content-body--be-ew";
export var modalFooterText = "RegisterInterestModal-module--modal-footer-text--eRhfn";
export var modelSocialShare = "RegisterInterestModal-module--modelSocialShare--ln21I";
export var primary = "#06004d";
export var registerInterestModal = "RegisterInterestModal-module--register-interest-modal--CHATF";
export var registerTitle = "RegisterInterestModal-module--register-title--pnZAR";
export var retailMaxProfileLink = "RegisterInterestModal-module--retail-max-profile-link--VUhks";
export var secondary = "#4dafd7";
export var validationError = "RegisterInterestModal-module--validation-error--frQd7";