// extracted by mini-css-extract-plugin
export var categoryCard = "CategoryCard-module--category-card--XMRp6";
export var categoryCardName = "CategoryCard-module--category-card-name--+hPRj";
export var categoryCardPosts = "CategoryCard-module--category-card-posts--SEAP0";
export var categoryCardViewMoreLink = "CategoryCard-module--category-card-view-more-link--cB5YI";
export var categorySpecific = "CategoryCard-module--category-specific--9FSTc";
export var postCard = "CategoryCard-module--post-card--nnJQE";
export var postCardContent = "CategoryCard-module--post-card-content--YbxSi";
export var postCardImage = "CategoryCard-module--post-card-image--BGb7G";
export var postCardImageWrapper = "CategoryCard-module--post-card-image-wrapper--lf7oz";
export var postCardTitle = "CategoryCard-module--post-card-title--4zmL5";
export var primary = "#06004d";
export var secondary = "#4dafd7";