import React from 'react';

export const Map = ({ address, height, width }) => {
  if (!address) return null;
  const regexAddress = address.replace(/\s/g, '+');
  // const regexAddress = '85+William+Street+Darlinghurst';
  return (
    <div className="map">
      <iframe
        src={`https://www.google.com/maps/embed/v1/place?key=${process.env.GOOGLE_MAPS}&q=${regexAddress}`}
        height={height}
        width={width}
        title="Google map of office location"
      />
    </div>
  );
};
