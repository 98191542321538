import React from 'react';
// import { Section } from 'components';
import Section from '../../components/Section';
import * as styles from './styles.module.scss';

export const WarningStatement = ({
  data: {
    primary: { heading: { text: heading } = {}, notice: { html: notice } = {}, points: { html: points } = {} },
  },
}) => (
  <Section as="div" className={styles.warningStatement} noContainer sliceName="WarningStatement">
    {heading && (
      <div className={styles.heading}>
        <span className={`${styles.icon} icon icon-info-dark`} />
        <div>
          <h3>{heading}</h3>
          {notice && <div className={styles.notice} dangerouslySetInnerHTML={{ __html: notice }} />}
        </div>
      </div>
    )}
    {points && <div className={styles.points} dangerouslySetInnerHTML={{ __html: points }} />}
  </Section>
);
