import React, { useState } from 'react';
import { Link, Button } from 'components';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import duration from 'dayjs/plugin/duration';
import { QuestionAnswerForm } from '.';
import { strToDate, timeSince } from '../../utils';
import * as styles from './QuestionAnswerQuestion.module.scss';

const QACard = props => {
  const {
    type = 'question',
    author,
    createdAt: created,
    updatedAt: updated,
    description,
    children,
    hasAnswerPrivilege,
    companyName,
  } = props;

  dayjs.extend(utc);
  dayjs.extend(duration);
  const now = dayjs.utc();

  const creatorEmail = hasAnswerPrivilege ? author?.email : '';
  const creatorFirstName = author?.firstName;
  const edited =
    type === 'answer' && !!updated && updated !== created ? (
      <span className={styles.qaTitleCreatorEmail}> ( edited )</span>
    ) : (
      ''
    );
  const creatorRole =
    hasAnswerPrivilege &&
    (author?.roles?.map(role => role.id)?.some(id => [1, 2, 3, 4].includes(id))
      ? '(Equitise Team)'
      : `(${companyName?.text} Team)`);

  const title =
    type === 'question' ? (
      <>
        <span className={styles.qaTitle}>Question from {creatorFirstName}</span>
        <span className={styles.qaTitleCreatorEmail}> {creatorEmail}</span>
      </>
    ) : (
      <>
        <span className={styles.qaTitle}>Answer from {creatorFirstName}</span>
        <span className={styles.qaTitleCreatorRole}> {creatorRole}</span>
      </>
    );

  const createdDate = new Date(strToDate(created));
  const postedDate = dayjs.utc(createdDate);
  const sincePosted = dayjs.duration(now.diff(postedDate)).$d;
  const typeName = `${type}Type`;

  return (
    <article className={`${styles.qaCard} ${styles[typeName]}`}>
      <div className={styles.qaHeader}>
        <h6>{title}</h6>
      </div>
      <div className={styles.qaBody}>
        <div className={styles.qaTimerContainer}>
          <span className="qa-timer">{timeSince(sincePosted)}</span>
          {children}
        </div>
        <pre>
          {description}
          {edited}
        </pre>
      </div>
    </article>
  );
};

const Question = props => {
  const [displayForm, setDisplayForm] = useState(false);
  const [answerFormType, setAnswerFormType] = useState('answer');

  const {
    answer,
    hasAnswerPrivilege,
    hasEditPrivilege,
    refreshQuestions,
    user,
    companyName,
    uid,
    setSubmitError,
    ...question
  } = props;

  const onReplyClick = event => {
    event.preventDefault();
    setDisplayForm(true);
  };

  const onEditClick = event => {
    event.preventDefault();
    setDisplayForm(true);
    return setAnswerFormType('edit');
  };

  const displayReplyBtn = !answer && hasAnswerPrivilege && !displayForm;
  const displayAnswer = Boolean(answer) && !displayForm;
  const displayAnswerForm = Boolean(displayForm) && hasAnswerPrivilege;

  return (
    <article className={styles.qaPair}>
      <QACard hasAnswerPrivilege={hasAnswerPrivilege} {...question} />
      {displayAnswer && (
        <QACard hasAnswerPrivilege={hasAnswerPrivilege} companyName={companyName} {...answer} type="answer">
          {hasEditPrivilege && (
            <>
              -
              <Link onClick={onEditClick} href="#edit-answer" className={styles.editAnswerLink}>
                Edit
              </Link>
            </>
          )}
        </QACard>
      )}
      {displayReplyBtn && <Button onClick={onReplyClick}>Reply</Button>}
      {displayAnswerForm && (
        <QuestionAnswerForm
          description={answer?.description}
          questionId={question.id}
          refreshQuestions={refreshQuestions}
          respondee={question?.author}
          setDisplayForm={setDisplayForm}
          type={answerFormType}
          user={user}
          answerId={answer?.id}
          companyName={companyName}
          questionDescription={question?.description}
          uid={uid}
          setSubmitError={setSubmitError}
        />
      )}
    </article>
  );
};

export default Question;
