import { ApolloClient, createHttpLink, from, InMemoryCache } from '@apollo/client';
import authLink from './authLink';
import customFetch from './customFetch';

const httpLink = createHttpLink({ uri: process.env.GRAPHQL_URL, fetch: customFetch });

const cache = new InMemoryCache({
  typePolicies: {
    UserProfile: {
      keyFields: ['userId'],
    },
  },
});

export default new ApolloClient({
  link: from([authLink, httpLink]),
  connectToDevTools: true,
  cache,
});
