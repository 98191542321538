import React from 'react';

const BrandShape = ({ fill = '#6364FF', inheritedClassname }) => (
  <svg
    className={`${inheritedClassname || ''} brand-shape`}
    width="1340px"
    height="1228px"
    viewBox="0 0 1340 1228"
    version="1.1"
  >
    <defs>
      <path
        d="M1306.46576,489.210017 L1306.41176,489.411017 C1350.50676,566.667017 1350.33176,662.363017 1305.81176,739.085017 L1096.37276,1102.04402 C1074.38376,1139.99602 1042.55476,1171.76202 1004.54476,1193.70702 C966.535762,1215.65202 923.236762,1227.26002 879.248762,1227.40102 L460.197762,1227.30102 C370.965762,1227.46102 287.782762,1179.38402 243.012762,1101.84002 L33.5467618,739.034017 C-11.1492382,661.617017 -11.1952382,565.539017 33.4867618,488.215017 L242.997762,125.383017 C265.041762,87.232017 296.670762,55.411017 334.679762,33.467017 C372.688762,11.522017 416.187762,-0.0329829967 460.122762,0.0260170033 L879.100762,0 C968.405762,-0.0329829967 1051.71676,47.971017 1096.41276,125.388017 L1306.46576,489.210017 Z"
        id="path-1"
      />
      <filter x="-0.1%" y="-0.2%" width="100.3%" height="100.3%" filterUnits="objectBoundingBox" id="filter-2">
        <feGaussianBlur stdDeviation="1.5" in="SourceAlpha" result="shadowBlurInner1" />
        <feOffset dx="0" dy="1" in="shadowBlurInner1" result="shadowOffsetInner1" />
        <feComposite
          in="shadowOffsetInner1"
          in2="SourceAlpha"
          operator="arithmetic"
          k2="-1"
          k3="1"
          result="shadowInnerInner1"
        />
        <feColorMatrix values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.5 0" type="matrix" in="shadowInnerInner1" />
      </filter>
    </defs>
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Fill-48">
        <use fill={fill} fillRule="evenodd" xlinkHref="#path-1" />
        <use fill="black" fillOpacity="1" filter="url(#filter-2)" xlinkHref="#path-1" />
      </g>
    </g>
  </svg>
);

export default BrandShape;
