// extracted by mini-css-extract-plugin
export var iconHighlightCross = "SearchModal-module--icon-highlight-cross--jjaLe";
export var inputContainer = "SearchModal-module--inputContainer--3hX6i";
export var isTabletSize = "SearchModal-module--is-tablet-size--hKcpQ";
export var noResult = "SearchModal-module--noResult--bxbxi";
export var noResultMargin = "SearchModal-module--noResultMargin--e3Jdi";
export var primary = "#06004d";
export var resultDescription = "SearchModal-module--result-description--atEXV";
export var resultLink = "SearchModal-module--result-link--fUPFg";
export var resultTagline = "SearchModal-module--result-tagline--mF47s";
export var searchIconInputContainer = "SearchModal-module--search-icon-input-container--6CNDg";
export var searchInput = "SearchModal-module--search-input--WXCKj";
export var searchLink = "SearchModal-module--searchLink--0W6Wu";
export var searchResultContainer = "SearchModal-module--searchResult-container--cbNZB";
export var searchResultItemContainer = "SearchModal-module--searchResultItemContainer--Msr68";
export var searchResultItemContainerSearch = "SearchModal-module--searchResultItemContainerSearch--Vckhw";
export var searchResultItemText = "SearchModal-module--searchResultItemText--NM4sg";
export var searchResultTitleStyles = "SearchModal-module--searchResultTitleStyles--pykVy";
export var secondary = "#4dafd7";
export var showMore = "SearchModal-module--showMore--314xT";
export var virtualKeyboardOpen = "SearchModal-module--virtual-keyboard-open--oGtKv";