import React from 'react';
import shortid from 'shortid';
import { SocialLink } from './SocialLink';
import * as styles from './SocialLinks.module.scss';

export const SocialLinks = ({ theme = 'primary', socialLinks, inheritedClassname, event, userEmail, area }) => (
  <div className={`${styles.shareLinks} ${inheritedClassname || ''} share-links`}>
    <span className={styles.shareLinksLabel}>Share this offer:</span>
    {socialLinks &&
      socialLinks.map(link => {
        const {
          link: { url },
          platform: type,
        } = link;
        return (
          <SocialLink
            url={url}
            type={type}
            theme={theme}
            event={event}
            key={shortid.generate()}
            userEmail={userEmail}
            area={area}
          />
        );
      })}
  </div>
);
