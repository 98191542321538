import React, { useContext, useEffect, useState } from 'react';
import OnVisible from 'react-on-visible';
import { Image, SocialLink, Section, RegisterInterestForm, TeamMembers, Link } from 'components';
import * as styles from './styles.module.scss';
import { LandingPageContext } from '../../context';
import { trackAmplitudeEvent } from '../../utils';

export const CtaRegisterInterestForm = ({
  data: {
    primary: {
      headline,
      tagline,
      logo,
      form_logo: formLogo,
      include_headline: includeHeadline,
      include_logo: includeLogo,
      link_to_offer: linkToOffer,
      container_image: containerImage,
    },
    items: teamMembers,
  },
  template,
}) => {
  const {
    prismicId,
    uid,
    companyName,
    primaryColour,
    secondaryColour,
    backgroundColour,
    textColour,
    platformLogo,
    isEoi,
    isVersionOne,
    isSyndicateDeal,
    isWholesaleDeal,
  } = useContext(LandingPageContext) || {};
  const showLogo = includeLogo === 'yes' && (logo?.localFile || logo?.url);
  const showHeadline = includeHeadline === 'yes' && headline && headline.html;

  const titleTextColour = {
    color: `${primaryColour}`,
  };

  // This determines whether the screen size represents a Mobile
  const [isMobile, setIsMobile] = useState(false);
  useEffect(() => {
    setIsMobile(window.innerWidth <= 500);
    window.addEventListener(
      'resize',
      () => {
        const isMob = window.innerWidth <= 500;
        if (isMob !== isMobile) setIsMobile(isMob);
      },
      false
    );
  }, [isMobile]);

  const backgroundColorSettings = isVersionOne && isMobile ? 'white' : secondaryColour;
  const customBackgroundColor = {
    backgroundColor: `${backgroundColorSettings}`,
  };
  const customColors = {
    primary: `${primaryColour}`,
    secondary: `${secondaryColour}`,
  };

  return (
    <OnVisible percent={20} visibleClassName={styles.visible}>
      <Section className={`${styles.landingCtaFormWrapper}`} noContainer sliceName="Team" style={customBackgroundColor}>
        <div className={styles.landingCtaFormContainer}>
          {showLogo && <div className={styles.landingCtaFormBox}></div>}
          <div className={styles.landingCtaFormTextLogoContainer}>
            {showLogo && <Image image={logo} className={styles.landingCtaFormLogo} objectFit="contain" />}
            {showHeadline && (
              <div className={styles.landingCtaFormBodyHeadline}>
                <h1 style={titleTextColour}>{headline.text}</h1>
              </div>
            )}

            <div
              className={`${styles.landingCtaFormFormLinkContainerUnderHeadline} ${!isVersionOne &&
                styles.isNotVersionOne}`}
            >
              <RegisterInterestForm
                companyName={companyName?.text}
                logo={formLogo || logo}
                prismicId={prismicId}
                uid={uid}
                customStyle={customColors}
                linkToOffer={linkToOffer}
                isEoi={isEoi}
                isSyndicateDeal={isSyndicateDeal}
                isWholesaleDeal={isWholesaleDeal}
              />
            </div>

            {tagline && tagline.html && (
              <div
                className={styles.landingCtaFormDescription}
                dangerouslySetInnerHTML={{ __html: tagline.html }}
                style={{ color: textColour }}
              />
            )}
            {containerImage.url ? (
              <Image className={styles.containerImage} image={containerImage.url} alt={logo?.alt} />
            ) : (
              <TeamMembers teamMembers={teamMembers} isSyndicateDeal={isSyndicateDeal} />
            )}
          </div>
          <div className={styles.landingCtaFormFormLinkContainer}>
            <RegisterInterestForm
              companyName={companyName?.text}
              logo={formLogo || logo}
              prismicId={prismicId}
              uid={uid}
              customStyle={customColors}
              linkToOffer={linkToOffer}
              isEoi={isEoi}
              isSyndicateDeal={isSyndicateDeal}
              isWholesaleDeal={isWholesaleDeal}
            />
          </div>
        </div>
      </Section>
    </OnVisible>
  );
};
