import React from 'react';
import { Button } from 'components';
import EquitiseLogo from '../../images/logos/equitise-icon-tertiary.svg';
import FireFoxIcon from '../../images/icons/firefox-icon.svg';
import GoogleIcon from '../../images/icons/chrome-icon.svg';
import SafariIcon from '../../images/icons/safari-icon.svg';

import './styles.scss';

const BadBrowser = () => (
  <div className="ie-container">
    <div className="ie-browser-body-container">
      <img className="equitise-icon" src={EquitiseLogo} alt="Google Icon" />
      <div className="ie-header">It's not you, its your web browser</div>
      <div className="ie-subheading">
        Equitise is built using the latest technology, which makes your investments more secure. Unfortunately, Internet
        Explorer browser doesn't support these latest features. To start investing today, Equitise requires requires you
        to use one of the following browsers:
      </div>
      <div className="ie-grid-wrapper">
        <div className="browser-link">
          <img className="web-icons" src={GoogleIcon} alt="Google Icon" />
          <Button
            className="browser-Button"
            href="https://www.google.com.au/chrome/?brand=CHBD&gclid=Cj0KCQjwnqH7BRDdARIsACTSAdtOyv4_ufRzjHqymeXb7bG29EqiEZ8aAb3vX8DiuMoOWE2gk9t5SuwaArtbEALw_wcB&gclsrc=aw.ds"
          >
            CHROME
          </Button>
        </div>

        <div className="browser-link">
          <img className="web-icons" src={FireFoxIcon} alt="Firefox Icon" />
          <Button href="https://www.mozilla.org/en-US/firefox/new/" className="browser-Button">
            FIREFOX
          </Button>
        </div>
        <div className="browser-link">
          <img className="web-icons" src={SafariIcon} alt="Firefox Icon" />
          <Button href="https://support.apple.com/downloads/safari" className="browser-Button">
            SAFARI
          </Button>
        </div>
      </div>
      <div className="ie-browser-footer">Have any questions? Contact us at contact@equitise.com</div>
    </div>
  </div>
);

export default BadBrowser;