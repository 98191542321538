// extracted by mini-css-extract-plugin
export var card = "styles-module--card--E1lw+";
export var cardBody = "styles-module--card-body--5f2z2";
export var cardImageWrapper = "styles-module--card-image-wrapper--TPqVx";
export var caseStudyContainer = "styles-module--case-study-container--mxoGy";
export var caseStudySection = "styles-module--case-study-section--ePtop";
export var description = "styles-module--description--EDRJb";
export var figure = "styles-module--figure--yMjve";
export var gatsbyImage = "styles-module--gatsby-image--HZyVb";
export var keyPoint = "styles-module--key-point--TqaEi";
export var keyPoints = "styles-module--key-points--QXm3o";
export var label = "styles-module--label--1+pQQ";
export var primary = "#06004d";
export var secondary = "#4dafd7";
export var taglineIcon = "styles-module--tagline-icon--mJQ9Y";
export var taglineWrapper = "styles-module--tagline-wrapper--V3YM8";
export var wrapper = "styles-module--wrapper--PHQqA";