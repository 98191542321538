import React, { useState } from 'react';
import { Button, Link } from 'components';
import { NEW_ZEALAND } from '../../utils/constants/countryNames';
import { RegisterInterestModal } from './RegisterInterestModal';
import { RETAIL } from '../../utils/constants/offerTypes';
import * as styles from './RegisterInterest.module.scss';

export const RegisterInterest = props => {
  const {
    isTileEoi,
    companyName,
    country,
    isEoi,
    logo,
    metrics,
    offerType,
    prismicId,
    registerInterestFooter,
    uid,
    hasRetailMax,
    inheritedClassnames,
    inheritedClassname,
    eoiTooltipText,
    isManuDeal,
    isManuCtaAside,
  } = props;
  const { hasMaxxedNzRetailFunding } = metrics || {};

  const [modalOpen, setModalOpen] = useState(hasRetailMax || showMaxxedNzFundingModal);

  const showMaxxedNzFundingModal = country === NEW_ZEALAND && offerType === RETAIL && hasMaxxedNzRetailFunding;

  const validClassnames = inheritedClassnames || {};

  const onRegisterClick = event => {
    event.preventDefault();

    return setModalOpen(true);
  };

  const DefaultRegisterInterest = () => (
    <div>
      {eoiTooltipText ? (
        <span className={styles.eoiBadgeContent} onClick={onRegisterClick}>
          <icon className={`${styles.icon} ${styles.iconEoi} icon icon-eoi`} />
          {eoiTooltipText}
        </span>
      ) : (
        <Button
          theme="highlight"
          href="#register"
          onClick={onRegisterClick}
          inheritedClassname={validClassnames.button || ''}
        >
          {isEoi || isTileEoi ? 'Register Interest' : 'Invest Now'}
        </Button>
      )}
    </div>
  );

  const ByManuRegisterInterest = () => (
    <div className={isManuCtaAside && `${styles.isManuCtaAside}`}>
      Not ready to invest?{' '}
      <Link className={`${validClassnames.investLogin} invest-login`} onClick={onRegisterClick}>
        Click here for more info
      </Link>
    </div>
  );

  return (
    <div className={`${validClassnames.investButtonContainer || inheritedClassname || ''} invest-button-container`}>
      {isManuDeal ? <ByManuRegisterInterest /> : <DefaultRegisterInterest />}

      <RegisterInterestModal
        companyName={companyName}
        country={country}
        isEoi={isEoi}
        logo={logo}
        metrics={metrics}
        modalOpen={modalOpen}
        offerType={offerType}
        prismicId={prismicId}
        registerInterestFooter={registerInterestFooter}
        setModalOpen={setModalOpen}
        uid={uid}
      />
    </div>
  );
};
