// extracted by mini-css-extract-plugin
export var answerFormFooter = "QuestionAnswerQuestion-module--answer-form-footer--VThuA";
export var answerType = "QuestionAnswerQuestion-module--answer-type--ViVdn";
export var button = "QuestionAnswerQuestion-module--button--p5As3";
export var editAnswerLink = "QuestionAnswerQuestion-module--edit-answer-link--bP4Kl";
export var offerRoomContentBlock = "QuestionAnswerQuestion-module--offer-room-content-block--gADVk";
export var primary = "#06004d";
export var qaBlock = "QuestionAnswerQuestion-module--qa-block--8lI8f";
export var qaBody = "QuestionAnswerQuestion-module--qa-body--oHhUo";
export var qaCard = "QuestionAnswerQuestion-module--qa-card--LM2iH";
export var qaCtaCopy = "QuestionAnswerQuestion-module--qa-cta-copy--07yzo";
export var qaHeader = "QuestionAnswerQuestion-module--qa-header--+UOIi";
export var qaPair = "QuestionAnswerQuestion-module--qa-pair--pQyNz";
export var qaTimerContainer = "QuestionAnswerQuestion-module--qa-timer-container--AuNYJ";
export var qaTitle = "QuestionAnswerQuestion-module--qa-title--6NJHw";
export var qaTitleCreatorEmail = "QuestionAnswerQuestion-module--qa-title-creator-email--iLFhP";
export var qaTitleCreatorRole = "QuestionAnswerQuestion-module--qa-title-creator-role--SsArS";
export var secondary = "#4dafd7";