import { gql } from '@apollo/client';

export const DESTROY_TOKEN = gql`
  mutation destroyToken {
    destroyToken {
      token
      type
    }
  }
`;
