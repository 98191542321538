import React from 'react';
import OnVisible from 'react-on-visible';
import { Image, Section } from 'components';
import { SliceHeader } from 'components/Page';
import * as styles from './styles.module.scss';

export const Capabilities = ({
  data: {
    primary: { tagline, title, content: { html: content } = {} },
    items,
  },
}) => (
  <OnVisible className={styles.capabilities} visibleClassName={styles.visible}>
    <Section className={`${styles.wrapper}`} as="div" sliceName="Capabilities">
      <SliceHeader tagline={tagline} title={title}>
        <div dangerouslySetInnerHTML={{ __html: content }} className={styles.pageSliceContent} />
      </SliceHeader>
      <div className={styles.capabilitiesBody}>
        {items.map((capability, index) => {
          const { content: { html: capabiltiyContent } = {}, heading: { text: heading } = {}, icon } = capability;
          return (
            <div className={styles.capability} style={{ transitionDelay: `${index * 0.2}s` }} key={heading}>
              <Image
                image={icon}
                inheritedClassname={styles.gatsbyImage}
                className={styles.capabilityIcon}
                alt={icon?.alt || title}
              />
              <h4>{heading}</h4>
              <div dangerouslySetInnerHTML={{ __html: capabiltiyContent }} />
            </div>
          );
        })}
      </div>
    </Section>
  </OnVisible>
);
