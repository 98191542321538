// extracted by mini-css-extract-plugin
export var dealRoom = "Documents-module--dealRoom--b+nnO";
export var offerRoomDocuments = "Documents-module--offer-room-documents--7ns3Z";
export var offerRoomDocumentsItem = "Documents-module--offer-room-documents-item--eoHg3";
export var offerRoomDocumentsList = "Documents-module--offer-room-documents-list--6Bb2R";
export var primary = "#06004d";
export var secondary = "#4dafd7";
export var sectionHeading = "Documents-module--sectionHeading--Oc592";
export var sydicateDealDocs = "Documents-module--sydicateDealDocs--Szln5";
export var sydicateDealDocsList = "Documents-module--sydicateDealDocsList--17K8Z";
export var sydicateDealOfferRoomDocs = "Documents-module--sydicateDealOfferRoomDocs---7zbq";
export var sydicateDealSectionHeeading = "Documents-module--sydicateDealSectionHeeading--5XyaX";
export var visible = "Documents-module--visible--Qc4r6";