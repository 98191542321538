import React, { useContext } from 'react';
import { DealContext } from '../../context/DealContext';
import { InvestButton } from '.';
import { RegisterInterest } from './RegisterInterest';

export const DealRoomCta = ({ inheritedClassnames, eoiTooltipText, isManuCtaAside }) => {
  const { dbDealData, prismicDealData } = useContext(DealContext);
  const { companyName, country, isEoi, logo, offerType, prismicId, registerInterestFooter, uid } =
    prismicDealData || {};

  const { metrics } = dbDealData || {};
  const { hasTargetMax, isLive } = metrics || {};

  const isManuDeal = prismicId === 'ZBlAbREAAH-q8Wp5';

  if (isManuDeal && isLive)
    return (
      <>
        <InvestButton inheritedClassnames={inheritedClassnames} buttonType="signIn" />
        <RegisterInterest
          companyName={companyName?.text}
          inheritedClassnames={inheritedClassnames}
          country={country}
          isEoi={isEoi}
          logo={logo}
          metrics={metrics}
          offerType={offerType}
          prismicId={prismicId}
          registerInterestFooter={registerInterestFooter}
          uid={uid}
          eoiTooltipText={eoiTooltipText}
          isManuDeal={isManuDeal}
          isManuCtaAside={isManuCtaAside}
        />
      </>
    );

  if (!isLive || hasTargetMax || isEoi)
    return (
      <RegisterInterest
        companyName={companyName?.text}
        inheritedClassnames={inheritedClassnames}
        country={country}
        isEoi={isEoi}
        logo={logo}
        metrics={metrics}
        offerType={offerType}
        prismicId={prismicId}
        registerInterestFooter={registerInterestFooter}
        uid={uid}
        eoiTooltipText={eoiTooltipText}
      />
    );

  return <InvestButton inheritedClassnames={inheritedClassnames} buttonType="signIn" />;
};
