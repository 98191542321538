// extracted by mini-css-extract-plugin
export var accordion = "styles-module--accordion--85b4B";
export var accordionBlock = "styles-module--accordion-block--XUEHu";
export var accordionItem = "styles-module--accordion-item--6un+9";
export var accordionItemBody = "styles-module--accordion-item-body--UMT5-";
export var accordionItemTitle = "styles-module--accordion-item-title--Xdise";
export var accordionSection = "styles-module--accordion-section--3-NvY";
export var legalPage = "styles-module--legal-page--8BwuG";
export var offerRoomContentTitle = "styles-module--offer-room-content-title--AuMAo";
export var open = "styles-module--open--EgdC3";
export var page = "styles-module--page--YVRSg";
export var primary = "#06004d";
export var secondary = "#4dafd7";
export var tagline = "styles-module--tagline--ytItb";
export var wrapper = "styles-module--wrapper--tvbwR";