import React from 'react';
import shortid from 'shortid';
import * as styles from './KeyPoints.module.scss';

export const KeyPoints = ({ keyPoints, inheritedClassnames }) => {
  if (!keyPoints) return null;
  const validClassnames = inheritedClassnames ? inheritedClassnames : {};

  return (
    <div className={`${styles.keyPoints} ${validClassnames.keyPoints}`}>
      {keyPoints.map(keyPoint => {
        const { label: { text: label } = {}, figure: { text: figure } = {} } = keyPoint;
        return (
          <div className={`${styles.keyPoint} ${validClassnames.keyPoint}`} key={shortid.generate()}>
            <span className={`${styles.label} ${validClassnames.label}`}>{label}</span>
            <span className={`${styles.figure} ${validClassnames.figure}`}>{figure}</span>
          </div>
        );
      })}
    </div>
  );
};
