const unhandledErrMsg = 'Oooops something went wrong please contact Equitise support';
// TODO improve error handling throughout - this can be refactored to remove duplicate code
// See destructure network error below
export const destructure422Error = error => {
  const response = error?.response;
  const status = response?.status;
  if (!response || status !== 422) {
    return { unhandledErrMsg };
  }
  return { errorData: response.data };
};

export const destructureGreenId422Error = error => {
  const { response } = error;
  if (!response) {
    console.error('This was not a request error');
    return null;
  }
  const { status, data } = response;
  switch (status) {
    case 500:
      return 'There was an error in our system verifying your identity please contact Equitise support';
    case 422:
      return data.messages || data.message || 'An unexpected error occurred';
    default:
      console.error('This was not a 500 or a 422 error');
      return null;
  }
};

export const destructureNetworkError = error => {
  const response = error?.response;

  const status = response?.status;

  const responseData = response?.data;

  if (!responseData || !status) return { unhandledErrMsg };

  return responseData;
};
