import React, { useState } from 'react';
import { EmblaCarousel, Section } from 'components';
import { Tabs, TestimonialCard, SliceHeader } from 'components/Page';
import * as styles from './styles.module.scss';

export const Testimonials = ({ data: { primary, items } }) => {
  const { tagline, title } = primary;

  const [category, setCategory] = useState('All');

  const [isHoveredOverQuote, setIsHoveredOverQuote] = useState(false);

  if (!items || items.length < 1) return null;

  const selectTab = name => setCategory(name);

  const tabNamesArr = items.map(item => item.classification).sort((a, b) => a?.localeCompare(b));
  const tabNames = tabNamesArr.length > 1 ? ['All', ...new Set(tabNamesArr)] : [];
  const hasMultipleCategories = tabNames.length > 2;

  const tabsProps = {
    tabNames,
    startTab: category,
    selectTab,
  };

  const slides =
    items &&
    items
      .filter(testimonial => category === 'All' || testimonial.classification === category)
      .map(testimonial => (
        <TestimonialCard
          setIsHoveredOverQuote={setIsHoveredOverQuote}
          data={testimonial}
          key={testimonial.full_name.text}
        />
      ));

  return (
    <Section className={styles.testimonials} containerClassName={styles.wrapper} sliceName="Testimonials">
      <div className={styles.testimonialsCarouselHeader}>
        <SliceHeader inheritedClassname={styles.pageSliceHeader} title={title} tagline={tagline} />
        {hasMultipleCategories && (
          <div>
            <Tabs inheritedClassname={styles.tabsWrapper} {...tabsProps} tabEventType="Testimonials Tab" />
          </div>
        )}
      </div>
      <div className={styles.testimonialsBody}>
        <EmblaCarousel slides={slides} inheritedClassname={styles.embla} isHoveredOverQuote={isHoveredOverQuote} />
      </div>
    </Section>
  );
};
