import React from 'react';
import OnVisible from 'react-on-visible';
import { EmblaCarousel, Image, Section } from 'components';
import { SectionHeading } from 'components/DealRoom';
import * as styles from './styles.module.scss';

export const Carousel = props => {
  const { data, template } = props || {};
  const { items, primary, containerClassName = 'carousel' } = data || {};

  const itemSlides = items.map(item => {
    const { image, alt } = item;
    return (
      <div key={image.url}>
        <Image image={image} alt={alt} />
      </div>
    );
  });

  return (
    <OnVisible
      className={`offer-room-content-block ${containerClassName} ${styles[template]}`}
      visibleClassName={styles.visible}
      id="team"
      percent={20}
    >
      <Section noContainer spacing="n" sliceName="Carousel">
        {primary && <SectionHeading heading={primary.heading} />}
        <EmblaCarousel slides={itemSlides} className="offer-room-slides" />
      </Section>
    </OnVisible>
  );
};
