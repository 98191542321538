// extracted by mini-css-extract-plugin
export var child = "styles-module--child--zEDvj";
export var circle = "styles-module--circle--5uq3g";
export var container = "styles-module--container--JvNMw";
export var date = "styles-module--date--jtwXs";
export var emptyParagraph = "styles-module--empty-paragraph--KH7w5";
export var iconDash = "styles-module--icon-dash--IAqM4";
export var iconDashContainer = "styles-module--iconDashContainer--F97Xk";
export var primary = "#06004d";
export var secondary = "#4dafd7";
export var wrapper = "styles-module--wrapper--dnYKf";