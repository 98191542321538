import axios from 'axios';
import { getCookie, setCookie, destroy } from './cookie';

// TODO add cancel token for clean up effects

const requestHeaders = {
  baseURL: process.env.API_URL,
  'Content-Type': 'application/json',
  Accept: 'application/json',
};

const lambdaRequestHeaders = {
  ...requestHeaders,
  baseURL: `${process.env.LAMBDA_URL}/api`,
};

export const refreshJwtToken = async () => {
  const { data: refTokResData } = await LocalAPI('/api/v3/auth/refresh');
  return refTokResData?.data?.token;
};

export const LocalAPI = axios.create(requestHeaders);

export const LambdaClient = axios.create(lambdaRequestHeaders);

const appendAuthToken = request => {
  const token = getCookie(process.env.AUTH_TOKEN_NAME);
  if (token) {
    request.headers.Authorization = `Bearer ${token}`;
  }
  return request;
};

LocalAPI.interceptors.request.use(appendAuthToken);

LambdaClient.interceptors.request.use(appendAuthToken);

const appendDefaultAuthHeader = token => {
  this.defaults.headers.common.Authorization = `Bearer ${token}`;
};

LocalAPI.setAuthHeader = appendDefaultAuthHeader;

LambdaClient.setAuthHeader = appendDefaultAuthHeader;

// If response successful don't alter it just pass on down the chain
const onResFulfilled = response => response;

// Check the response for failure due to JWT expiry
const onResRejected = async error => {
  const { status, config: origReq, data: origResData } = error?.response || {};

  // If this is not an authorization then throw the error down the error handling chain
  if (status !== 401) return Promise.reject(error);

  const errMsg = origResData?.message;
  // If token is invalid remove the auth cookie and throw the error down the chain
  // TODO explore if we should be doing this on any 401 error but token expired
  if (errMsg === 'Invalid token') {
    // Remove cookie
    destroy();
    // Throw error to handlers down the chain
    return Promise.reject(error);
  }

  // If token is expired try refreshing it and retrying the original request
  if (errMsg === 'Expired token') {
    try {
      // Refresh the token with auth server
      const newToken = await refreshJwtToken();
      // Save the new token to auth cookie
      setCookie({ value: newToken });
      // Append new token to original request
      origReq.headers.Authorization = `Bearer ${newToken}`;
      // Retry the orignal request
      return axios(origReq);
    } catch (refTokErr) {
      console.error('Refresh token failed', { error: refTokErr });
      // Remove cookie to force user to login again and start afresh
      destroy();
      // Throw the error down the chain
      return Promise.reject(error);
    }
  }

  // Unknown authorization error pass it down the chain
  return Promise.reject(error);
};

LocalAPI.interceptors.response.use(onResFulfilled, onResRejected);
