import React, { useState, useEffect, useContext } from 'react';
import { AuthContext, UtmContext, DealContext } from '../../context';
import { pushDataLayerEvent } from '../../utils/analytics';
import { IPO } from '../../utils/constants/offerTypes';
import { Arrow } from '..';
import { IpoNoticeModal } from '../IpoNoticeModal';

export const InvestButton = props => {
  const { buttonType, inheritedClassnames } = props;
  const { prismicDealData, hasAgreedTerms, setHasClickedInvest } = useContext(DealContext);
  const { companyName: offerName, isBid, offerType, prismicId } = prismicDealData;

  const { resource: user, loading: userLoading } = useContext(AuthContext) || {};
  const hasAuth = !userLoading && !!user;
  const [modalOpen, setModalOpen] = useState(false);
  const [modalRedirect, setModalRedirect] = useState('');

  // pull the utm params from context, check to make sure they have a value instead of 'null' and create a string that can be attached to the url
  const { utmParams } = useContext(UtmContext) || {};
  const containsUtm = utmParams?.utmSource !== null;
  const utmString = `utm_source=${utmParams?.utmSource}&utm_medium=${utmParams?.utmMedium}&utm_campaign=${utmParams?.utmCampaign}`;

  const [dealUrl, setDealUrl] = useState(null);
  const [investClicked, setInvestClicked] = useState(false);
  const destinationUrl = hasAuth ? dealUrl : `${process.env.CORE_APP_URL}/register?redirect=${dealUrl}`;
  const loginUrl = hasAuth ? dealUrl : `${process.env.CORE_APP_URL}/login?redirect=${dealUrl}`;
  const validClassnames = inheritedClassnames || {};

  // if the the utm params have a value other than null, attach the string to the end of the url for redux to pick up in the invest app
  useEffect(() => {
    const url = `${process.env.CORE_APP_URL}/invest?prismicId=${prismicId}&${containsUtm ? `${utmString}` : ''}`;

    setDealUrl(url);
  }, [prismicId]);

  // Block IPO investors if they haven't agreed to the terms
  const needsToTandC = offerType === IPO && !hasAgreedTerms;
  const investUrl = needsToTandC ? '#terms' : destinationUrl;

  const trackInvest = () => {
    try {
      pushDataLayerEvent({ eventName: 'Clicked Invest', eventData: { offerName } });
    } catch (pushDataLayerInvestFailed) {
      console.error('Data layer event Invest failed', {
        error: pushDataLayerInvestFailed,
        offer: offerName,
      });
    }
  };

  const onInvestClick = needsToTandC ? () => setHasClickedInvest(true) : trackInvest;

  const onScalareIpoClick = needsToTandC ? () => setHasClickedInvest(true) : () => setModalOpen(true);

  const scalarePrismicId = prismicId === 'Zw7uIhEAACEAanME';
  // const scalarePrismicId = true;

  const InvestLink = ({ children }) =>
    scalarePrismicId ? (
      <button
        type="button"
        className={`${validClassnames.button || ''} button highlight invest-button with-chevron`}
        onClick={() => onScalareIpoClick()}
      >
        {children}
      </button>
    ) : (
      <a
        href={scalarePrismicId ? null : investUrl || '/'}
        className={`${validClassnames.button || ''} button highlight invest-button with-chevron`}
        onClick={onInvestClick}
      >
        {children}
      </a>
    );

  // return (
  //   <a
  //     href={scalarePrismicId ? null : investUrl || '/'}
  //     className={`${validClassnames.button || ''} button highlight invest-button with-chevron`}
  //     onClick={onInvestClick}
  //   >
  //     {children}
  //   </a>
  // );

  if (!hasAuth && buttonType === 'signIn') {
    return (
      <div className={`${validClassnames.investButtonContainer || ''}  invest-button-container`}>
        <IpoNoticeModal
          modalOpen={modalOpen}
          setModalOpen={setModalOpen}
          setRedirect={setModalRedirect}
          investUrl={investUrl}
        />
        <InvestLink>
          Sign up to {isBid ? 'bid' : 'invest'}
          <Arrow />
        </InvestLink>
        <div className="login-or-container">
          <span className={`${validClassnames.investOr} invest-or`}>or</span>
          <a href={loginUrl} className={`${validClassnames.investLogin} invest-login`} onClick={onInvestClick}>
            Login
          </a>
        </div>
      </div>
    );
  }

  return (
    <div className={`${validClassnames.investButtonContainer} invest-button-container`}>
      <IpoNoticeModal
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
        setRedirect={setModalRedirect}
        investUrl={investUrl}
      />
      <InvestLink>
        {isBid ? 'Bid' : 'Invest'} now
        <Arrow />
      </InvestLink>
    </div>
  );
};
